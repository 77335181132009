import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CreateBase, FileField, FileInput, Form, ImageField, ImageInput, Loading, SaveButton } from 'react-admin';
import axios from '../axios/axios';
import { Box } from '@mui/system';
import { Card, CardContent, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { uploadImageToFirebaseStorage, uploadVideoToFirebaseStorage } from '../utils/util';
import ExcelJS from 'exceljs';
import { Consts } from '../consts/Const';

export default function FormDialog({ contestData }: any) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsloading] = React.useState(false);
    const [confirm, setConfirm] = React.useState<any>({});
    const [confirmError, setConfirmError] = React.useState(false);
    const [csvItems, setCsvItems] = React.useState<any>(null);
    const [isCompleted, setIsCompleted] = React.useState(false);
    const [completeError, setCompleteError] = React.useState<any[]>([]);
    const [uploadFiles, setUploadFiles] = React.useState<any[]>([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == 'backdropClick' && isLoading) return;
        setOpen(false);
        setIsloading(false);
        setConfirm({});
        setIsCompleted(false);
        setCompleteError([]);
        setConfirmError(false);
        setCsvItems(null);
        setUploadFiles([]);
    };

    const onSubmit = async (data: any) => {
        if (!csvItems) {
            setIsloading(true);

            const csvFiles: any[] = [];
            const otherFiles: any[] = [];
            const confirmObject: any = {};

            // ファイルをcsvとそれ以外に分ける。
            data.files.map((file: any) => {
                file.rawFile.type.indexOf('application') === 0
                    ? csvFiles.push(file.rawFile)
                    : otherFiles.push(file.rawFile);
            });
            setUploadFiles(otherFiles);

            if (csvFiles.length === 0 || otherFiles.length === 0)
                confirmObject[9998] = {
                    tenantName: '',
                    checks: [{ status: 'error', message: 'ファイルに不足があります。' }]
                };

            // csvファイルは2つ以上ならエラー
            if (csvFiles.length > 1)
                confirmObject[9998] = {
                    tenantName: '',
                    checks: [{ status: 'error', message: 'csvファイルは１つにしてください。' }]
                };

            const workbook = new ExcelJS.Workbook();
            const fileReader: any = new FileReader();
            fileReader.readAsArrayBuffer(csvFiles[0]);
            fileReader.onload = async () => {
                // ファイル読み込み
                const buffer = fileReader.result;
                const wb = await workbook.xlsx.load(buffer);
                const worksheet = wb.worksheets[0];

                // エクセルから情報を取得
                const items: any[] = [];
                const headerRow = worksheet.getRow(1);
                worksheet.eachRow((row: ExcelJS.Row, rowIndex) => {
                    if (rowIndex == 1 || !row.values.length || (row.values as any[]).length <= 0) {
                        return;
                    }
                    const result: any = {};
                    const currentRow = worksheet.getRow(rowIndex);
                    // [TODO] row.values.length確認
                    for (let index = 1; index < (row.values as any[]).length; index++) {
                        const key = headerRow.getCell(index).value!.toString();
                        result[key] = currentRow.getCell(index).value?.toString();
                    }
                    items.push(result);
                });

                // バリデーション確認
                for (let index = 0; index < items.length; index++) {
                    const item = items[index];
                    items[index]['contestId'] = contestData.id;
                    items[index]['areaId'] = contestData.areaId;
                    items[index]['contestType'] = contestData.contestType;
                    confirmObject[index] = {
                        tenantName: item.tenantName,
                        checks: []
                    };

                    try {
                        const results: any = await axios.post('/posts/bulkCreatePostCheck', item);
                        confirmObject[index]['checks'] = results.data.results.checks;
                        items[index] = results.data.item;
                    } catch (error: any) {
                        confirmObject[index]['checks'].push({ status: 'error', message: 'エラーが発生しました' });
                    }

                    // 添付ファイルとの整合性チェック
                    Object.keys(item).map((key) => {
                        if (key.indexOf('ファイル') === 0 && item[key] && item[key].trim()) {
                            let foundFile;
                            if (otherFiles) {
                                foundFile = otherFiles.find((file) => {
                                    return file.name === item[key];
                                });
                            }
                            if (!foundFile)
                                confirmObject[index]['checks'].push({
                                    status: 'error',
                                    message: `${key}のファイルが存在しません。`
                                });
                        }
                    });
                }

                // 結果を格納
                setCsvItems(items);
                setConfirm(confirmObject);

                // errorが存在するかどうか
                Object.keys(confirmObject).map((id: any) => {
                    confirmObject[id].checks.map((d: any) => {
                        if (d.status === 'error') {
                            setConfirmError(true);
                        }
                    });
                });

                setIsloading(false);
            };
        }
        // 確認後の実行
        else {
            setIsloading(true);

            const errors = [];

            // 一括インポート実行
            for (let index = 0; index < csvItems.length; index++) {
                const csvItem = csvItems[index];

                console.log(csvItem);

                // ファイルアップロード処理
                for (let i = 0; i < csvItem.files.length; i++) {
                    const foundFile = uploadFiles.find((file) => {
                        return file.name === csvItem.files[i].url;
                    });
                    try {
                        // 画像の場合
                        if (csvItem.files[i].fileType == 'images') {
                            csvItem.files[i].url = await uploadImageToFirebaseStorage(
                                foundFile,
                                csvItem.areaId,
                                'posts'
                            );
                        }
                        // [TODO] 動画の場合
                        else {
                            csvItem.files[i].url = await uploadVideoToFirebaseStorage(
                                foundFile,
                                csvItem.areaId,
                                'posts'
                            );
                        }
                    } catch (error: any) {
                        errors.push({
                            rowNumber: index,
                            tenantName: csvItem.tenantName,
                            message: `${foundFile.name}のアップロードが失敗しました。`
                        });
                    }
                }

                try {
                    // 新規登録
                    await axios.post('/posts', csvItem);
                } catch (error: any) {
                    errors.push({ rowNumber: index, tenantName: csvItem.tenantName });
                }
            }

            setConfirm({});
            setConfirmError(false);
            setIsCompleted(true);
            setCompleteError(errors);
            setCsvItems(false);
            setIsloading(false);
            setUploadFiles([]);
        }
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                一括投稿
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>一括投稿</DialogTitle>
                {!isCompleted ? (
                    <Form onSubmit={onSubmit}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <DialogContent>
                                {!csvItems ? (
                                    <FileInput
                                        source="files"
                                        label="まとめてファイルをドラッグアンドドロップできます" // 仕様通り{false}にしたいが、errorが発生する。
                                        accept={
                                            '.xlsx, ' +
                                            (contestData.contestType === Consts.CONTEST_TYPES.images
                                                ? Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION
                                                : Consts.ACCEPTABLE_VIDEO_FILE_EXTENSION)
                                        }
                                        maxSize={
                                            contestData.contestType === Consts.CONTEST_TYPES.images
                                                ? Consts.ACCEPTABLE_IMAGE_FILE_SIZE
                                                : Consts.ACCEPTABLE_VIDEO_FILE_SIZE
                                        }
                                        multiple={true}
                                        format={formatPreview}
                                        placeholder={
                                            'xlsxファイル、アップロードするファイルをドロップするか、クリックして選択してください。'
                                        }
                                        sx={{
                                            '& .RaFileInput-dropZone': {
                                                background: 'transparent',
                                                border: '2px dashed gray',
                                                borderRadius: '6px',
                                                padding: '50px'
                                            }
                                        }}
                                    >
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                ) : (
                                    <Box>
                                        <Box>
                                            {confirmError
                                                ? '以下のエラー（赤字）があるため、一括インポートを実行できません。csvファイルを修正し、再度ファイル選択してください。'
                                                : '一括インポートを実行してよろしいですか。'}
                                        </Box>
                                        {Object.keys(confirm).map((id: any, index: number) =>
                                            confirm[id].checks.length > 0 ? (
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{ marginTop: '15px', padding: '10px' }}
                                                >
                                                    <Typography fontWeight={'bold'}>
                                                        {parseInt(id) + 2}行目、テナント名: {confirm[id].tenantName}
                                                    </Typography>
                                                    <Box>
                                                        {confirm[id].checks.map((c: any, i: number) => {
                                                            return (
                                                                <Typography
                                                                    key={i}
                                                                    variant="body2"
                                                                    gutterBottom
                                                                    color={() => {
                                                                        switch (c.status) {
                                                                            case 'error':
                                                                                return 'red';
                                                                            case 'warning':
                                                                                return 'orange';
                                                                            case 'new':
                                                                                return 'green';
                                                                        }
                                                                    }}
                                                                >
                                                                    ・{c.message}
                                                                </Typography>
                                                            );
                                                        })}
                                                    </Box>
                                                </Card>
                                            ) : null
                                        )}
                                    </Box>
                                )}
                            </DialogContent>
                        )}
                        <DialogActions>
                            <Button onClick={handleClose} disabled={isLoading}>
                                キャンセル
                            </Button>
                            {csvItems ? (
                                <Button
                                    onClick={() => {
                                        setConfirm({});
                                        setConfirmError(false);
                                        setCsvItems(null);
                                        setUploadFiles([]);
                                    }}
                                    disabled={isLoading}
                                >
                                    ファイルを再選択
                                </Button>
                            ) : null}
                            <SaveButton
                                disabled={isLoading || confirmError}
                                label={csvItems ? '実行する' : '確認する'}
                            />
                        </DialogActions>
                    </Form>
                ) : (
                    <>
                        <DialogContent>
                            一括インポートが完了しました。
                            {completeError.length > 0
                                ? completeError.map((error: any, i: number) => {
                                      return (
                                          <Box key={i}>
                                              {!error.message ? (
                                                  <Typography variant="body2" gutterBottom color="red">
                                                      ・{error.rowNumber}行目、テナント名「{error.tenantName}
                                                      」の登録に失敗しました。
                                                  </Typography>
                                              ) : (
                                                  <Typography variant="body2" gutterBottom color="red">
                                                      ・{error.rowNumber}行目、テナント名「{error.tenantName}」
                                                      {error.message}
                                                  </Typography>
                                              )}
                                          </Box>
                                      );
                                  })
                                : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>閉じる</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
}

function formatPreview(value: any) {
    return value;
    // if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    //     return { url: value };
    // } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
    //     return value;
    // }
}
