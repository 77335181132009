import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {
    CreateBase,
    FileField,
    FileInput,
    Form,
    ImageField,
    ImageInput,
    Loading,
    SaveButton,
    useDataProvider,
    useGetList,
    useGetOne,
    useNotify,
    useRedirect,
    useRefresh,
    useUpdate,
    useUpdateMany
} from 'react-admin';
import Error from '../Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CropLandscapeOutlined } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { Consts } from '../consts/Const';
import { usePrevious } from '../utils/util';
import axios from '../axios/axios';
import { useEffect } from 'react';
import { Box, Chip } from '@mui/material';

export default function FormDialog({ contestData, currentUser, currentMallSelections }: any) {
    const [open, setOpen] = React.useState(false);
    const [selectionError, setSelectionError] = React.useState(false);
    const [selectionLoading, setSelectionLoading] = React.useState(false);
    const [isFinished, setIsFinished] = React.useState(false);
    const [selectionCapacity, setSelectionCapacity] = React.useState<number>();
    const refresh = useRefresh();
    const prevContestData: any = usePrevious(contestData);
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (!contestData || !currentUser) return;

        setSelectionLoading(true);

        // 審査確定のチェック
        axios
            .post('/excuteSelection', {
                contestId: contestData.id,
                mallId: currentUser.mallId,
                isValidationOnly: true
            })
            .then((respose) => {
                console.log(respose.data.selectionCapacity);
                setSelectionCapacity(respose.data.selectionCapacity);
                setSelectionLoading(false);

                if (respose.data.status !== 'ok') {
                    setSelectionError(true);
                }
            })
            .catch((error) => {
                setSelectionLoading(false);
                setSelectionError(true);
                console.log(error.response.data.message);
            });
    }, [contestData, currentUser]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectionError(false);
        setSelectionLoading(false);
        setIsFinished(false);
        refresh();
    };

    const onSubmit = async (data: any) => {
        setSelectionLoading(true);

        // postsへの処理
        await axios
            .post('/excuteSelection', {
                contestId: contestData.id,
                mallId: currentUser.mallId
            })
            .then((respose) => {
                console.log(respose.data.message);

                // [TODO] 直前のawaitを待たずリロードしてしまうため、以下コメントアウト
                // setIsFinished(true);
                // setSelectionLoading(false);

                // [TODO] 直前のawaitを待たずリロードしてしまうため、暫定対応
                // 1次審査の場合、mallSelectionsの更新を検知して、ブラウザリロードを走らせる
                if (contestData.selectionStatus == Consts.SELECTION_STATUS.firstSelection) {
                    console.log('一次審査確定処理完了');
                    browserReloadForUpdatedSelections();
                }
                // 2次審査以上の場合、contestsの更新を検知して、ブラウザリロードを走らせる
                else {
                    console.log('二次、三次審査確定処理完了');
                    browserReloadForContests();
                }
            })
            .catch((error) => {
                setSelectionLoading(false);
                setSelectionError(true);
                console.error(error.response.data.message);
            });
    };

    const browserReloadForUpdatedSelections = async (count = 1) => {
        // 5回までリトライ
        if (count > 5) return handleClose();
        console.log('mallSelections変更検知実行回数:', count);
        // 1秒待つ
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const gotMallSelection = await dataProvider
            .getList('mallSelections', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'DESC' },
                filter: { contestId: contestData.id, mallId: currentUser.mallId }
            })
            .then((response: any) => {
                return response?.data[0];
            });

        // 変更が検知できた場合は、ブラウザリロード
        if (gotMallSelection?.isSelected === 1) {
            return location.reload();
        }

        // 取得できるまで再帰的に実行
        count = count + 1;
        browserReloadForUpdatedSelections(count);
    };

    const browserReloadForContests = async (count = 1) => {
        // 5回までリトライ
        if (count > 5) return handleClose();
        console.log('contests変更検知実行回数:', count);
        // 1秒待つ
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const gotContest = await dataProvider.getOne('contests', { id: contestData.id }).then((response: any) => {
            return response?.data;
        });

        // 変更が検知できた場合は、ブラウザリロード
        if (prevContestData?.selectionStatus !== gotContest?.selectionStatus) {
            return location.reload();
        }

        // 取得できるまで再帰的に実行
        count = count + 1;
        browserReloadForContests(count);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}
            >
                {contestData.selectionStatus + 1 !== contestData.maxSelectionNumber ? (
                    <Box
                        sx={{
                            mb: 1
                        }}
                    >
                        <Chip
                            label={`${contestData.selectionStatus + 1}次審査通過数：${selectionCapacity}`}
                            variant="outlined"
                        />
                    </Box>
                ) : null}
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<EmojiEventsIcon />}
                    onClick={handleClickOpen}
                >
                    確定する
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{contestData.selectionStatus + 1}次審査確定確認</DialogTitle>
                {!selectionLoading ? (
                    !selectionError ? (
                        // [TODO] 直前のawaitを待たずリロードしてしまうことの暫定対応のため以下コメントアウト
                        // isFinished ?
                        // <>
                        //     <DialogContent>
                        //         <DialogContentText>
                        //             {contestData.selectionStatus + 1}次審査を確定しました。
                        //         </DialogContentText>
                        //     </DialogContent>
                        //     <DialogActions>
                        //         <Button
                        //             variant="contained"
                        //             color="secondary"
                        //             startIcon={<EmojiEventsIcon />}
                        //             onClick={handleClose}
                        //             disabled={selectionLoading}
                        //         >
                        //             確認
                        //         </Button>
                        //     </DialogActions>
                        // </>
                        // :
                        <>
                            <DialogContent>
                                <DialogContentText>
                                    {contestData.selectionStatus + 1 !== contestData.maxSelectionNumber ? (
                                        <>
                                            {contestData.selectionStatus + 1}次審査点数が高い上位{selectionCapacity!}
                                            作品を、{contestData.selectionStatus + 2}
                                            次審査に選出する作品として確定してよろしいでしょうか。
                                            <br />※ 一度、確定すると再確定し直すことはできません。
                                        </>
                                    ) : (
                                        <>
                                            {contestData.selectionStatus + 1}
                                            次（最終）審査の賞と採点を確定してよろしいですか。
                                            <br />※ 一度、確定すると再確定し直すことはできません。
                                        </>
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} disabled={selectionLoading}>
                                    キャンセル
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<EmojiEventsIcon />}
                                    onClick={onSubmit}
                                    disabled={selectionLoading}
                                >
                                    確定する
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <>
                            <DialogContent>
                                <DialogContentText>
                                    {contestData.selectionStatus + 1 !== contestData.maxSelectionNumber &&
                                    selectionCapacity ? (
                                        <>
                                            以下のいずれかの理由により、上位{selectionCapacity}
                                            作品に確定できなかったため、点数の再調整が必要です。
                                            <br />・{selectionCapacity}作品以上の採点を完了していない。
                                            <br />・{selectionCapacity}位と{selectionCapacity + 1}位以下の点数が同点
                                        </>
                                    ) : (
                                        <>
                                            以下のいずれかの理由により、最終審査を確定できません
                                            <br />
                                            ・賞がどの投稿にも付けられていない。
                                        </>
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>閉じる</Button>
                            </DialogActions>
                        </>
                    )
                ) : (
                    <Loading />
                )}
            </Dialog>
        </>
    );
}
