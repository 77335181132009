// in src/MyError.js
import * as React from 'react';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Report';
import History from '@mui/icons-material/History';
import { Title, useTranslate } from 'react-admin';
import { useLocation } from 'react-router';
import { Box } from '@mui/material';

const MyError = ({ error, resetErrorBoundary, type, ...rest }: any) => {
    const { pathname } = useLocation();
    const originalPathname = React.useRef(pathname);

    // Effect that resets the error state whenever the location changes
    React.useEffect(() => {
        if (pathname !== originalPathname.current) {
            resetErrorBoundary();
        }
    }, [pathname, resetErrorBoundary]);

    const translate = useTranslate();

    let errorTitle = '';
    let errorDescription = '';
    switch (type) {
        case '404':
            errorTitle = 'このページは存在しません。';
            errorDescription = '正しいページへ遷移してください';
            break;
        case 'permission':
            errorTitle = 'このページに対する権限がありません';
            errorDescription = '正しいページへ遷移してください';
            break;

        default:
            errorTitle = 'Something Went Wrong';
            errorDescription = "A client error occurred and your request couldn't be completed.";
            break;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                padding: '20px'
            }}
        >
            <Title title="Error" />
            <h1>
                <ErrorIcon />
                {errorTitle}
            </h1>
            <div>{errorDescription}</div>
            {/* {process.env.NODE_ENV !== 'production' && (
                <details>
                    <h2>{translate(error.toString())}</h2>
                </details>
            )} */}
            <div>
                <Button
                    variant="contained"
                    startIcon={<History />}
                    onClick={() => history.go(-1)}
                    sx={{ marginTop: '20px' }}
                >
                    Back
                </Button>
            </div>
        </Box>
    );
};

export default MyError;
