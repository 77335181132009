import RecommendIcon from '@mui/icons-material/Newspaper';

import { RecommendList } from './RecommendList';
import { RecommendShow } from './RecommendShow';
import { RecommendEdit } from './RecommendEdit';
import { RecommendCreate } from './RecommendCreate';

export default {
    list: RecommendList,
    show: RecommendShow,
    create: RecommendCreate,
    edit: RecommendEdit,
    icon: RecommendIcon
};
