import * as React from 'react';
import { useTranslate } from 'ra-core';
import { useTiptapEditor } from 'ra-input-rich-text';
import { ToggleButton, ToggleButtonProps, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

export const MyHardBreakButton = (props: Omit<ToggleButtonProps, 'value'>) => {
    const translate = useTranslate();
    const editor = useTiptapEditor();

    const label = translate('ra.tiptap.hardbreak', { _: '改行' });

    const addHardBreak = React.useCallback(() => {
        editor.chain().focus().setHardBreak().run();
    }, [editor, translate]);

    return editor ? (
        <ToggleButton
            aria-label={label}
            title={label}
            {...props}
            disabled={!editor?.isEditable}
            value="hardbreak"
            onClick={addHardBreak}
        >
            <Typography sx={{ fontSize: '10px' }}>改行</Typography>
        </ToggleButton>
    ) : null;
};
