import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import {
    Box,
    Button,
    Typography,
    useMediaQuery,
    TextField as MuiTextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    DateField,
    useRecordSelection,
    useGetMany,
    useRecordContext,
    ArrayField,
    RecordContextProvider,
    useGetOne,
    useGetManyReference,
    Loading,
    BooleanInput,
    UrlField,
    RichTextField
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, ConstructionOutlined, OpenInNewOffOutlined } from '@mui/icons-material';
import { CustomEditorInput } from './CustomEditorInput';

export const TabBasic = ({ isShow, currentUser }: any) => {
    const record = useRecordContext();

    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 50px 0',
                    sm: '0'
                }
            }}
        >
            <Typography variant="h4" gutterBottom>
                おすすめコンテンツ設定
            </Typography>
            <ImageInput
                label="サムネイル"
                format={formatPreview}
                source="imageUrl"
                accept={Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION}
                isRequired
                fullWidth
                disabled={isShow}
                sx={
                    isShow && {
                        '& .RaFileInputPreview-removeButton': {
                            display: 'none'
                        }
                    }
                }
            >
                <ImageField
                    source="url"
                    title="title"
                    sx={{
                        width: '100%',
                        height: '400px',
                        '& img': {
                            height: 'auto',
                            width: 'auto',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                        }
                    }}
                />
            </ImageInput>
            <Separator />
            <Typography variant="h6">公開期間</Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <DateTimeInput label="公開開始" source="publishStart" isRequired disabled={isShow} />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateTimeInput label="公開終了" source="publishEnd" isRequired disabled={isShow} />
                </Box>
            </Box>
            <Separator />
            <BooleanInput source="publishFlag" label="公開フラグ" disabled={isShow} />
            {/* <BooleanInput
                source="popFlag"
                label="お知らせ表示フラグ"
                disabled={isShow} 
            /> */}
        </Box>
    );
};

const Separator = () => <Box pt="1em" />;

export const TabContents = ({ isShow, currentUser }: any) => {
    const record = useRecordContext();

    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 50px 0',
                    sm: '0'
                }
            }}
        >
            <TextInput label="記事タイトル" source="title" isRequired disabled={isShow} fullWidth />
            <Box>
                <ReferenceInput source="areaId" reference="areas">
                    <SelectInput
                        label="対象地区"
                        optionText="areaName"
                        // isRequired
                        disabled={isShow || currentUser.areaId || record ? true : false}
                        defaultValue={currentUser.areaId ? currentUser.areaId : undefined}
                        helperText="設定しない場合は、全地区に向けて公開します"
                    />
                </ReferenceInput>
            </Box>
            <Box
                // Editorのcontent内のスタイル設定
                sx={{
                    '& #content': {
                        minHeight: '350px',
                        '& blockquote': {
                            padding: '5px',
                            background: 'lightgray'
                        },
                        '& img': {
                            width: '100%',
                            maxWidth: '800px',
                            display: 'block',
                            margin: '0 auto'
                        },
                        '& iframe': {
                            width: '100%',
                            maxWidth: '800px',
                            display: 'block',
                            margin: '0 auto',
                            border: 'none'
                        }
                    }
                }}
            >
                <CustomEditorInput label="内容" source="content" disabled={isShow} fullWidth />
            </Box>
            <Box>
                <TextInput
                    label="サイトURL"
                    source="url"
                    disabled={isShow}
                    helperText={'urlを設定した場合は、記事内容は表示されません'}
                />
            </Box>
            <Box>
                <TextInput
                    label="YouTube URL"
                    source="youtubeUrl"
                    disabled={isShow}
                    helperText={'YouTubeのurlを設定した場合は、記事内容・外部urlは表示されません'}
                />
            </Box>
        </Box>
    );
};

export const CustomFormTab = ({ onSubmit, isShow, toolbar, currentUser }: any) => {
    return (
        <TabbedForm onSubmit={onSubmit} toolbar={toolbar}>
            <FormTab sx={{ maxWidth: '100%' }} label="記事入力">
                <TabContents isShow={isShow} currentUser={currentUser} />
            </FormTab>
            <FormTab sx={{ maxWidth: 600 }} label="基本設定">
                <TabBasic isShow={isShow} currentUser={currentUser} />
            </FormTab>
        </TabbedForm>
    );
};

function formatPreview(value: any) {
    if (!value || typeof value === 'string') {
        // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
        return { url: value };
    } else {
        // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}
