import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button as MuiButton, Typography, useMediaQuery, TextField as MuiTextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    Loading,
    useRecordContext,
    useDataProvider,
    useGetIdentity,
    TopToolbar,
    useUpdate,
    useRedirect,
    useNotify,
    Toolbar,
    SaveButton,
    DeleteWithConfirmButton
} from 'react-admin';
import { formatDate, uploadImageToFirebaseStorage, uploadVideoToFirebaseStorage } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, OpenInNewOffOutlined } from '@mui/icons-material';
import { TabBasic, TabQuestions } from './PostFormComponents';
import { Link, useSearchParams } from 'react-router-dom';
import CustomBreadcrumbs from '../layout/Breadcrumbs';
import { stringify } from 'query-string';
import Error from '../Error';
import { dark } from '@mui/material/styles/createPalette';

const ContestTitle = ({ record }: any) => {
    return <span>Contest {record ? `"${record.title}"` : ''}</span>;
};

type FormData = {
    title: string;
    subtitle: string;
    description: string;
    areaId: number;
    imageUrl: string;
    publishStart: Date;
    publishEnd: Date;
    applicationStart: Date;
    applicationEnd: Date;
    questions: [
        {
            text: string;
            type: number;
            options: Array<any>;
        }
    ];
};

const PostEditActions = ({ currentSelectionStatus }: any) => {
    const record = useRecordContext();

    return (
        <TopToolbar>
            <MuiButton
                variant="text"
                component={Link}
                size="small"
                startIcon={<VisibilityIcon />}
                to={`/posts/${record.id}/show?selectionStatus=${currentSelectionStatus}`}
            >
                表示
            </MuiButton>
            {/* <EditButton /> */}
        </TopToolbar>
    );
};

export const PostEdit = () => {
    const [contest, setContest] = useState<any>();
    const [record, setRecord] = useState<any>();
    const [searchParams] = useSearchParams();
    const currentSelectionStatus = searchParams.get('selectionStatus');
    const { identity: currentUser, isLoading } = useGetIdentity();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    const onSubmit = async (data: any) => {
        if (!contest) return;

        const tagIds: any[] = [];
        const newTags: any[] = [];

        // newTagsの抽出
        data.tagIds.map((tag: any) => {
            typeof tag == 'string' ? newTags.push(tag) : tagIds.push(tag);
        });
        data.tagIds = tagIds;
        data.newTags = newTags;

        // バリデーションのみ、filesは、バリデーションのため一時的に、rawFileのファイル名を入れておく。
        const validationFiles = data.files.map((file: any) => {
            return typeof file.url === 'string' ? file : { url: file.url.rawFile.name, fileType: file.fileType };
        });
        try {
            await update(
                'posts',
                {
                    id: data.id,
                    data: {
                        isValidationOnly: true,
                        ...data,
                        files: validationFiles.length > 0 ? validationFiles : undefined
                    }
                },
                { returnPromise: true }
            );
        } catch (error: any) {
            if (error.body.errors) {
                // The shape of the returned validation errors must match the shape of the form
                console.log(error.body.errors);
                // return error.body.errors;
                return error.body.errors;
            }
        }

        // アップロード処理
        for (let index = 0; index < data.files.length; index++) {
            const file = data.files[index];
            try {
                // fileが新規登録の場合アップロードを行う
                if (typeof file.url !== 'string') {
                    if (file.fileType === 'video') {
                        data.files[index].url = await uploadVideoToFirebaseStorage(
                            file.url.rawFile,
                            contest.areaId,
                            'posts'
                        );
                    } else {
                        data.files[index].url = await uploadImageToFirebaseStorage(
                            file.url.rawFile,
                            contest.areaId,
                            'posts'
                        );
                    }
                }
            } catch (error: any) {
                return {
                    files: {
                        [index]: {
                            url: 'ファイルのアップロードに失敗しました。適切なファイルを選択し直してください。'
                        }
                    }
                };
            }
        }

        // formの送信
        try {
            await update('posts', { id: data.id, data: data }, { returnPromise: true });
            notify('resources.posts.notification.updated_success', { type: 'success' });
            redirect(
                `/posts?` +
                    stringify({
                        filter: JSON.stringify({
                            contestId: contest.id,
                            selectionStatus_gte: parseInt(currentSelectionStatus ?? '0')
                        })
                    })
            );
        } catch (error: any) {
            notify('resources.posts.notification.updated_error', { type: 'error' });
            if (error.body.errors) {
                // The shape of the returned validation errors must match the shape of the form
                console.log(error.body.errors);
                // return error.body.errors;
                return error.body.errors;
            }
        }
    };

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            {contest && record ? (
                currentSelectionStatus ? (
                    <CustomBreadcrumbs
                        breadcrumbs={[
                            { url: '/contests', text: 'コンテスト一覧' },
                            {
                                url:
                                    `/posts?` +
                                    stringify({
                                        filter: JSON.stringify({
                                            contestId: contest.id,
                                            selectionStatus_gte: parseInt(currentSelectionStatus)
                                        })
                                    }),
                                text: `${contest.title}：${parseInt(currentSelectionStatus) + 1}次審査投稿一覧`
                            },
                            { url: null, text: `${record.tenantName}` }
                        ]}
                    />
                ) : (
                    <CustomBreadcrumbs
                        breadcrumbs={[
                            { url: '/contests', text: 'コンテスト一覧' },
                            {
                                url:
                                    `/posts?` +
                                    stringify({
                                        filter: JSON.stringify({
                                            contestId: contest.id,
                                            selectionStatus_gte: 0
                                        })
                                    }),
                                text: `${contest.title}：1次審査投稿一覧`
                            },
                            { url: null, text: `${record.tenantName}` }
                        ]}
                    />
                )
            ) : null}
            <Edit actions={<PostEditActions currentSelectionStatus={currentSelectionStatus} />}>
                <FormTabs
                    onSubmit={onSubmit}
                    setContest={setContest}
                    setRecord={setRecord}
                    contest={contest}
                    currentUser={currentUser}
                />
            </Edit>
        </Box>
    );
};

const FormTabs = ({ onSubmit, contest, setContest, setRecord, currentUser }: any) => {
    const record = useRecordContext();

    const [searchParams] = useSearchParams();
    const areaId = searchParams.get('areaId');
    const contestId = searchParams.get('contestId');
    const currentSelectionStatus = searchParams.get('selectionStatus');
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [malls, setMalls] = useState<any[]>();

    React.useEffect(() => {
        (async () => {
            if (!record) return;
            await setRecord(record);
            await dataProvider
                .getOne('contests', { id: record.contestId })
                .then(({ data }: any) => {
                    setContest(data);
                })
                .catch((error: any) => {
                    setError(error);
                    setLoading(false);
                });
            await dataProvider
                .getOne('malls', { id: record.mallId })
                .then(({ data }: any) => {
                    setMalls([data]);
                    setLoading(false);
                })
                .catch((error: any) => {
                    setError(error);
                    setLoading(false);
                });
        })();
    }, []);

    if (loading || !record) return <Loading />;
    if (error) return <Error />;

    // 権限別調整
    let isNotPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            if (currentUser.areaId !== record.areaId) isNotPermitted = true;
            break;
        case Consts.AUTORITY.mall:
            if (currentUser.mallId !== record.mallId) isNotPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPermitted = true;
            break;
        default:
            break;
    }

    if (isNotPermitted) return <Error type="permission" />;

    const redirect =
        `/posts?` +
        stringify({
            filter: JSON.stringify({
                contestId: record.contestId,
                selectionStatus_gte: parseInt(currentSelectionStatus ?? '0')
            })
        });
    const CustomToolbar = (props: any) => (
        <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
            <SaveButton />
            <DeleteWithConfirmButton
                confirmContent="投稿を削除してもよろしいですか？"
                confirmTitle="削除確認"
                redirect={redirect}
            />
        </Toolbar>
    );

    return (
        <TabbedForm onSubmit={onSubmit} toolbar={<CustomToolbar />}>
            <FormTab sx={{ maxWidth: 600 }} label="基本項目">
                <TabBasic
                    areaId={areaId}
                    contestId={contestId}
                    contest={contest}
                    malls={malls}
                    record={record}
                    currentUser={currentUser}
                />
            </FormTab>
            <FormTab sx={{}} label="設問回答">
                <TabQuestions
                    areaId={areaId}
                    contestId={contestId}
                    contest={contest}
                    malls={malls}
                    record={record}
                    currentUser={currentUser}
                />
            </FormTab>
        </TabbedForm>
    );
};
