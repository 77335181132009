import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, Typography, useMediaQuery, TextField as MuiTextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    useRecordContext,
    Loading,
    useGetIdentity,
    useCreate,
    useRedirect,
    useUpdate,
    useNotify,
    Toolbar,
    SaveButton,
    DeleteButton,
    DeleteWithConfirmButton
} from 'react-admin';
import LinkToRelatedPosts from './LinkToRelatedPosts';
import { formatDate, uploadImageToFirebaseStorage } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, OpenInNewOffOutlined } from '@mui/icons-material';
import { CustomFormTab, TabBasic, TabMallSelections, TabQuestions, TabScoringItems } from './ContestFormComponents';
import Error from '../Error';
import CustomBreadcrumbs from '../layout/Breadcrumbs';

type FormData = {
    title: string;
    subtitle: string;
    description: string;
    areaId: number;
    imageUrl: string;
    publishStart: Date;
    publishEnd: Date;
    applicationStart: Date;
    applicationEnd: Date;
    questions: [
        {
            text: string;
            type: number;
            options: Array<any>;
        }
    ];
};

const ContestTitle = ({ record }: any) => {
    return <span>Contest {record ? `"${record.title}"` : ''}</span>;
};

const PostEditToolbar = (props: any) => {
    const { isSubmitting, ...rest } = props;
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Toolbar {...rest} sx={{ justifyContent: 'space-between' }}>
            <SaveButton disabled={isSubmitting} label="保存" />
            <DeleteWithConfirmButton confirmContent="コンテストを削除してもよろしいですか？" confirmTitle="削除確認" />
            {/* <DeleteButton/> */}
            {/* <SaveButton
                label="post.action.save_and_add"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                        redirect(false);
                    }}
                }
                type="button"
                variant="text"
            /> */}
        </Toolbar>
    );
};

const ContestEditContent = ({ currentUser, onSubmit, isSubmitting, validate }: any) => {
    const record = useRecordContext();

    if (!record) return <Loading />;

    // 権限別調整
    let isNotPagePermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            isNotPagePermitted = record.areaId !== currentUser.areaId;
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = record.mallId !== currentUser.mallId;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    // コンテスト応募開始以降は設問設定の編集を不可にするフラグをtrueに
    let isNotEditQuestions = false;
    if (new Date() >= new Date(record.applicationStart)) {
        isNotEditQuestions = true;
    }

    // コンテスト応募終了以降は採点項目設定の編集を不可にするフラグをtrueに
    let isNotEditScoringItems = false;
    if (new Date() >= new Date(record.applicationEnd)) {
        isNotEditScoringItems = true;
    }

    return (
        <CustomFormTab
            validate={validate}
            onSubmit={onSubmit}
            currentUser={currentUser}
            toolbar={<PostEditToolbar isSubmitting={isSubmitting} />}
            isNotEditQuestions={isNotEditQuestions}
            isNotEditScoringItems={isNotEditScoringItems}
        />
    );
};

export const ContestEdit = () => {
    const { identity: currentUser, isLoading } = useGetIdentity();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const validateContestsForm = async (values: any) => {
        const errors: any = {};
        if (!values.title) {
            errors.title = '必須です';
        }
        if (!values.subtitle) {
            errors.subtitle = '必須です';
        }
        if (values.contestType === '') {
            errors.contestType = '必須です';
        }
        if (!values.maxSelectionNumber) {
            errors.maxSelectionNumber = '必須です';
        }
        if (!values.areaId) {
            errors.areaId = '必須です';
        }
        if (!values.imageUrl) {
            errors.imageUrl = '必須です';
        }
        if (!values.applicationStart) {
            errors.applicationStart = '必須です';
        }
        if (!values.applicationEnd) {
            errors.applicationEnd = '必須です';
        }
        if (!values.firstSelectionCapacityBasis) {
            errors.firstSelectionCapacityBasis = '必須です';
        }
        if (!values.mallSelections?.length) {
            errors.mallSelections = 'SC審査設定に不備があります';
        }
        if (values.questions?.length == 0) {
            errors.questions = '設問を1つ以上を入力してください';
        }
        if (values.scoringItems?.length == 0) {
            errors.scoringItems = '採点項目を1つ以上を入力してください';
        }
        console.log('maxSelectionNumber:', values.maxSelectionNumber);
        console.log('errors:', errors);
        return errors;
    };

    const onSubmit = async (data: any) => {
        setIsSubmitting(true);
        // バリデーションのみ、imageUrlは、バリデーションのため一時的に、rawFileのファイル名を入れておく。
        console.log(data.id);
        try {
            await update(
                'contests',
                {
                    id: data.id,
                    data: {
                        isValidationOnly: true,
                        ...data,
                        imageUrl:
                            typeof data.imageUrl === 'string'
                                ? data.imageUrl
                                : data.imageUrl
                                ? data.imageUrl.rawFile.name
                                : null
                    }
                },
                { returnPromise: true }
            );
        } catch (error: any) {
            setIsSubmitting(false);
            if (error.body.errors) {
                // The shape of the returned validation errors must match the shape of the form
                console.log(error.body.errors);
                // return error.body.errors;
                return error.body.errors;
            }
        }

        // 画像のアップロード処理
        try {
            if (data.imageUrl && data.areaId && typeof data.imageUrl !== 'string') {
                data.imageUrl = await uploadImageToFirebaseStorage(data.imageUrl.rawFile, data.areaId, 'contests');
            }
        } catch (error: any) {
            setIsSubmitting(false);
            return {
                imageUrl: '画像のアップロードに失敗しました。適切な画像を選択し直してください。'
            };
        }

        // formの送信
        try {
            await update('contests', { id: data.id, data: data }, { returnPromise: true });
            notify('resources.contests.notification.updated_success', { type: 'success' });
            redirect('/contests');
        } catch (error: any) {
            setIsSubmitting(false);
            if (error.body.errors) {
                // The shape of the returned validation errors must match the shape of the form
                console.log(error.body.errors);
                // return error.body.errors;
                return error.body.errors;
            }
        }

        setIsSubmitting(false);
    };

    if (isLoading) return <Loading />;

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/contests', text: 'コンテスト一覧' },
                    { url: null, text: `コンテスト編集` }
                ]}
            />
            <Edit
                title="コンテスト編集"
                sx={{
                    padding: {
                        // xs: '20px 10px',
                        sm: '0px 0px 20px'
                    }
                }}
            >
                <ContestEditContent
                    validate={validateContestsForm}
                    currentUser={currentUser}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                />
            </Edit>
        </Box>
    );
};
