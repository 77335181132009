import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    useGetIdentity,
    Loading,
    TopToolbar,
    FilterButton,
    ExportButton,
    CreateButton,
    Pagination
} from 'react-admin';
import LinkToRelatedPosts from './LinkToRelatedPosts';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import { PostTotal } from './PostTotal';

const contestFilters = (currentUser: any) => {
    // 権限別調整
    let isNotPermitted;
    switch (currentUser?.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPermitted = true;
            break;
        default:
            break;
    }

    return !isNotPermitted
        ? [
              // [TODO] 未実装のため全文検索コメントアウト
              // <TextInput source="q" label="Search" alwaysOn />,
              <ReferenceInput source="areaId" label="地区" reference="areas" allowEmpty key="areaId">
                  <SelectInput optionText="areaName" />
              </ReferenceInput>
          ]
        : [
              // [TODO] 未実装のため全文検索コメントアウト
              // <TextInput source="q" label="Search" alwaysOn />,
          ];
};

const ContestListActions = ({ currentUser }: any) => {
    // 権限別調整
    let isNotFilterPermitted;
    let isNotCreatePermitted;
    switch (currentUser?.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotFilterPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotFilterPermitted = true;
            isNotCreatePermitted = true;
            break;
        default:
            break;
    }

    return (
        <>
            <TopToolbar>
                {!isNotFilterPermitted ? <FilterButton /> : null}
                {/* <ExportButton/> */}
                {!isNotCreatePermitted ? <CreateButton /> : null}
            </TopToolbar>
        </>
    );
};

const PostPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;

export const ContestList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const { identity: currentUser, isLoading } = useGetIdentity();

    if (isLoading) return <Loading />;

    // 権限別調整
    let filter = {};
    let isNotFirstPermitted;
    let isNotPermitted;
    switch (currentUser?.authority) {
        case Consts.AUTORITY.area:
            filter = {};
            break;
        case Consts.AUTORITY.mall:
            filter = {
                areaId: currentUser?.areaId
            };
            isNotPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            filter = {
                areaId: currentUser?.areaId
            };
            isNotFirstPermitted = true;
            break;
        default:
            break;
    }

    return (
        <List
            perPage={50}
            pagination={<PostPagination />}
            filters={contestFilters(currentUser)}
            actions={<ContestListActions currentUser={currentUser} />}
            sx={{
                padding: {
                    xs: '0px 10px',
                    sm: '0px 0px'
                }
            }}
            filter={filter}
            sort={{ field: 'id', order: 'DESC' }}
        >
            {/* {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => `${record.views} views`}
                    tertiaryText={record => new Date(
                        // record.published_at
                    ).toLocaleDateString()}
                />
            ) : ( */}
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="title" label="resources.contests.fields.contest_name" />
                <ReferenceField label="resources.contests.fields.area" source="areaId" reference="areas" link={false}>
                    <TextField source="areaName" />
                </ReferenceField>
                {/* <FunctionField label="resources.contests.fields.publish_period" sortBy="publishStart" render={
                        (record: any) => `${formatDate(new Date(record.publishStart), Consts.dateFormat)} ~ ${formatDate(new Date(record.publishEnd), Consts.dateFormat)}`
                    } /> */}
                <FunctionField
                    label="resources.contests.fields.application_period"
                    sortBy="applicationStart"
                    render={(record: any) =>
                        `${formatDate(new Date(record.applicationStart), Consts.dateFormat)} ~ ${formatDate(
                            new Date(record.applicationEnd),
                            Consts.dateFormat
                        )}`
                    }
                />
                <ReferenceManyField
                    label="resources.contests.fields.application_amount"
                    reference="posts"
                    target="contestId"
                    filter={{
                        selectionStatus_gte: 0,
                        admin: 1
                    }}
                >
                    <PostTotal />
                </ReferenceManyField>
                <FunctionField
                    label="resources.contests.fields.selection_status"
                    sortBy="selectionStatus"
                    render={(record: any) => {
                        let statusText = Consts.selectionStatus[record.selectionStatus];
                        let suffix = '中';
                        if (record.selectionStatus == record.maxSelectionNumber) {
                            statusText = Consts.selectionStatus[record.maxSelectionNumber - 1];
                            suffix = '完了';
                        }
                        if (
                            new Date(record.applicationEnd) >= new Date() &&
                            record.selectionStatus == Consts.SELECTION_STATUS.firstSelection
                        )
                            suffix = '開始待ち';
                        return statusText + suffix;
                    }}
                />
                {currentUser?.authority <= Consts.AUTORITY.integrate ? <EditButton /> : <ShowButton />}
                {!isNotFirstPermitted ? (
                    <LinkToRelatedPosts
                        // source="id"
                        selectionStatus={0}
                        label="resources.contests.fields.first_selection"
                    />
                ) : null}
                {!isNotPermitted ? (
                    <LinkToRelatedPosts
                        // source="id"
                        selectionStatus={1}
                        label="resources.contests.fields.second_selection"
                    />
                ) : null}
                {!isNotPermitted ? (
                    <LinkToRelatedPosts
                        // source="id"
                        selectionStatus={2}
                        label="resources.contests.fields.third_selection"
                    />
                ) : null}
            </Datagrid>
            {/* )} */}
        </List>
    );
};
