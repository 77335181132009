import ExcelJS from 'exceljs';
import { Consts } from '../../consts/Const';
import { numberToAlphabet } from '../../utils/util';

export const postsExporter = async (
    data: any,
    contestData: any,
    jobTypesData: any,
    tagsData: any,
    mallSelectionsData: any
) => {
    // 1. 初期化
    const workbook = new ExcelJS.Workbook(); // workbookを作成
    const worksheetName = '投稿';
    const scListWorksheetName = 'SC一覧';
    workbook.addWorksheet(worksheetName); // worksheetを追加
    workbook.addWorksheet(scListWorksheetName); // バリデーション用worksheetを追加
    const worksheet = workbook.getWorksheet(worksheetName); // 追加したworksheetを取得
    const scListWorksheet = workbook.getWorksheet(scListWorksheetName); // 追加したバリデーション用worksheetを取得

    // 2. データを用意
    // 各列のヘッダー
    const questionsHeaders: any[] = [];
    contestData.questions.map((question: any, index: number) => {
        if (question.type == Consts.QUESTION_TYPES.check) {
            question.options.map((option: any, j: number) => {
                questionsHeaders.push({
                    header: `回答${index + 1}（${option.optionText}）`,
                    key: `answer${index + 1}_option${j}`
                });
            });
        } else {
            questionsHeaders.push({
                header: `回答${index + 1}`,
                key: `answer${index + 1}`
            });
        }
    });

    worksheet.columns = [
        { header: 'SC名', key: 'mallName' },
        { header: 'テナント名', key: 'tenantName' },
        { header: '職種', key: 'jobType' },
        { header: 'ファイル1', key: 'file1' },
        { header: 'ファイル2', key: 'file2' },
        { header: 'ファイル3', key: 'file3' },
        { header: 'ファイル4', key: 'file4' },
        { header: 'ファイル5', key: 'file5' },
        ...questionsHeaders,
        { header: 'タグ1', key: 'tag1' },
        { header: 'タグ2', key: 'tag2' },
        { header: 'タグ3', key: 'tag3' },
        { header: 'タグ4', key: 'tag4' },
        { header: 'タグ5', key: 'tag5' },
        { header: 'いいね数', key: 'likes' },
        { header: '編集・削除キー', key: 'editKey' }
    ];

    // ヘッダーに合わせてデータを変換
    data.map((d: any) => {
        Object.keys(d).map((key) => {
            switch (key) {
                case 'mallId':
                    d.mallName = d.mall.mallName;
                    break;
                case 'jobTypeId':
                    d.jobType = jobTypesData!.find((jobType: any) => jobType.id == d[key]).name;
                    break;
                case 'files':
                    d[key].map((file: any, index: number) => {
                        d[`file${index + 1}`] = file.url;
                    });
                    break;
                case 'tagIds':
                    d[key].map((tagId: any, index: number) => {
                        d[`tag${index + 1}`] = tagsData!.find((tag: any) => tag.id == tagId).name;
                    });
                    break;
                case 'answers':
                    for (let i = 0; i < contestData.questions.length; i++) {
                        // 2:セレクト,4:ラジオの場合
                        if (
                            [Consts.QUESTION_TYPES.select, Consts.QUESTION_TYPES.radio].includes(
                                contestData.questions[i].type
                            )
                        ) {
                            d[`answer${i + 1}`] = contestData.questions[i].options[d[key][i].answer].optionText;
                        }
                        // 3:チェックボックスの場合
                        else if ([Consts.QUESTION_TYPES.check].includes(contestData.questions[i].type)) {
                            contestData.questions[i].options.map((option: any, index: number) => {
                                d[key][i].answer.includes(index)
                                    ? (d[`answer${i + 1}_option${index}`] = '◯')
                                    : (d[`answer${i + 1}_option${index}`] = '');
                            });
                        }
                        // 0:テキスト,1:テキストエリアの場合
                        else {
                            d[`answer${i + 1}`] = d[key][i].answer;
                        }
                    }
                    break;

                default:
                    break;
            }
        });
    });

    // データを追加（worksheet.columnsのkeyがオブジェクトのキーと同じになる）
    worksheet.addRows(data);

    // 3. 書式設定等
    // セルのバリデーション（ドロップダウン選択）

    // SC名
    // 選択しうるSC名を別シートにリストアップする。入力規則用
    scListWorksheet.columns = [{ header: scListWorksheetName, key: 'mallName' }];
    scListWorksheet.addRows(mallSelectionsData);
    // SC名の列全てに適応
    for (let i = 2; i <= 9999; i++) {
        worksheet.getCell(`A${i}`).dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [`${scListWorksheetName}!$A$2:$A$${mallSelectionsData.length + 1}`],
            showErrorMessage: true,
            errorStyle: 'error',
            errorTitle: 'SC名エラー',
            error: 'SC名が無効な値です。'
        };
    }

    // 職種
    const jobTypeFormulae = jobTypesData!.map((jobType: any) => jobType.name).toString();
    for (let i = 2; i <= 9999; i++) {
        worksheet.getCell(`C${i}`).dataValidation = {
            type: 'list',
            allowBlank: true,
            formulae: [`"${jobTypeFormulae}"`],
            showErrorMessage: true,
            errorStyle: 'error',
            errorTitle: '職種エラー',
            error: '職種が無効な値です。'
        };
    }

    // 回答
    worksheet.columns.map((column: any, index: number) => {
        if (column.key!.indexOf('answer') !== 0) return;

        let answerFormulae = '';

        const questionIndex: number = Number(column.key.replace(/answer|_option[0-9]*$/g, '')) - 1;

        // 2:セレクト,4:ラジオの場合
        if (
            [Consts.QUESTION_TYPES.select, Consts.QUESTION_TYPES.radio].includes(
                contestData.questions[questionIndex].type
            )
        ) {
            answerFormulae = contestData.questions[questionIndex].options
                .map((option: any) => option.optionText)
                .toString();
        }
        // 3:チェックボックスの場合
        else if ([Consts.QUESTION_TYPES.check].includes(contestData.questions[questionIndex].type)) {
            answerFormulae = '　,◯';
        }
        // 0:テキスト,1:テキストエリアの場合
        else {
            answerFormulae = '';
        }

        if (answerFormulae) {
            for (let i = 2; i <= 9999; i++) {
                worksheet.getCell(`${numberToAlphabet(index)}${i}`).dataValidation = {
                    type: 'list',
                    allowBlank: true,
                    formulae: [`"${answerFormulae}"`],
                    showErrorMessage: true,
                    errorStyle: 'error',
                    errorTitle: '回答エラー',
                    error: '回答が無効な値です。'
                };
            }
        }
    });

    // // セル表示：折り返しなし、切り詰め（ファイル部分4~8列）
    // for (let index = 4; index <= 8; index++) {
    //     worksheet.getColumn(index).alignment = { horizontal: 'fill'};
    // }

    // 1行目、1~2列目を固定にする
    worksheet.views = [{ state: 'frozen', ySplit: 1, xSplit: 2 }];

    // 4. ファイル生成
    const uint8Array = await workbook.xlsx.writeBuffer(); // xlsxの場合
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const a = document.createElement('a');
    a.href = (window.URL || window.webkitURL).createObjectURL(blob);
    a.download = `posts.xlsx`;
    a.click();
    a.remove();
};
