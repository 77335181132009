import * as React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';
import admins from './admins';
import contests from './contests';
import posts from './posts';
import malls from './malls';
import customDataProvider from './dataProvider';
import jsonServerProvider from 'ra-data-json-server';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import { Login, Layout } from './layout';
import japanbeseMessages from './i18n/ja';
import { lightTheme } from './layout/themes';
import Configuration from './configuration/Configuration';
import recommends from './recommends';
import rankings from './rankings';
import searchSettings from './searchSettings';
const dataProvider = customDataProvider(process.env.REACT_APP_API_URL!);
const i18nProvider = polyglotI18nProvider((locale) => {
    if (locale === 'en') {
        return import('./i18n/en').then((messages) => messages.default);
    }

    // Always fallback on japanese
    return japanbeseMessages;
}, 'ja');

const App = () => (
    <Admin
        // [TODO] ダッシュボード表示
        // dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
        disableTelemetry
        theme={lightTheme}
        basename={process.env.REACT_APP_ROUTER_BASENAME}
    >
        <CustomRoutes>
            <Route path="/configuration" element={<Configuration />} />
        </CustomRoutes>
        <Resource name="contests" {...contests} />
        <Resource name="posts" {...posts} />
        <Resource name="malls" {...malls} />
        <Resource name="admins" {...admins} />
        <Resource name="recommends" {...recommends} />
        <Resource name="rankings" {...rankings} />
        <Resource name="searchSettings" {...searchSettings} />
        <Resource
            name="scores"
            // {...scores}
        />
        <Resource
            name="jobTypes"
            // {...users}
        />
        <Resource
            name="mallSelections"
            // {...users}
        />
    </Admin>
);

export default App;
