import { auth, initFirebaseAuth } from './firebase';
import {
    signInWithEmailAndPassword,
    signOut,
    signInWithCustomToken,
    onAuthStateChanged,
    setPersistence,
    browserSessionPersistence,
    inMemoryPersistence,
    browserLocalPersistence,
    User
} from 'firebase/auth';
import axios from './axios/axios';
import { ConstructionOutlined } from '@mui/icons-material';
import { areArraysEqual } from '@mui/base';
import { Consts } from './consts/Const';

const basename = process.env.REACT_APP_ROUTER_BASENAME;

export default {
    // called when the user attempts to log in
    login: async ({ username, password }: any) => {
        // Firebase Authのメールアドレス（管理画面用）
        const email = username + '@' + Consts.LOGIN_MAIL_DOMAIN_ADMIN;
        // await setPersistence(auth, inMemoryPersistence);
        const userCredential = await setPersistence(auth, browserLocalPersistence).then(() => {
            return signInWithEmailAndPassword(auth, email, password);
        });
        // const userCredential = await signInWithEmailAndPassword(auth, email, password);

        const { data } = await axios.get(`/csrf-token`);
        const token = await userCredential.user.getIdToken(true);

        const result = await axios.post(`/sessionLogin`, {
            idToken: token,
            csrfToken: data.csrfToken
        });

        if (result.status == 200) {
            Promise.resolve();
        } else {
            Promise.reject();
        }
    },
    // called when the user clicks on the logout button
    logout: async () => {
        // await axios.post(`/sessionLogout`).then(()=> {
        await signOut(auth);
        Promise.resolve();
        // }).catch((error: any)=>{
        //     console.log(error);
        //     Promise.resolve();
        // });
    },
    // called when the API returns an error
    checkError: ({ status }: any) => {
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        try {
            // [TODO] サードバーティCookie問題があるため、axios.postの第二引数{isGettingToken: true}を一旦削除
            await axios.post(`/checkAuth`).then(async (response: any) => {
                if (response.data.token) {
                    // カスタムトークンで認証
                    await signInWithCustomToken(auth, response.data.token).then(async (userCredential) => {
                        if (response.data.isNotExtended) {
                            return Promise.resolve();
                        }

                        const { data } = await axios.get(`/csrf-token`);
                        const token = await userCredential.user.getIdToken();
                        const result = await axios.post(`/sessionLogin`, {
                            idToken: token,
                            csrfToken: data.csrfToken
                        });

                        if (result.status == 200) {
                            return Promise.resolve();
                        }

                        throw Error();
                    });
                } else {
                    if (response.data.status == 'error') {
                        throw Error();
                    }

                    return Promise.resolve();
                }
            });
        } catch (error) {
            console.log('Not logged in.', error);
            return Promise.reject({ redirectTo: '/login' });
        }
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async () => {
        try {
            const user: any = await axios.post(`/getIdentity`);
            const id = user.data.id;
            const fullName = user.data.name;
            const avatar = '';
            const areaId: number = user.data.areaId;
            const mallId: number = user.data.mallId;
            const authority: number = user.data.authority;
            console.log('getPermissions', { id, fullName, avatar, areaId, mallId, authority });
            return Promise.resolve({ id, fullName, avatar, areaId, mallId, authority });
        } catch (error) {
            return Promise.reject();
        }
    },
    getIdentity: async () => {
        try {
            const user: any = await axios.post(`/getIdentity`);
            const id = user.data.id;
            const fullName = user.data.name;
            const avatar = '';
            const areaId: number = user.data.areaId;
            const mallId: number = user.data.mallId;
            const authority: number = user.data.authority;
            console.log('getIdentity', { id, fullName, avatar, areaId, mallId, authority });
            return Promise.resolve({ id, fullName, avatar, areaId, mallId, authority });
        } catch (error) {
            console.log('Cannot get identity.', error);
            location.href = (basename ? '/' + basename : '') + '/#/login';
            return Promise.reject({ redirectTo: '/login' });
        }
    }
};
