export const Consts = {
    AUTORITY: {
        system: 0,
        integrate: 1,
        area: 2,
        mall: 3,
        maker: 4
    },
    SELECTION_STATUS: {
        firstSelection: 0,
        secondSelection: 1,
        thirdSelection: 2,
        fourthSelection: 3
    },
    PRIZE: {
        lost: 0,
        bronze: 1,
        silver: 2,
        gold: 3
    },
    CONTEST_TYPES: {
        images: 0,
        video: 1
    },
    QUESTION_TYPES: {
        text: 0,
        textarea: 1,
        select: 2,
        check: 3,
        radio: 4
    },
    DIMENTION: {
        malls: 'malls',
        posts: 'posts'
    },
    METRIX: {
        views: 1,
        likes: 2,
        comments: 3,
        posts: 4
    },
    SORT: {
        prize: 'prize',
        views: 'views',
        likes: 'likes',
        comments: 'comments'
    },
    dateFormat: 'yyyy-MM-dd HH:mm',
    selectionStatus: ['１次審査', '２次審査', '３次審査', '３次審査'],
    selectionStatusPost: ['１次審査', '２次審査', '３次審査', '３次審査'],
    prize: ['落選', '敢闘賞', '優秀賞', '大賞'],
    authorities: ['システム管理者', '同友店統括部', '地区担当者', 'SC', '審査関係者'],
    ACCEPTABLE_IMAGE_FILE_EXTENSION: 'image/*',
    ACCEPTABLE_VIDEO_FILE_EXTENSION: '.mp4,.mov,.avi,.m4v,.wmv,.3gp,.qt',
    ACCEPTABLE_IMAGE_FILE_SIZE: 1024 * 1024 * 100,
    ACCEPTABLE_VIDEO_FILE_SIZE: 1024 * 1024 * 500,
    LOGIN_MAIL_DOMAIN_ADMIN:
        process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID === 'am-vp-contest-site-staging'
            ? 'am-vp-contest-site-staging.com'
            : process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID + '-admin.web.app'
};
