import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import {
    Box,
    Button as MuiButton,
    Typography,
    useMediaQuery,
    TextField as MuiTextField,
    CircularProgress
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    Loading,
    useRecordContext,
    useDataProvider,
    Show,
    useGetIdentity,
    TopToolbar
} from 'react-admin';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, OpenInNewOffOutlined } from '@mui/icons-material';
import { TabBasic, TabQuestions, TabScore } from './PostFormComponents';
import { Link, useSearchParams } from 'react-router-dom';
import CustomBreadcrumbs from '../layout/Breadcrumbs';
import { stringify } from 'query-string';
import Error from '../Error';

const ContestTitle = ({ record }: any) => {
    return <span>Contest {record ? `"${record.title}"` : ''}</span>;
};

type FormData = {
    title: string;
    subtitle: string;
    description: string;
    areaId: number;
    imageUrl: string;
    publishStart: Date;
    publishEnd: Date;
    applicationStart: Date;
    applicationEnd: Date;
    questions: [
        {
            text: string;
            type: number;
            options: Array<any>;
        }
    ];
};

const PostShowActions = ({ currentSelectionStatus }: any) => {
    const record = useRecordContext();
    const { identity: currentUser, isLoading } = useGetIdentity();

    if (!record) return <CircularProgress />;
    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    // 権限別調整
    let isNotPermittedEdit;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            if (currentUser.areaId !== record.areaId) isNotPermittedEdit = true;
            break;
        case Consts.AUTORITY.mall:
            if (currentUser.mallId !== record.mallId) isNotPermittedEdit = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPermittedEdit = true;
            break;
        default:
            break;
    }

    if (isNotPermittedEdit) return <TopToolbar></TopToolbar>;

    return (
        <TopToolbar>
            <MuiButton
                variant="text"
                component={Link}
                size="small"
                startIcon={<VisibilityIcon />}
                to={`/posts/${record.id}?selectionStatus=${currentSelectionStatus}`}
            >
                編集
            </MuiButton>
            {/* <EditButton /> */}
        </TopToolbar>
    );
};

export const PostShow = () => {
    const [contest, setContest] = useState<any>();
    const [record, setRecord] = useState<any>();
    const [searchParams] = useSearchParams();
    const currentSelectionStatus = searchParams.get('selectionStatus');
    const { identity: currentUser, isLoading } = useGetIdentity();

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            {contest && record ? (
                currentSelectionStatus ? (
                    <CustomBreadcrumbs
                        breadcrumbs={[
                            { url: '/contests', text: 'コンテスト一覧' },
                            {
                                url:
                                    `/posts?` +
                                    stringify({
                                        filter: JSON.stringify({
                                            contestId: contest.id,
                                            selectionStatus_gte: parseInt(currentSelectionStatus)
                                        })
                                    }),
                                text: `${contest.title}：${parseInt(currentSelectionStatus) + 1}次審査投稿一覧`
                            },
                            { url: null, text: `${record.tenantName}` }
                        ]}
                    />
                ) : (
                    <CustomBreadcrumbs
                        breadcrumbs={[
                            { url: '/contests', text: 'コンテスト一覧' },
                            {
                                url:
                                    `/posts?` +
                                    stringify({
                                        filter: JSON.stringify({
                                            contestId: contest.id,
                                            selectionStatus_gte: 0
                                        })
                                    }),
                                text: `${contest.title}：1次審査投稿一覧`
                            },
                            { url: null, text: `${record.tenantName}` }
                        ]}
                    />
                )
            ) : null}
            <Show actions={<PostShowActions currentSelectionStatus={currentSelectionStatus} />}>
                <FormTabs
                    setContest={setContest}
                    setRecord={setRecord}
                    contest={contest}
                    isShow={true}
                    currentUser={currentUser}
                />
            </Show>
        </Box>
    );
};

const FormTabs = ({ contest, setContest, setRecord, isShow, currentUser }: any) => {
    const record = useRecordContext();

    const [searchParams] = useSearchParams();
    const areaId = searchParams.get('areaId');
    const contestId = searchParams.get('contestId');
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [malls, setMalls] = useState<any[]>();

    React.useEffect(() => {
        (async () => {
            if (!record) return;
            await setRecord(record);
            await dataProvider
                .getOne('contests', { id: record.contestId })
                .then(({ data }: any) => {
                    setContest(data);
                })
                .catch((error: any) => {
                    setError(error);
                    setLoading(false);
                });
            await dataProvider
                .getOne('malls', { id: record.mallId })
                .then(({ data }: any) => {
                    setMalls([data]);
                    setLoading(false);
                })
                .catch((error: any) => {
                    setError(error);
                    setLoading(false);
                });
        })();
    }, [record]);

    if (loading || !record) return <Loading />;
    if (error) return <Error />;

    // 権限別調整
    let isNotPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPermitted = currentUser.mallId !== record.mallId;
            break;
        case Consts.AUTORITY.maker:
            isNotPermitted = currentUser.areaId !== record.areaId;
            break;
        default:
            break;
    }

    if (isNotPermitted) return <Error type="permission" />;

    return (
        <TabbedForm toolbar={<></>}>
            <FormTab sx={{ maxWidth: 600 }} label="基本項目">
                <TabBasic
                    areaId={areaId}
                    contestId={contestId}
                    contest={contest}
                    malls={malls}
                    record={record}
                    isShow={isShow}
                    currentUser={currentUser}
                />
            </FormTab>
            <FormTab sx={{}} label="設問回答">
                <TabQuestions
                    areaId={areaId}
                    contestId={contestId}
                    contest={contest}
                    malls={malls}
                    record={record}
                    isShow={isShow}
                    currentUser={currentUser}
                />
            </FormTab>
            <FormTab sx={{}} label="採点・コメント">
                <TabScore contest={contest} record={record} />
            </FormTab>
        </TabbedForm>
    );
};
