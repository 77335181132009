import { Box, Typography, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

const VideoViewer = (props: any) => {
    const { videoUrl } = props;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        checkVideoUrl(videoUrl)
            .then((exists) => {
                if (exists) {
                    setLoading(false);
                } else {
                    setLoading(false);
                    setError(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    }, [videoUrl]);

    if (loading)
        return (
            <Box
                sx={{
                    height: '375px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    maxWidth: '100%',
                    width: '100%',
                    bgcolor: 'background.default'
                }}
            >
                <CircularProgress size={50} thickness={5} />
            </Box>
        );

    if (error)
        return (
            <Box
                sx={{
                    height: '375px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    maxWidth: '100%',
                    width: '100%',
                    bgcolor: 'background.default'
                }}
            >
                <Box>
                    <Typography textAlign={'center'}>動画をアップロード中です。</Typography>
                    <Typography textAlign={'center'}>しばらく経ってから再度ご確認ください。</Typography>
                </Box>
            </Box>
        );

    return (
        <Box
            sx={{
                height: '375px',
                width: '100%'
            }}
        >
            <video src={videoUrl} width="100%" height="100%" controls autoPlay={false} />
        </Box>
    );
};

async function checkVideoUrl(url: string): Promise<boolean> {
    return new Promise((resolve) => {
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
            resolve(true);
        };
        video.onerror = () => {
            resolve(false);
        };
        video.src = url;
    });
}

export default VideoViewer;
