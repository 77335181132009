import {
    DefaultEditorOptions,
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons
} from 'ra-input-rich-text';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import HardBreak from '@tiptap/extension-hard-break';
import Youtube from '@tiptap/extension-youtube';
import { MyImageButton } from './CustomEditorInputImageButton';
import { MyHardBreakButton } from './CustomEditorInputHardBreakButton';

import { Box } from '@mui/material';
import { MyYoutubeButton } from './CustomEditorInputYoutubeButton';

export const CustomEditorInput = ({ size, ...props }: any) => {
    return (
        <RichTextInput
            editorOptions={MyEditorOptions}
            toolbar={
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContents: 'flex-start',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        width: '100%'
                    }}
                >
                    <RichTextInputToolbar>
                        <LevelSelect size={size} />
                        <FormatButtons size={size} />
                        <AlignmentButtons size={size} />
                        <ListButtons size={size} />
                        <LinkButtons size={size} />
                        <QuoteButtons size={size} />
                        <ClearButtons size={size} />
                        <MyImageButton />
                        <MyYoutubeButton />
                        <MyHardBreakButton />
                    </RichTextInputToolbar>
                </Box>
            }
            {...props}
        />
    );
};

const MyEditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        ...DefaultEditorOptions.extensions,
        Image,
        HardBreak,
        Youtube.configure({
            controls: true,
            inline: false
        })
    ],
    editorProps: {
        attributes: {
            spellcheck: 'false'
        }
    }
};
