import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CreateBase, FileField, FileInput, Form, ImageField, ImageInput, Loading, SaveButton } from 'react-admin';
import axios from '../axios/axios';
import { Box } from '@mui/system';
import { Card, CardContent, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';
import Encoding from 'encoding-japanese';

export default function FormDialog() {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsloading] = React.useState(false);
    const [confirm, setConfirm] = React.useState<any>({});
    const [confirmError, setConfirmError] = React.useState(false);
    const [confirmErrorMessage, setConfirmErrorMessage] = React.useState('');
    const [csvItems, setCsvItems] = React.useState<any>(null);
    const [isCompleted, setIsCompleted] = React.useState(false);
    const [completeError, setCompleteError] = React.useState<any[]>([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == 'backdropClick' && isLoading) return;
        setOpen(false);
        setIsloading(false);
        setConfirm({});
        setIsCompleted(false);
        setCompleteError([]);
        setConfirmError(false);
        setConfirmErrorMessage('');
        setCsvItems(null);
    };

    const onSubmit = async (data: any) => {
        if (!csvItems) {
            setIsloading(true);

            const fileReader: any = new FileReader();
            fileReader.readAsArrayBuffer(data.files.rawFile);
            fileReader.onload = async () => {
                // 8ビット符号なし整数値配列と見なす
                const array: any = new Uint8Array(fileReader.result);
                // 文字コードを取得
                console.log(Encoding.detect(array));
                // Unicodeの数値配列に変換
                const unicodeArray = Encoding.convert(array, 'UNICODE');
                // Unicodeの数値配列を文字列に変換
                const text = Encoding.codeToString(unicodeArray);

                // ファイル読み込み
                let fileResult = text.split(/\n|\r\n/);
                // 先頭行をヘッダとして格納
                const header: any = fileResult[0].split(',');
                console.log({ header });
                // 先頭行の削除
                fileResult.shift();
                // 最終行が不要ならば削除
                fileResult = fileResult.filter((row, index) => row.indexOf(',') !== -1);

                // CSVから情報を取得
                const items = fileResult.map((item: any) => {
                    const datas = item.split(',');
                    const result: any = {};
                    for (const index in datas) {
                        const key = header[index].replace(/\s|\ufeff/g, '');
                        result[key] = datas[index];
                    }
                    return result;
                });

                try {
                    // バリデーション確認
                    const results = await axios.post('/malls/bulkUpdateMallCheck', items);

                    // 結果を格納
                    setCsvItems(results.data.items);
                    setConfirm(results.data.results);

                    // errorが存在するかどうか
                    Object.keys(results.data.results).map((id: any) => {
                        results.data.results[id].checks.map((d: any) => {
                            if (d.status === 'error') {
                                setConfirmError(true);
                            }
                        });
                    });
                } catch (error: any) {
                    setCsvItems(items);
                    setConfirmError(true);
                    setConfirmErrorMessage(error.response.data.message);
                }

                setIsloading(false);
            };
        }
        // 確認後の実行
        else {
            setIsloading(true);

            const errors = [];

            // 一括インポート実行
            for (let index = 0; index < csvItems.length; index++) {
                const csvItem = csvItems[index];

                try {
                    // 新規登録
                    if (!csvItem.docId) {
                        await axios.post('/malls', csvItem);
                    }
                    // 更新
                    else {
                        await axios.patch('/malls/' + csvItem.id, csvItem);
                    }
                } catch (error: any) {
                    errors.push({ id: csvItem.id });
                }
            }

            setConfirm({});
            setConfirmError(false);
            setConfirmErrorMessage('');
            setIsCompleted(true);
            setCompleteError(errors);
            setCsvItems(false);
            setIsloading(false);
        }
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                一括インポート
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>SC一括インポート</DialogTitle>
                {!isCompleted ? (
                    <Form onSubmit={onSubmit}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <DialogContent>
                                {!csvItems ? (
                                    <FileInput
                                        source="files"
                                        label="csvファイル" // 仕様通り{false}にしたいが、errorが発生する。
                                        accept=".csv"
                                        multiple={false}
                                        format={formatPreview}
                                        placeholder={
                                            'csvファイルをアップロードするファイルをドロップするか、クリックして選択してください。'
                                        }
                                        sx={{
                                            '& .RaFileInput-dropZone': {
                                                background: 'transparent',
                                                border: '2px dashed gray',
                                                borderRadius: '6px',
                                                padding: '50px'
                                            }
                                        }}
                                    >
                                        <FileField source="src" title="title" rel="noopener" />
                                    </FileInput>
                                ) : (
                                    <Box>
                                        <Box>
                                            {confirmError
                                                ? '以下のエラー（赤字）があるため、一括インポートを実行できません。csvファイルを修正し、再度ファイル選択してください。'
                                                : '一括インポートを実行してよろしいですか。項目に警告がある場合、以下に表示されます。'}
                                        </Box>
                                        {confirmError && confirmErrorMessage ? (
                                            <Card variant="outlined" sx={{ marginTop: '15px', padding: '10px' }}>
                                                <Typography variant="body2" gutterBottom color="red">
                                                    {confirmErrorMessage}
                                                </Typography>
                                            </Card>
                                        ) : null}
                                        {Object.keys(confirm).map((id: any, index: number) =>
                                            confirm[id].checks.length > 0 ? (
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{ marginTop: '15px', padding: '10px' }}
                                                >
                                                    <Typography fontWeight={'bold'}>
                                                        ID: {id}、SC名: {confirm[id].mallName}
                                                    </Typography>
                                                    <Box>
                                                        {confirm[id].checks.map((c: any, i: number) => {
                                                            return (
                                                                <Typography
                                                                    key={i}
                                                                    variant="body2"
                                                                    gutterBottom
                                                                    color={() => {
                                                                        switch (c.status) {
                                                                            case 'error':
                                                                                return 'red';
                                                                            case 'warning':
                                                                                return 'orange';
                                                                            case 'new':
                                                                                return 'green';
                                                                        }
                                                                    }}
                                                                >
                                                                    ・{c.message}
                                                                </Typography>
                                                            );
                                                        })}
                                                    </Box>
                                                </Card>
                                            ) : null
                                        )}
                                    </Box>
                                )}
                            </DialogContent>
                        )}
                        <DialogActions>
                            <Button onClick={handleClose} disabled={isLoading}>
                                キャンセル
                            </Button>
                            {csvItems ? (
                                <Button
                                    onClick={() => {
                                        setConfirm({});
                                        setConfirmError(false);
                                        setConfirmErrorMessage('');
                                        setCsvItems(null);
                                    }}
                                    disabled={isLoading}
                                >
                                    ファイルを再選択
                                </Button>
                            ) : null}
                            <SaveButton
                                disabled={isLoading || confirmError}
                                label={csvItems ? '実行する' : '確認する'}
                            />
                        </DialogActions>
                    </Form>
                ) : (
                    <>
                        <DialogContent>
                            一括インポートが完了しました。
                            {completeError.length > 0
                                ? completeError.map((error: any, i: number) => {
                                      return (
                                          <Typography key={i} variant="body2" gutterBottom color="red">
                                              ・{error.id}の登録に失敗しました。
                                          </Typography>
                                      );
                                  })
                                : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>閉じる</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
}

function formatPreview(value: any) {
    return value;
    // if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    //     return { url: value };
    // } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
    //     return value;
    // }
}
