import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Loading, useRefresh } from 'react-admin';
import Error from '../Error';
import { CropLandscapeOutlined } from '@mui/icons-material';
import { Consts } from '../consts/Const';
import axios from '../axios/axios';

export default function FormDialog({ contestData }: any) {
    const [open, setOpen] = React.useState(false);
    const [selectionError, setSelectionError] = React.useState(false);
    const [selectionLoading, setSelectionLoading] = React.useState(false);
    const [isFinished, setIsFinished] = React.useState(false);
    const refresh = useRefresh();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectionError(false);
        setSelectionLoading(false);
        setIsFinished(false);
        refresh();
    };

    const onSubmit = async (data: any) => {
        setSelectionLoading(true);

        // postsへの処理
        const results = await axios
            .post('/forceExcuteSelection', {
                contestId: contestData.id
            })
            .then((respose) => {
                console.log(respose.data.message);
            })
            .catch((error) => {
                setSelectionError(true);
                console.warn(error.response.data.message);
            });

        // [TODO] 強制移行の場合、該当contestsが更新されるので自動でリフレッシュが行われるため以下コメント
        setSelectionLoading(false);
        setIsFinished(true);
    };

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<ArrowForwardIcon />}
                onClick={handleClickOpen}
            >
                １次審査を締め切り２次審査に進める
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>１次審査を締め切り２次審査に進める</DialogTitle>
                {!selectionError ? (
                    !selectionLoading ? (
                        isFinished ? (
                            <>
                                <DialogContent>
                                    <DialogContentText>１次審査を締め切り２次審査へ進めました。</DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<ArrowForwardIcon />}
                                        onClick={handleClose}
                                        disabled={selectionLoading}
                                    >
                                        確認
                                    </Button>
                                </DialogActions>
                            </>
                        ) : (
                            <>
                                <DialogContent>
                                    <DialogContentText>
                                        １次審査を締め切り２次審査に進めてよろしいでしょうか。
                                        <br />※ 一度、締め切ると審査段階を戻すことはできません。
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} disabled={selectionLoading}>
                                        キャンセル
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<ArrowForwardIcon />}
                                        onClick={onSubmit}
                                        disabled={selectionLoading}
                                    >
                                        確定する
                                    </Button>
                                </DialogActions>
                            </>
                        )
                    ) : (
                        <Loading />
                    )
                ) : (
                    <>
                        <DialogContent>
                            <DialogContentText>審査を進められませんでした。</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>閉じる</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
}
