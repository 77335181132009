import ContestIcon from '@mui/icons-material/Book';

import { ContestList } from './ContestList';
import { ContestShow } from './ContestShow';
import { ContestEdit } from './ContestEdit';
import { ContestCreate } from './ContestCreate';

export default {
    list: ContestList,
    show: ContestShow,
    create: ContestCreate,
    edit: ContestEdit,
    icon: ContestIcon
};
