import * as React from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { Card, Grid, InputAdornment, Typography, Button as MuiButton } from '@mui/material';
import {
    Button,
    Confirm,
    CreateBase,
    Form,
    NumberInput,
    RadioButtonGroupInput,
    SaveButton,
    TextInput,
    useCreate,
    useEditController,
    useRefresh,
    useUpdate
} from 'react-admin';
import { Carousel } from 'react-responsive-carousel';
import Error from '../Error';
import { Consts } from '../consts/Const';
import { getAnswerString } from '../utils/util';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VideoViewer from './VideoViewer';

export const ScoreForm = (props: any) => {
    const scores = props.record.scores;
    const targetScore = scores.find((score: any) => score.phase == props.currentSelectionStatus + 1);

    return (
        <>
            {targetScore ? (
                <ScoreFormComponents scoreRecord={targetScore} {...props} />
            ) : (
                <ScoreFormComponents {...props} />
            )}
        </>
    );
};

export const ScoreFormComponents = (props: any) => {
    const { contestData } = props;
    const scoringItems = props.scoringItems;
    const { getValues, setValue, setError, clearErrors } = useFormContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const refresh = useRefresh();
    const [create, { isLoading: isUseCreateLoading, error: useCreateError }] = useCreate();
    const [update, { isLoading: isUseUpdateLoading, error: useUpdateError }] = useUpdate();

    const handleConfirm = () => {
        setOpen(false);
        refresh();
    };

    const handleDialogClose = () => {
        setOpen(false);
        refresh();
    };

    const handleClick = async (postId: any) => {
        setIsLoading(true);
        const formData = getValues();
        const scoreData = formData[`score${postId}`].map((score: any) =>
            isNaN(parseInt(score)) ? 0 : parseInt(score)
        );
        const comment = formData[`comment${postId}`];

        // バリデーション
        clearErrors();
        const errors = [];
        // 点数がallocationを超えているかどうか
        scoringItems.map((item: any, index: number) => {
            if (item.allocation < scoreData[index]) {
                errors.push({ postId: postId, index: index });
                setError(`score${postId}[${index}]`, { type: 'custom', message: '無効な点数です。' });
            }
        });

        if (errors.length > 0) {
            setIsLoading(false);
            return;
        }

        const data = {
            score: scoreData,
            comment: comment,
            phase: props.currentSelectionStatus + 1,
            postId: postId,
            adminId: props.currentUser.id
        };
        if (formData[`scoreId${postId}`]) {
            await update('scores', {
                id: formData[`scoreId${postId}`],
                data: data
            });
        } else {
            await create(
                'scores',
                {
                    data: data
                },
                {
                    onSettled: (data: any, error: any) => {
                        setValue(`scoreId${postId}`, data.id, { shouldValidate: true });
                    },
                    onError: (error: any) => {
                        // error is an instance of Error.
                    }
                }
            );
        }
        if (formData[`prize${postId}`]) {
            await update('posts', {
                id: postId,
                data: {
                    prize: formData[`prize${postId}`],
                    isSelection: true
                }
            });
            setValue(`prize${postId}`, formData[`prize${postId}`], { shouldValidate: true });
        }

        data.score.map((score: any, index: number) => {
            setValue(`score${postId}[${index}]`, score);
        });

        // [TODO] 処理完了までactiveにする処理
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setOpen(true);
        setIsLoading(false);
    };

    if (useCreateError || useUpdateError) return <Error />;

    return (
        <Grid container spacing={1}>
            <Grid item xs={5}>
                <Typography variant="h5">投稿ID: {props.record.id}</Typography>
                <Typography variant="h5">
                    {props.record.tenantName}（SCID: {props.record.mallId}、SC名: {props.record.mall.mallName}）
                </Typography>
                <Card>
                    {props.record.files[0].fileType === 'images' ? (
                        <ImageSlide {...props} />
                    ) : (
                        <VideoViewer
                            videoUrl={props.record.files[0].url.split('?')[0] + '__compressed.mp4?alt=media'}
                        />
                    )}
                </Card>
                {props.questions.map((question: any, index: number) => {
                    return (
                        <Grid item xs={12} key={index}>
                            <Typography variant="h6">
                                {'設問'}
                                {index + 1}) {question.text}
                            </Typography>
                            <Typography variant="subtitle1">
                                {getAnswerString(question, index, props.record.answers)}
                            </Typography>
                        </Grid>
                    );
                })}
            </Grid>
            <Grid item xs={7}>
                <Grid container spacing={1}>
                    {scoringItems.map((item: any, index: number) => {
                        return (
                            <Grid item xs={12} key={index}>
                                <Typography variant="h6">
                                    {index + 1}) {item.text}
                                </Typography>
                                <Typography variant="subtitle2">{item.description}</Typography>
                                <NumberInput
                                    label=""
                                    source={`scoreId${props.record.id}`}
                                    type="hidden"
                                    sx={{
                                        visibility: 'hidden',
                                        overflow: 'hidden',
                                        margin: 0,
                                        padding: 0,
                                        height: 0,
                                        width: 0
                                    }}
                                    defaultValue={props.scoreRecord ? props.scoreRecord.id : undefined}
                                />
                                <TextInput
                                    label="スコア"
                                    source={`score${props.record.id}[${index}]`}
                                    helperText={false}
                                    defaultValue={props.scoreRecord ? props.scoreRecord.score[index] : undefined}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">{`/${item.allocation}`}</InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        );
                    })}
                    {props.currentSelectionStatus !== Consts.SELECTION_STATUS.firstSelection ? (
                        <Grid item xs={12}>
                            <TextInput
                                label="コメント"
                                source={`comment${props.record.id}`}
                                defaultValue={props.scoreRecord ? props.scoreRecord.comment : undefined}
                                fullWidth
                                multiline
                                minRows={3}
                            />
                        </Grid>
                    ) : null}
                    {props.currentSelectionStatus + 1 === props.contestData.maxSelectionNumber ? (
                        <Grid item xs={12}>
                            <RadioButtonGroupInput
                                label="賞"
                                source={`prize${props.record.id}`}
                                choices={[
                                    { id: Consts.PRIZE.lost, name: '賞なし' },
                                    { id: Consts.PRIZE.bronze, name: '敢闘賞' },
                                    { id: Consts.PRIZE.silver, name: '優秀賞' },
                                    { id: Consts.PRIZE.gold, name: '大賞' }
                                ]}
                                defaultValue={props.record.prize ? props.record.prize : Consts.PRIZE.lost}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <MuiButton
                            disabled={isLoading || isUseCreateLoading || isUseUpdateLoading}
                            variant="contained"
                            size="large"
                            onClick={() => handleClick(props.record.id)}
                        >
                            保存する
                        </MuiButton>
                        <Confirm
                            isOpen={open}
                            loading={isLoading || isUseCreateLoading || isUseUpdateLoading}
                            title="採点の保存"
                            content="採点を保存しました"
                            onConfirm={handleConfirm}
                            onClose={handleDialogClose}
                            cancel="確認"
                            CancelIcon={CheckCircleIcon}
                            sx={{
                                '& .RaConfirm-confirmPrimary': {
                                    display: 'none'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const ImageSlide = (props: any) => {
    return (
        <Carousel transitionTime={300}>
            {props.record.files.map((file: any, index: number) => {
                return (
                    <div key={index}>
                        <img src={file.url} />
                    </div>
                );
            })}
        </Carousel>
    );
};
