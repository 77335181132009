import { stringify } from 'query-string';
import { fetchUtils, DataProvider } from 'ra-core';
import axios from './axios/axios';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 */
export default (apiUrl: string): DataProvider => ({
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
            admin: 1 // frontend-adminからのリクエストであることをパラメータに追加
        };
        const url = `/${resource}?${stringify(query)}`;

        return axios.get(url).then(({ headers, data }) => {
            if (!headers['x-total-count']) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: data,
                total: parseInt(headers['x-total-count'].split('/').pop()!, 10)
            };
        });
    },

    getOne: (resource, params) =>
        axios.get(`/${resource}/${params.id}?admin=1`).then(({ data }) => ({
            data: data
        })),

    getMany: (resource, params) => {
        const query = {
            id: params.ids
        };
        const url = `/${resource}?${stringify(query)}`;
        return axios.get(url).then(({ data }) => ({ data: data }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage
        };
        const url = `/${resource}?${stringify(query)}`;

        return axios.get(url).then(({ headers, data }) => {
            if (!headers['x-total-count']) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: data,
                total: parseInt(headers['x-total-count'].split('/').pop()!, 10)
            };
        });
    },

    update: (resource, params) =>
        axios.patch(`/${resource}/${params.id}`, params.data).then(({ data }) => ({ data: data })),

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(params.ids.map((id) => axios.patch(`/${resource}/${id}`, params.data))).then((responses) => ({
            data: responses.map(({ data }) => data.id)
        })),

    create: (resource, params) =>
        axios.post(`/${resource}`, params.data).then(({ data }) => ({
            data: { ...params.data, id: data.id }
        })),

    delete: (resource, params) => axios.delete(`/${resource}/${params.id}`).then(({ data }) => ({ data: data })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(params.ids.map((id) => axios.delete(`${apiUrl}/${resource}/${id}`))).then((responses) => ({
            data: responses.map(({ data }) => data.id)
        }))
});
