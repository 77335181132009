import * as React from 'react';
import { SVGProps } from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type BreadcrumbProps = {
    url: string | null;
    text: string;
};

type BreadcrumbsProps<T> = {
    breadcrumbs: T[];
};

const CustomBreadcrumbs: React.VFC<BreadcrumbsProps<BreadcrumbProps>> = ({
    breadcrumbs
}: BreadcrumbsProps<BreadcrumbProps>) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: '10px' }}>
            {breadcrumbs.map((item: BreadcrumbProps, index: number) => {
                return index == breadcrumbs.length - 1 ? (
                    <Typography key={index} color="text.primary" fontSize="small">
                        {item.text}
                    </Typography>
                ) : (
                    <Link
                        component={RouterLink}
                        to={item.url ? item.url : ''}
                        key={index}
                        underline="hover"
                        color="inherit"
                        fontSize="small"
                    >
                        {item.text}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default CustomBreadcrumbs;
