import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
    Box,
    Card,
    Chip,
    Grid,
    InputAdornment,
    Typography,
    useMediaQuery,
    Button as MuiButton,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    useTheme
} from '@mui/material';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Show,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    ImageField,
    FunctionField,
    useRecordContext,
    useGetOne,
    Loading,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    NumberField,
    TopToolbar,
    FilterButton,
    CreateButton,
    Button,
    ExportButton,
    Form,
    SaveButton,
    CreateBase,
    useEditController,
    useGetIdentity,
    useListContext,
    NullableBooleanInput,
    useGetList,
    Confirm,
    BulkActionsToolbar,
    RecordContextProvider,
    DatagridBody,
    NonEmptyReferenceField,
    Pagination,
    downloadCSV
} from 'react-admin';
import { Link, useSearchParams } from 'react-router-dom';
import { Consts } from '../consts/Const';
import { formatDate, numberToAlphabet } from '../utils/util';
import IconEvent from '@mui/icons-material/Event';
import AddIcon from '@mui/icons-material/Add';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentFilter from '@mui/icons-material/FilterList';
import { stringify } from 'query-string';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Error from '../Error';
import CustomBreadcrumbs from '../layout/Breadcrumbs';
import { ScoreForm } from './ScoreForm';
import SelectionDialog from './SelectionDialog';
import CircularProgress from '@mui/material/CircularProgress';
import { ContactlessOutlined } from '@mui/icons-material';
import BulkSaveScoringButton from './BulkSaveScoringButton';
import { normalize } from 'path';
import ForceSelectionDialog from './ForceSelectionDialog';
import BulkPostFormDialog from './BulkPostFormDialog';
import BulkScoreFormDialog from './BulkScoreFormDialog';
import { postsExporter } from './exporters/postsExporter';
import { printExporter } from './exporters/printExporter';
import { scoresExporter } from './exporters/scoresExporter';
import { CustomTheme } from '../layout/themes';
import { mallSelectionsExporter } from './exporters/mallSelectionsExpoter';

const postFilters = (isScoring: boolean, isBulkAction?: boolean) => [
    <TextInput source="q" label="Search" alwaysOn disabled={isBulkAction} key="q" />,
    <TextInput source="id" label="投稿Id" defaultValue="" disabled={isBulkAction} alwaysOn key="id" />,
    <SelectInput
        source="isScored"
        label="採点状況"
        emptyText="全て"
        emptyValue={''}
        choices={[
            { id: 0, name: '未採点' },
            { id: 1, name: '採点済' }
        ]}
        alwaysOn={isScoring}
        disabled={isBulkAction}
        key="isScored"
    />
];

const ListActions = ({
    contestData,
    contestId,
    currentSelectionStatus,
    isScoringPermitted,
    currentUser,
    isScoring,
    isBulkAction
}: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, setPerPage } = useListContext();

    const [open, setOpen] = React.useState(false);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        hideFilter('isScoring');
        setOpen(false);
    };

    const {
        data: currentMallSelections,
        isLoading: isCurrentMallSelectionsLoading,
        error: currentMallSelectionsError
    } = useGetList('mallSelections', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'DESC' },
        filter: { contestId: contestData.id, mallId: currentUser.mallId }
    });
    const {
        data: mallSelectionsData,
        isLoading: isMallSelectionsLoading,
        error: mallSelectionsError
    } = useGetList('mallSelections', {
        // [TODO] API側で解決したい
        pagination: { page: 1, perPage: 99999 },
        sort: { field: 'id', order: 'DESC' },
        filter:
            currentUser.authority === Consts.AUTORITY.mall
                ? { contestId: contestData.id, mallId: currentUser.mallId }
                : { contestId: contestData.id }
    });
    const {
        data: jobTypesData,
        isLoading: isJobTypesLoading,
        error: jobTypesError
    } = useGetList('jobTypes', {
        // [TODO] API側で解決したい
        pagination: { page: 1, perPage: 99999 },
        sort: { field: 'id', order: 'ASC' }
    });
    const {
        data: tagsData,
        isLoading: isTagsLoading,
        error: tagsError
    } = useGetList('tags', {
        // [TODO] API側で解決したい
        pagination: { page: 1, perPage: 99999 },
        sort: { field: 'id', order: 'ASC' }
    });

    if (isCurrentMallSelectionsLoading || isMallSelectionsLoading || isJobTypesLoading || isTagsLoading)
        return <CircularProgress />;
    if (currentMallSelectionsError || mallSelectionsError || jobTypesError || tagsError) return <Error />;

    // 権限別調整
    let isScoringButtonPermitted;
    let isForceSelectionButtonPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            isForceSelectionButtonPermitted = currentSelectionStatus == Consts.SELECTION_STATUS.firstSelection;
            isScoringButtonPermitted = currentSelectionStatus >= Consts.SELECTION_STATUS.secondSelection;
            break;
        case Consts.AUTORITY.mall:
            isForceSelectionButtonPermitted = false;
            isScoringButtonPermitted =
                currentMallSelections && currentMallSelections.length > 0 && currentMallSelections[0].isSelected == 0;
            break;
        case Consts.AUTORITY.maker:
            isForceSelectionButtonPermitted = false;
            isScoringButtonPermitted = currentSelectionStatus >= Consts.SELECTION_STATUS.secondSelection;
            break;
        default:
            isForceSelectionButtonPermitted = currentSelectionStatus == Consts.SELECTION_STATUS.firstSelection;
            isScoringButtonPermitted = currentSelectionStatus >= Consts.SELECTION_STATUS.secondSelection;
            break;
    }
    const isCreateButtonPermitted =
        new Date(contestData.applicationEnd) > new Date() &&
        contestData.selectionStatus == Consts.SELECTION_STATUS.firstSelection;

    return (
        <TopToolbar>
            <FilterButton />
            {!isScoring ? (
                <ExportButton
                    disabled={false} // 現状投稿が0件でもできるように
                    label="一括投稿用エクスポート"
                    maxResults={9999}
                    exporter={(data) => postsExporter(data, contestData, jobTypesData, tagsData, mallSelectionsData)}
                />
            ) : null}
            {isCreateButtonPermitted ? <BulkPostFormDialog contestData={contestData} /> : null}
            <ExportButton
                maxResults={9999}
                label="印刷用エクスポート"
                exporter={(data) => printExporter(data, contestData, jobTypesData, currentSelectionStatus)}
            />
            {isScoringPermitted && isForceSelectionButtonPermitted && !isScoring ? (
                !isCurrentMallSelectionsLoading ? (
                    <ExportButton
                        maxResults={9999}
                        label="１次審査状況確認"
                        exporter={() => mallSelectionsExporter(mallSelectionsData, contestData)}
                        disabled={false}
                    />
                ) : (
                    <CircularProgress />
                )
            ) : null}
            {isScoringPermitted && isScoringButtonPermitted && isScoring ? (
                <ExportButton
                    maxResults={9999}
                    label="一括採点エクスポート"
                    exporter={(data) => scoresExporter(data, contestData)}
                />
            ) : null}
            {isScoringPermitted && isScoringButtonPermitted && isScoring ? (
                <BulkScoreFormDialog contestData={contestData} currentUser={currentUser} />
            ) : null}
            {isCreateButtonPermitted ? (
                <Button
                    component={Link}
                    to={`/posts/create?contestId=${contestData.id}&areaId=${contestData.areaId}&selectionStatus=${currentSelectionStatus}`}
                    label="新規投稿"
                >
                    <AddIcon />
                </Button>
            ) : null}
            {isScoringPermitted && isScoringButtonPermitted && !isScoring ? (
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                        setFilters({ isScoring: 1, ...filterValues }, displayedFilters, false);
                        setPerPage(5);
                    }}
                    label="採点する"
                >
                    <RateReviewIcon />
                </Button>
            ) : null}
            {isScoringPermitted && isScoringButtonPermitted && !isScoring ? (
                !isCurrentMallSelectionsLoading ? (
                    <SelectionDialog
                        contestData={contestData}
                        currentUser={currentUser}
                        currentMallSelections={currentMallSelections}
                    />
                ) : (
                    <CircularProgress />
                )
            ) : null}
            {isScoringPermitted && isForceSelectionButtonPermitted && !isScoring ? (
                !isCurrentMallSelectionsLoading ? (
                    <ForceSelectionDialog
                        contestData={contestData}
                        currentUser={currentUser}
                        currentMallSelections={currentMallSelections}
                    />
                ) : (
                    <CircularProgress />
                )
            ) : null}
            {isScoring ? (
                <>
                    <Button variant="outlined" size="large" onClick={handleClick} label="投稿一覧へ戻る">
                        <IconEvent />
                    </Button>
                    <Confirm
                        isOpen={open}
                        title="確認"
                        content={
                            <>
                                採点を保存完了していないものは、入力した採点が消えてしまいます。
                                <br />
                                本当に投稿一覧へ戻ってよろしいですか。
                            </>
                        }
                        onConfirm={handleConfirm}
                        onClose={handleDialogClose}
                    />
                </>
            ) : null}
        </TopToolbar>
    );
};

const PostBulkActionButtons = ({
    currentSelectionStatus,
    currentUser,
    setIsBulkAction,
    contestData
}: {
    currentSelectionStatus: number;
    currentUser: any;
    setIsBulkAction: any;
    contestData: any;
}) => (
    <>
        <BulkSaveScoringButton
            contestData={contestData}
            currentSelectionStatus={currentSelectionStatus}
            currentUser={currentUser}
            setIsBulkAction={setIsBulkAction}
        />
        {/* default bulk delete action */}
        {/* <BulkDeleteButton /> */}
    </>
);

const CustomDatagridRow = ({ record, id, onToggleItem, children, selected, selectable }: any) => (
    <RecordContextProvider value={record}>
        <TableRow>
            {/* first column: selection checkbox */}
            <TableCell padding="none">
                <Checkbox
                    // disabled={selectable}
                    checked={selected}
                    onClick={(event) => onToggleItem(id, event)}
                />
            </TableCell>
            {/* data columns based on children */}
            {React.Children.map(children, (child, index) =>
                index == 0 ? (
                    <TableCell key={`${id}-${child.props.source}`} colSpan={children.length}>
                        {child}
                    </TableCell>
                ) : null
            )}
        </TableRow>
    </RecordContextProvider>
);

const CustomDatagridBody = (props: any) => <DatagridBody {...props} row={<CustomDatagridRow />} />;
const PostPagination = () => <Pagination rowsPerPageOptions={[5, 25, 50, 100]} />;

export const PostList = () => {
    const { identity: currentUser, isLoading: isIdentityLoading } = useGetIdentity();
    const [isBulkAction, setIsBulkAction] = React.useState(false);
    const [searchParams] = useSearchParams();
    const theme: CustomTheme = useTheme();
    let errors;

    let contestId = 1;
    let currentSelectionStatus = 0;
    let isScoring = false;
    if (searchParams.get('filter') !== null) {
        contestId = JSON.parse(searchParams.get('filter')!).contestId;
        currentSelectionStatus = JSON.parse(searchParams.get('filter')!).selectionStatus_gte;
        isScoring = JSON.parse(searchParams.get('filter')!).isScoring == '1';
    }
    const { data: contestData, isLoading, error } = useGetOne('contests', { id: contestId });

    if (searchParams.get('filter') == null) {
        return <Error type="404" />;
    }
    if (isLoading || isIdentityLoading) {
        return <Loading />;
    }
    if (error || errors) {
        return <Error />;
    }

    const targetContestStatus: number = contestData.selectionStatus;
    const targetContestMaxStatus: number = contestData.maxSelectionNumber;
    const scoringItems: any[] = contestData.scoringItems;
    const questions: any[] = contestData.questions;

    // 権限別調整
    let filter = {};
    let isScoringPermitted;
    let isNotPagePermitted;
    switch (currentUser?.authority) {
        case Consts.AUTORITY.area:
            filter = {};
            isScoringPermitted =
                new Date(contestData.applicationEnd) <= new Date() &&
                targetContestStatus == currentSelectionStatus &&
                contestData.areaId == currentUser?.areaId;
            break;
        case Consts.AUTORITY.mall:
            filter = {
                mallId: currentUser?.mallId
            };
            isNotPagePermitted =
                currentSelectionStatus !== Consts.SELECTION_STATUS.firstSelection ||
                (contestData.mallId !== 0 && contestData.mallId !== currentUser?.mallId);
            isScoringPermitted =
                new Date(contestData.applicationEnd) <= new Date() &&
                targetContestStatus == Consts.SELECTION_STATUS.firstSelection &&
                contestData.areaId == currentUser?.areaId;
            break;
        case Consts.AUTORITY.maker:
            filter = {
                areaId: currentUser?.areaId
            };
            isNotPagePermitted = currentSelectionStatus == Consts.SELECTION_STATUS.firstSelection;
            isScoringPermitted =
                currentSelectionStatus >= Consts.SELECTION_STATUS.secondSelection &&
                targetContestStatus == currentSelectionStatus &&
                contestData.areaId == currentUser?.areaId;
            break;
        default:
            isScoringPermitted =
                new Date(contestData.applicationEnd) <= new Date() && targetContestStatus == currentSelectionStatus;
            break;
    }

    if (isNotPagePermitted || (!isScoringPermitted && isScoring)) {
        return <Error type="permission" />;
    }

    const onSubmit = (data: any) => {
        console.log({ data });
    };

    return (
        <Box
            sx={{
                paddingBottom: '70px'
            }}
        >
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/contests', text: 'コンテスト一覧' },
                    { url: null, text: `${contestData.title}：${currentSelectionStatus + 1}次審査投稿一覧` }
                ]}
            />
            <List
                perPage={50}
                pagination={<PostPagination />}
                sx={{
                    '& .RaBulkActionsToolbar-toolbar': {
                        height: '70px !important',
                        // top: "-16px !important",
                        transform: 'none !important',
                        bottom: 0,
                        position: 'fixed !important'
                    },
                    '& .RaBulkActionsToolbar-topToolbar': {
                        padding: '0',
                        backgroundColor: 'transparent !important'
                    },
                    '& .RaBulkActionsToolbar-collapsed': {
                        height: '0px !important'
                    }
                }}
                filters={postFilters(isScoring, isBulkAction)}
                actions={
                    <ListActions
                        contestData={contestData}
                        currentSelectionStatus={currentSelectionStatus}
                        contestId={contestId}
                        isScoringPermitted={isScoringPermitted}
                        currentUser={currentUser}
                        isScoring={isScoring}
                        isBulkAction={isBulkAction}
                    />
                }
                filter={filter}
                sort={{ field: 'id', order: 'DESC' }}
            >
                {!isScoring ? (
                    <Datagrid bulkActionButtons={false}>
                        <TextField label="投稿ID" source="id" />
                        {theme.postList?.mallNameColumn?.isDisplay ? (
                            <ReferenceField
                                label="SC"
                                source="mallId"
                                reference="malls"
                                link={false}
                                sortable={currentUser?.mallId ? false : true}
                            >
                                <TextField source="mallName" />
                            </ReferenceField>
                        ) : null}
                        {theme.postList?.firstAnswerColumn?.isDisplay ? (
                            <TextField label={theme.postList?.firstAnswerColumn?.label} source="answers[0].answer" />
                        ) : null}
                        <TextField label="店名" source="tenantName" />
                        <ReferenceField label="業種" source="jobTypeId" reference="jobTypes" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <NumberField label="いいね数" source="likes" />
                        {currentSelectionStatus >= 0 ? <NumberField label="1次採点" source="averageScore1" /> : null}
                        {currentSelectionStatus >= 1 ? <NumberField label="2次採点" source="averageScore2" /> : null}
                        {currentSelectionStatus >= 2 ? <NumberField label="3次採点" source="averageScore3" /> : null}
                        {currentSelectionStatus + 1 == contestData.maxSelectionNumber ? (
                            <FunctionField
                                label="賞"
                                sortBy={`prize,averageScore${contestData.maxSelectionNumber}`}
                                render={(record: any) => {
                                    return record.prize == null ? '-' : Consts.prize[record.prize];
                                }}
                            />
                        ) : null}
                        <FunctionField
                            label="審査状況"
                            sortBy="prize,selectionStatus"
                            render={(record: any) => {
                                let statusText = Consts.selectionStatus[record.selectionStatus];
                                let suffix = '中';
                                if (
                                    new Date(contestData.applicationEnd) >= new Date() &&
                                    targetContestStatus == Consts.SELECTION_STATUS.firstSelection &&
                                    record.selectionStatus == Consts.SELECTION_STATUS.firstSelection
                                ) {
                                    suffix = '開始待ち';
                                }
                                if (record.selectionStatus < targetContestStatus || record.prize == Consts.PRIZE.lost) {
                                    suffix = '落ち';
                                }
                                if (targetContestMaxStatus == record.selectionStatus) {
                                    statusText =
                                        record.prize == Consts.PRIZE.lost
                                            ? Consts.selectionStatus[targetContestMaxStatus - 1] +
                                              Consts.prize[record.prize]
                                            : `確定（${Consts.prize[record.prize]}）`;
                                    suffix = '';
                                }
                                return statusText + suffix;
                            }}
                        />
                        <FunctionField
                            label="投稿日"
                            sortBy="createdAt"
                            render={(record: any) => `${formatDate(new Date(record.createdAt), Consts.dateFormat)}`}
                        />
                        <FunctionField
                            label=""
                            render={(record: any) => (
                                <MuiButton
                                    variant="text"
                                    component={Link}
                                    size="small"
                                    startIcon={<VisibilityIcon />}
                                    to={`/posts/${record.id}/show?selectionStatus=${currentSelectionStatus}`}
                                >
                                    表示
                                </MuiButton>
                            )}
                        />
                    </Datagrid>
                ) : (
                    <Form warnWhenUnsavedChanges onSubmit={onSubmit}>
                        <Datagrid
                            bulkActionButtons={
                                <PostBulkActionButtons
                                    contestData={contestData}
                                    currentSelectionStatus={currentSelectionStatus}
                                    currentUser={currentUser}
                                    setIsBulkAction={setIsBulkAction}
                                />
                            }
                            // header={<CustomDatagridHeader />}
                            body={<CustomDatagridBody />}
                        >
                            <FunctionField
                                label="投稿id"
                                sortBy="id"
                                render={(record: any) => (
                                    <ScoreForm
                                        contestData={contestData}
                                        record={record}
                                        scoringItems={scoringItems}
                                        questions={questions}
                                        currentSelectionStatus={currentSelectionStatus}
                                        currentUser={currentUser}
                                    />
                                )}
                            />
                            <FunctionField
                                label="点数"
                                sortBy={`averageScore${currentSelectionStatus + 1}`}
                                render={(record: any) => <></>}
                            />
                            <FunctionField label="日付" sortBy={`updatedAt`} render={(record: any) => <></>} />
                        </Datagrid>
                    </Form>
                )}
            </List>
        </Box>
    );
};
