import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {
    useTranslate,
    useLocaleState,
    useTheme,
    Title,
    Form,
    PasswordInput,
    Loading,
    useGetIdentity
} from 'react-admin';

import { darkTheme, lightTheme } from '../layout/themes';
import ChangePasswordFormDialog from './ChangePasswordFormDialog';
import { Consts } from '../consts/Const';

const Configuration = () => {
    const { identity: currentUser, isLoading: isIdentityLoading } = useGetIdentity();
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    const [theme, setTheme] = useTheme();

    if (!currentUser || isIdentityLoading) return <Loading />;

    return (
        <Box
            sx={{
                padding: {
                    sm: '30px 0'
                }
            }}
        >
            <Card>
                <Title title={translate('pos.configuration')} />
                {/* 権限別調整 */}
                {currentUser.authority == Consts.AUTORITY.mall ? (
                    <CardContent>
                        <Box sx={{ width: '10em', display: 'inline-block' }}>{'パスワード変更'}</Box>
                        <ChangePasswordFormDialog currentUser={currentUser} />
                    </CardContent>
                ) : null}
                <CardContent>
                    <Box sx={{ width: '10em', display: 'inline-block' }}>{translate('pos.theme.name')}</Box>
                    <Button
                        variant="contained"
                        sx={{ margin: '1em' }}
                        color={theme?.palette?.mode === 'light' ? 'primary' : 'secondary'}
                        onClick={() => setTheme(lightTheme)}
                    >
                        {translate('pos.theme.light')}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ margin: '1em' }}
                        color={theme?.palette?.mode === 'dark' ? 'primary' : 'secondary'}
                        onClick={() => setTheme(darkTheme)}
                    >
                        {translate('pos.theme.dark')}
                    </Button>
                </CardContent>
                {/* <CardContent>
                    <Box sx={{ width: '10em', display: 'inline-block' }}>
                        {translate('pos.language')}
                    </Box>
                    <Button
                        variant="contained"
                        sx={{ margin: '1em' }}
                        color={locale === 'ja' ? 'primary' : 'secondary'}
                        onClick={() => setLocale('ja')}
                    >
                        日本語
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ margin: '1em' }}
                        color={locale === 'en' ? 'primary' : 'secondary'}
                        onClick={() => setLocale('en')}
                    >
                        English
                    </Button>
                </CardContent> */}
            </Card>
        </Box>
    );
};

export default Configuration;
