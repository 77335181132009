import * as React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useTranslate, FieldProps, useRecordContext } from 'react-admin';
import { stringify } from 'query-string';
import UnCheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckedIcon from '@mui/icons-material/CheckCircle';

import posts from '../posts';
import { Category } from '../types';

const LinkToRelatedPosts = (props: any) => {
    const record = useRecordContext();
    const { selectionStatus } = props;
    return record ? (
        selectionStatus <= record.selectionStatus ? (
            <Button
                size="small"
                color="primary"
                component={Link}
                to={{
                    pathname: '/posts',
                    search: stringify({
                        filter: JSON.stringify({
                            contestId: record.id,
                            selectionStatus_gte: selectionStatus
                        })
                    })
                }}
                sx={{ display: 'inline-flex', alignItems: 'center' }}
            >
                {selectionStatus < record.selectionStatus ? (
                    <CheckedIcon />
                ) : selectionStatus !== record.maxSelectionNumber ? (
                    <UnCheckedIcon />
                ) : null}
            </Button>
        ) : selectionStatus <= record.maxSelectionNumber - 1 ? (
            <Button size="small" color="secondary">
                <UnCheckedIcon />
            </Button>
        ) : null
    ) : null;
};

export default LinkToRelatedPosts;
