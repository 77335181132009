import LocalMall from '@mui/icons-material/LocalMall';

import { MallCreate, MallList, MallEdit } from './malls';

export default {
    list: MallList,
    edit: MallEdit,
    icon: LocalMall,
    create: MallCreate
};
