import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import {
    Box,
    Button,
    Typography,
    useMediaQuery,
    TextField as MuiTextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Link
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    DateField,
    useRecordSelection,
    useGetMany,
    useRecordContext,
    ArrayField,
    RecordContextProvider,
    useGetOne,
    useGetManyReference,
    Loading,
    BooleanInput,
    UrlField,
    AutocompleteInput,
    useGetList
} from 'react-admin';
import LinkToRelatedPosts from './LinkToRelatedPosts';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, ConstructionOutlined, OpenInNewOffOutlined } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { CurrentUser } from '../types';

const ContestTitle = ({ record }: any) => {
    return <span>Contest {record ? `"${record.title}"` : ''}</span>;
};

type FormData = {
    title: string;
    subtitle: string;
    description: string;
    areaId: number;
    imageUrl: string;
    publishStart: Date;
    publishEnd: Date;
    applicationStart: Date;
    applicationEnd: Date;
    questions: [
        {
            no: number;
            text: string;
            type: number;
        }
    ];
};

export const TabBasic = ({ isShow, currentUser }: any) => {
    const record = useRecordContext();

    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 50px 0',
                    sm: '0'
                }
            }}
        >
            <Typography variant="h4" gutterBottom>
                コンテスト設定
            </Typography>
            <TextInput label="コンテストタイトル" source="title" isRequired fullWidth disabled={isShow} />
            <TextInput label="コンテストサブタイトル" source="subtitle" isRequired fullWidth disabled={isShow} />
            <SelectInput
                label={'コンテストタイプ'}
                source={`contestType`}
                emptyValue={null}
                choices={[
                    { id: 0, name: '画像' },
                    { id: 1, name: '動画' }
                ]}
                fullWidth
                disabled={isShow}
                isRequired
            />
            <SelectInput
                label={'最大審査次数'}
                source={'maxSelectionNumber'}
                emptyValue={null}
                choices={[
                    { id: 2, name: '2次審査' },
                    { id: 3, name: '3次審査' }
                ]}
                fullWidth
                disabled={isShow}
                isRequired
            />
            <FormDataConsumer>
                {({
                    formData, // The whole form data
                    ...rest
                }) =>
                    formData && 3 <= formData.maxSelectionNumber ? (
                        <NumberInput
                            label="2次審査の選出数"
                            source="selectionCapacity"
                            isRequired
                            disabled={isShow}
                            sx={{ display: 'block' }}
                        />
                    ) : null
                }
            </FormDataConsumer>
            {/* <TextInput label="コンテスト説明" source="description" multiline isRequired fullWidth minRows={3} disabled={isShow} /> */}
            <ReferenceInput source="areaId" reference="areas">
                <SelectInput
                    label="開催地区"
                    optionText="areaName"
                    isRequired
                    disabled={isShow || currentUser.areaId || record ? true : false}
                    defaultValue={currentUser.areaId ? currentUser.areaId : undefined}
                />
            </ReferenceInput>
            <FormDataConsumer>
                {({
                    formData, // The whole form data
                    ...rest
                }) =>
                    formData && formData.areaId ? (
                        <ReferenceInput
                            source="mallId"
                            reference="malls"
                            page={1}
                            perPage={99999}
                            filter={{ areaId: formData.areaId }}
                        >
                            {currentUser.authority === Consts.AUTORITY.mall ? (
                                <SelectInput
                                    label="開催SC"
                                    optionText="mallName"
                                    optionValue="id"
                                    isRequired
                                    disabled={true}
                                    defaultValue={currentUser.mallId}
                                    sx={{ display: 'block' }}
                                />
                            ) : (
                                <AutocompleteInput
                                    label="開催SC"
                                    optionText="mallName"
                                    optionValue="id"
                                    helperText={'特定のSC単独開催のコンテストにする場合は指定してください。'}
                                    disabled={isShow || record ? true : false}
                                />
                            )}
                        </ReferenceInput>
                    ) : null
                }
            </FormDataConsumer>
            <ImageInput
                label="コンテスト画像"
                format={formatPreview}
                source="imageUrl"
                accept={Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION}
                isRequired
                fullWidth
                disabled={isShow}
                sx={
                    isShow && {
                        '& .RaFileInputPreview-removeButton': {
                            display: 'none'
                        }
                    }
                }
            >
                <ImageField
                    source="url"
                    title="title"
                    sx={{
                        width: '100%',
                        height: '400px',
                        '& img': {
                            height: 'auto',
                            width: 'auto',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                        }
                    }}
                />
            </ImageInput>
            <Separator />
            {/* <Typography variant="h6">
                公開期間
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <DateTimeInput label="公開開始" source="publishStart" isRequired disabled={isShow} />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateTimeInput label="公開終了" source="publishEnd" isRequired disabled={isShow} />
                </Box>
            </Box> */}
            <Typography variant="h6">投稿期間</Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <DateTimeInput label="投稿開始" source="applicationStart" isRequired disabled={isShow} />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <DateTimeInput label="投稿終了" source="applicationEnd" isRequired disabled={isShow} />
                </Box>
            </Box>
        </Box>
    );
};

const Separator = () => <Box pt="1em" />;

export const TabQuestions = ({ isShow }: any) => {
    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 50px 0',
                    sm: '0'
                }
            }}
        >
            <ArrayInput label={false} source="questions" disabled={isShow} isRequired>
                <SimpleFormIterator
                    getItemLabel={(index) => `設問${index + 1}. `}
                    {...(isShow && { disableAdd: true, disableRemove: true, disableReordering: true })}
                >
                    <TextInput label={'設問文'} source={'text'} fullWidth disabled={isShow} isRequired />
                    <SelectInput
                        label={'タイプ'}
                        source={`type`}
                        choices={[
                            { id: Consts.QUESTION_TYPES.text, name: 'テキスト' },
                            { id: Consts.QUESTION_TYPES.textarea, name: 'テキストエリア' },
                            { id: Consts.QUESTION_TYPES.select, name: 'セレクトボックス' },
                            { id: Consts.QUESTION_TYPES.check, name: 'チェックボックス' },
                            { id: Consts.QUESTION_TYPES.radio, name: 'ラジオ' }
                        ]}
                        disabled={isShow}
                        isRequired
                    />
                    <FormDataConsumer>
                        {({
                            formData, // The whole form data
                            scopedFormData, // The data for this item of the ArrayInput
                            getSource, // A function to get the valid source inside an ArrayInput
                            ...rest
                        }) =>
                            scopedFormData && [2, 3, 4].includes(scopedFormData.type) ? (
                                <ArrayInput label={'選択肢'} source={getSource!('options')}>
                                    <SimpleFormIterator
                                        {...(isShow && {
                                            disableAdd: true,
                                            disableRemove: true,
                                            disableReordering: true
                                        })}
                                    >
                                        <TextInput
                                            label={'選択肢テキスト'}
                                            source="optionText"
                                            disabled={isShow}
                                            isRequired
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            ) : null
                        }
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    );
};

export const TabScoringItems = ({ isShow }: any) => {
    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 50px 0',
                    sm: '0'
                }
            }}
        >
            <ArrayInput label={false} source="scoringItems" disabled={isShow} isRequired>
                <SimpleFormIterator
                    getItemLabel={(index) => `採点項目${index + 1}. `}
                    {...(isShow && { disableAdd: true, disableRemove: true, disableReordering: true })}
                >
                    <TextInput label={'採点項目文'} source={'text'} fullWidth disabled={isShow} isRequired />
                    <TextInput
                        label={'採点項目説明文'}
                        source={'description'}
                        fullWidth
                        multiline
                        minRows={3}
                        disabled={isShow}
                        isRequired
                    />
                    <NumberInput label={'配点'} source={'allocation'} disabled={isShow} isRequired />
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    );
};

const MallSelectionsInputField = ({ isShow }: any) => {
    const record = useRecordContext();

    const [firstSelectionCapacityBasis, setSelectionCapacity] = React.useState<number | null>(15);
    const [firstSelectionCapacityBasisUpper, setSelectionCapacityUpper] = React.useState<number | null>(5);
    const [postIndexIncreaseRatio, setPostIndexIncreaseRatio] = React.useState<number | null>(0.1);
    const [increaseStartRatio, setIncreaseStartRatio] = React.useState<number | null>(0.5);
    const isSelectionByPostIndex = useWatch({ name: 'isSelectionByPostIndex' });

    React.useEffect(() => {
        if (record.firstSelectionCapacityBasis) setSelectionCapacity(record.firstSelectionCapacityBasis);
        if (record.firstSelectionCapacityBasisUpper) setSelectionCapacityUpper(record.firstSelectionCapacityBasisUpper);
        if (record.postIndexIncreaseRatio) setPostIndexIncreaseRatio(record.postIndexIncreaseRatio);
        if (record.increaseStartRatio) setIncreaseStartRatio(record.increaseStartRatio);
    }, [record]);

    if (!record) return <Loading />;

    return (
        <Box>
            <NumberInput
                label="１次審査通過数（**店舗ごとに１枠）"
                source="firstSelectionCapacityBasis"
                onChange={(e) => {
                    setSelectionCapacity(e.target.value);
                }}
                disabled={isShow}
                defaultValue={firstSelectionCapacityBasis}
                sx={{
                    maxWidth: 300,
                    width: '100%'
                }}
                isRequired
            />
            <BooleanInput
                label="投稿指数を考慮して1次審査通過枠を増やすようにする"
                source="isSelectionByPostIndex"
                disabled={isShow}
            />
            {isSelectionByPostIndex ? (
                <>
                    <Box>
                        <NumberInput
                            label="１次審査通過数上限（**店舗ごとに１枠）"
                            source="firstSelectionCapacityBasisUpper"
                            onChange={(e) => {
                                setSelectionCapacityUpper(e.target.value);
                            }}
                            disabled={isShow}
                            defaultValue={firstSelectionCapacityBasisUpper}
                            sx={{
                                maxWidth: 300,
                                width: '100%'
                            }}
                            helperText="投稿指数を考慮する場合の１次審査通過枠の上限を設定します。"
                            isRequired
                        />
                    </Box>
                    <Box>
                        <NumberInput
                            label="増加割合（投稿指数**%毎に1枠増加）"
                            source="postIndexIncreaseRatio"
                            onChange={(e) => {
                                setPostIndexIncreaseRatio(e.target.value);
                            }}
                            disabled={isShow}
                            defaultValue={postIndexIncreaseRatio}
                            sx={{
                                maxWidth: 300,
                                width: '100%'
                            }}
                            helperText="どれほどの割合で、１次審査通過枠を増加させるか設定します。"
                            isRequired
                        />
                    </Box>
                    <Box>
                        <NumberInput
                            label="投稿枠の増加開始割合"
                            source="increaseStartRatio"
                            onChange={(e) => {
                                setIncreaseStartRatio(e.target.value);
                            }}
                            disabled={isShow}
                            defaultValue={increaseStartRatio}
                            sx={{
                                maxWidth: 300,
                                width: '100%',
                                marginBottom: '20px'
                            }}
                            helperText={
                                '投稿指数を考慮した１次審査通過枠の増加を開始する割合を設定します。例）店舗数100、0.5に設定した場合：50投稿された段階から投稿枠追加が開始する'
                            }
                            isRequired
                        />
                    </Box>
                </>
            ) : null}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>SC名</TableCell>
                            <TableCell align="center">店舗数</TableCell>
                            {isSelectionByPostIndex ? <TableCell align="center">投稿指数による増加数</TableCell> : null}
                            <TableCell align="center">１次審査通過数</TableCell>
                            {/* <TableCell align="center">１次審査通過数（任意）</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record.mallSelections
                            ? record.mallSelections.map((item: any, index: number) => (
                                  <MallSelectionsTableRow
                                      key={index}
                                      index={index}
                                      firstSelectionCapacityBasis={firstSelectionCapacityBasis}
                                      isSelectionByPostIndex={isSelectionByPostIndex}
                                      firstSelectionCapacityBasisUpper={firstSelectionCapacityBasisUpper}
                                      postIndexIncreaseRatio={postIndexIncreaseRatio}
                                      increaseStartRatio={increaseStartRatio}
                                      data={item}
                                      isShow={isShow}
                                  />
                              ))
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const MallSelectionsTableRow = ({
    index,
    firstSelectionCapacityBasis,
    isSelectionByPostIndex,
    firstSelectionCapacityBasisUpper,
    postIndexIncreaseRatio,
    increaseStartRatio,
    data,
    isShow
}: any) => {
    const [numberOfTenants, setNumberOfTenants] = React.useState<number | null>(data.fixedNumberOfTenants);

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
                <ReferenceField label="SC名" source={`mallSelections.${index}.mallId`} reference="malls" link={false}>
                    <FunctionField
                        render={(record: any) => {
                            return (
                                <Link
                                    component={RouterLink}
                                    to={`/malls/${record.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {record.mallName}
                                </Link>
                            );
                        }}
                    />
                </ReferenceField>
                <NumberInput
                    label=""
                    source={`mallSelections.${index}.mallId`}
                    disabled={isShow}
                    type="hidden"
                    sx={{ visibility: 'hidden', overflow: 'hidden', margin: 0, padding: 0, height: 0, width: 0 }}
                />
            </TableCell>
            <TableCell align="right">
                <ReferenceField label="店舗数" source={`mallSelections.${index}.mallId`} reference="malls" link={false}>
                    <TextField source="numberOfTenants" />
                </ReferenceField>
            </TableCell>
            {isSelectionByPostIndex ? (
                <TableCell align="right">
                    <ReferenceField
                        label="１次審査通過数"
                        source={`mallSelections.${index}.mallId`}
                        reference="malls"
                        link={false}
                    >
                        <FunctionField
                            render={(record: any) =>
                                `上限${Math.ceil(
                                    record.numberOfTenants / firstSelectionCapacityBasisUpper
                                )}枠まで、${Math.ceil(record.numberOfTenants * increaseStartRatio)}投稿から${Math.ceil(
                                    record.numberOfTenants * postIndexIncreaseRatio
                                )}投稿毎に1枠増加`
                            }
                        />
                    </ReferenceField>
                </TableCell>
            ) : null}
            <TableCell align="right">
                <ReferenceField
                    label="１次審査通過数"
                    source={`mallSelections.${index}.mallId`}
                    reference="malls"
                    link={false}
                >
                    <FunctionField
                        render={(record: any) => (
                            <CalculatedFirstSelectionCapacity
                                firstSelectionCapacityBasis={firstSelectionCapacityBasis}
                                numberOfTenants={record.numberOfTenants}
                            />
                        )}
                    />
                </ReferenceField>
                {/* [TODO] 1次審査通過数を任意で設定したい場合（初期段階では設定しない方針）DBではフィールドを持っておく */}
                <NumberInput
                    label=""
                    source={`mallSelections.${index}.fixedFirstSelectionCapacity`}
                    defaultValue={''}
                    disabled={isShow}
                    type="hidden"
                    sx={{ visibility: 'hidden', overflow: 'hidden', margin: 0, padding: 0, height: 0, width: 0 }}
                />
            </TableCell>
        </TableRow>
    );
};

const MallSelectionsRecordProvider = ({ id, resource, children }: any) => {
    const { getValues, setValue, resetField } = useFormContext();
    const areaId = getValues('areaId');
    const mallId = getValues('mallId');
    const [mallSelections, setMallSelections] = useState<any[] | undefined>();

    const filter = mallId
        ? {
              id: mallId
          }
        : {
              areaId: areaId ? areaId : 0
          };

    // fetch all malls related to the current record
    const { data, total, isLoading, error } = useGetList('malls', {
        // [TODO] API側で解決したい
        pagination: { page: 1, perPage: 99999 },
        sort: { field: 'id', order: 'DESC' },
        filter: filter
    });

    // areaIdの変更時に同時にフォームのmallSelectionsの値をリセットする
    React.useEffect(() => {
        resetField('mallSelections');
        if (isLoading || error) return;
        // mallSelectionsのリセット
        setValue(
            'mallSelections',
            data!.map((item) => {
                return {
                    mallId: item['id']
                    // fixedFirstSelectionCapacity: ""
                };
            })
        );
        setMallSelections(
            data!.map((item) => {
                return { mallId: item['id'] };
            })
        );
    }, [data]);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    if (!mallSelections || (mallSelections && mallSelections.length === 0)) {
        return <p>開催地区を選択してください</p>;
    }

    return <RecordContextProvider value={{ mallSelections: mallSelections }}>{children}</RecordContextProvider>;
};

export const TabMallSelections = ({ isShow }: any) => {
    const record = useRecordContext();
    const areaId = useWatch({ name: 'areaId' });
    const mallId = useWatch({ name: 'mallId' });

    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 80px 0',
                    sm: '0'
                }
            }}
        >
            {record && record.areaId == areaId ? (
                <MallSelectionsInputField isShow={isShow} />
            ) : (
                <MallSelectionsRecordProvider>
                    <MallSelectionsInputField isShow={isShow} />
                </MallSelectionsRecordProvider>
            )}
        </Box>
    );
};

export const CustomFormTab = ({
    onSubmit,
    isShow,
    toolbar,
    currentUser,
    isNotEditQuestions,
    isNotEditScoringItems,
    validate,
    record
}: {
    onSubmit?: () => void;
    isShow?: boolean;
    toolbar: JSX.Element;
    currentUser: CurrentUser;
    isNotEditQuestions?: boolean;
    isNotEditScoringItems?: boolean;
    validate?: (values: any) => Promise<any>;
    record?: any;
}) => {
    return (
        <TabbedForm validate={validate} onSubmit={onSubmit} toolbar={toolbar}>
            <FormTab sx={{ maxWidth: 600 }} label="基本設定">
                <TabBasic isShow={isShow} currentUser={currentUser} />
            </FormTab>
            {isNotEditQuestions ? (
                <FormTab sx={{}} label="設問設定">
                    <TabQuestions isShow={true} />
                </FormTab>
            ) : (
                <FormTab sx={{}} label="設問設定">
                    <TabQuestions isShow={isShow} />
                </FormTab>
            )}
            {isNotEditScoringItems ? (
                <FormTab sx={{}} label="採点項目設定">
                    <TabScoringItems isShow={true} />
                </FormTab>
            ) : (
                <FormTab sx={{}} label="採点項目設定">
                    <TabScoringItems isShow={isShow} />
                </FormTab>
            )}
            {/* 権限別調整 */}
            {currentUser.authority !== Consts.AUTORITY.mall ||
            (isShow && currentUser.authority === Consts.AUTORITY.mall && currentUser.mallId === record?.mallId) ||
            (!isShow && currentUser.authority === Consts.AUTORITY.mall) ? (
                <FormTab sx={{}} label="SC審査設定">
                    <TabMallSelections isShow={isShow} />
                </FormTab>
            ) : null}
        </TabbedForm>
    );
};

function formatPreview(value: any) {
    if (!value || typeof value === 'string') {
        // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
        return { url: value };
    } else {
        // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}

const CalculatedFirstSelectionCapacity = (props: any) => {
    const firstSelectionCapacityBasis = parseInt(props.firstSelectionCapacityBasis);
    const numberOfTenants = parseInt(props.numberOfTenants);

    const calculatedValue =
        isNaN(firstSelectionCapacityBasis) || isNaN(numberOfTenants)
            ? 0
            : Math.ceil(numberOfTenants / firstSelectionCapacityBasis);

    return (
        <MuiTextField
            label="1次選考通過数"
            variant="standard"
            value={calculatedValue}
            disabled
            sx={{ marginBottom: '20px' }}
        />
    );
};
