import PostIcon from '@mui/icons-material/Book';

import { PostList } from './PostList';
import { PostShow } from './PostShow';
import { PostCreate } from './PostCreate';
import { PostEdit } from './PostEdit';

export default {
    list: PostList,
    edit: PostEdit,
    icon: PostIcon,
    create: PostCreate,
    show: PostShow
};
