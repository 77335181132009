import _axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { initFirebaseAuth } from '../firebase';
import { User } from 'firebase/auth';

const baseApiUrl = process.env.REACT_APP_API_URL;

/**
 * デフォルト config の設定
 */
const axios = _axios.create({
    baseURL: baseApiUrl,
    // timeout: 3000,
    headers: {
        'Content-Type': 'application/json'
    }
});

/**
 * リクエスト インターセプター
 */
axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (config.headers !== undefined) {
        const currentUser: User = await initFirebaseAuth();
        const idToken = await currentUser.getIdToken();
        // [TODO] 要検討。最終ログインから３時間後はtokenを無効
        // const lastLoginAt = (currentUser.toJSON() as { lastLoginAt: string }).lastLoginAt;
        // if (new Date().getTime() - Number(lastLoginAt) > 1000 * 60 * 60 * 3) {
        //     console.log('auth timeout.');
        //     idToken = '';
        // }

        // ヘッダにアクセストークンを埋める
        config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
});

/**
 * レスポンス インターセプター
 */
axios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        switch (error?.response?.status) {
            case 400:
                console.error('not found');
                break;
            case 401:
                console.error('unauthorized');
                break;
            default:
                break;
        }
        return Promise.reject(error);
    }
);

export default axios;
