import { RaThemeOptions } from 'react-admin';

interface CustomTheme extends RaThemeOptions {
    components: {
        [key: string]: {
            styleOverrides?: any;
            defaultProps?: any;
        };
    };
    postList?: {
        mallNameColumn?: {
            isDisplay?: boolean;
        };
        firstAnswerColumn?: {
            label?: string;
            isDisplay?: boolean;
        };
    };
}

interface ThemeObject {
    [key: string]: CustomTheme;
}

let lightTheme: CustomTheme;
let darkTheme: CustomTheme;

const ThemeObject: ThemeObject = {
    defaultLight: {
        palette: {
            primary: {
                light: '#FF60A3',
                main: '#AF1D7B'
            },
            secondary: {
                light: '#5f5fc4',
                main: '#283593',
                dark: '#001064',
                contrastText: '#fff'
            },
            background: {
                default: '#fcfcfe'
            },
            mode: 'light' as const,
            success: {
                main: 'green',
                light: 'green',
                dark: 'green',
                contrastText: '#fff'
            }
        },
        shape: {
            borderRadius: 10
        },
        sidebar: {
            width: 200
        },
        components: {
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        borderLeft: '3px solid #fff',
                        '&.RaMenuItemLink-active': {
                            borderLeft: '3px solid #AF1D7B'
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    elevation1: {
                        boxShadow: 'none'
                    },
                    root: {
                        border: '1px solid #e0e0e3',
                        backgroundClip: 'padding-box'
                    }
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    // disable ripple for perf reasons
                    disableRipple: true
                },
                styleOverrides: {
                    root: {
                        '&:hover:active::after': {
                            // recreate a static ripple color
                            // use the currentColor to make it work both for outlined and contained buttons
                            // but to dim the background without dimming the text,
                            // put another element on top with a limited opacity
                            content: '""',
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'currentColor',
                            opacity: 0.3,
                            borderRadius: 'inherit'
                        }
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorSecondary: {
                        color: '#808080',
                        backgroundColor: '#fff'
                    }
                }
            },
            MuiLinearProgress: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: '#f5f5f5'
                    },
                    barColorPrimary: {
                        backgroundColor: '#d7d7d7'
                    }
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        '&$disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }
                }
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        border: 'none'
                    }
                }
            }
        },
        postList: {
            mallNameColumn: {
                isDisplay: true
            }
        }
    },
    defaultDark: {
        palette: {
            primary: {
                main: '#90caf9'
            },
            secondary: {
                main: '#FBBA72'
            },
            mode: 'dark' as const // Switching the dark mode on is a single property value change.
        },
        sidebar: {
            width: 200
        },
        components: {
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        borderLeft: '3px solid #000',
                        '&.RaMenuItemLink-active': {
                            borderLeft: '3px solid #90caf9'
                        }
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorSecondary: {
                        color: '#ffffffb3',
                        backgroundColor: '#616161e6'
                    }
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    // disable ripple for perf reasons
                    disableRipple: true
                },
                styleOverrides: {
                    root: {
                        '&:hover:active::after': {
                            // recreate a static ripple color
                            // use the currentColor to make it work both for outlined and contained buttons
                            // but to dim the background without dimming the text,
                            // put another element on top with a limited opacity
                            content: '""',
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'currentColor',
                            opacity: 0.3,
                            borderRadius: 'inherit'
                        }
                    }
                }
            }
        },
        postList: {
            mallNameColumn: {
                isDisplay: true
            }
        }
    },
    disneyLight: {
        palette: {
            primary: {
                light: '#64d4cc',
                main: '#4ABAB2',
                dark: '#33827c'
            },
            secondary: {
                light: '#d4646c',
                main: '#ba4a52',
                dark: '#93081b',
                contrastText: '#fff'
            },
            background: {
                default: '#fcfcfe'
            },
            mode: 'light' as const,
            success: {
                main: 'green',
                light: 'green',
                dark: 'green',
                contrastText: '#fff'
            }
        },
        shape: {
            borderRadius: 10
        },
        sidebar: {
            width: 200
        },
        components: {
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        borderLeft: '3px solid #fff',
                        '&.RaMenuItemLink-active': {
                            borderLeft: '3px solid #4ABAB2'
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    elevation1: {
                        boxShadow: 'none'
                    },
                    root: {
                        border: '1px solid #e0e0e3',
                        backgroundClip: 'padding-box'
                    }
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    // disable ripple for perf reasons
                    disableRipple: true
                },
                styleOverrides: {
                    root: {
                        '&:hover:active::after': {
                            // recreate a static ripple color
                            // use the currentColor to make it work both for outlined and contained buttons
                            // but to dim the background without dimming the text,
                            // put another element on top with a limited opacity
                            content: '""',
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'currentColor',
                            opacity: 0.3,
                            borderRadius: 'inherit'
                        }
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorSecondary: {
                        color: '#808080',
                        backgroundColor: '#fff'
                    }
                }
            },
            MuiLinearProgress: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: '#f5f5f5'
                    },
                    barColorPrimary: {
                        backgroundColor: '#d7d7d7'
                    }
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        '&$disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }
                }
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        border: 'none'
                    }
                }
            }
        },
        postList: {
            mallNameColumn: {
                isDisplay: false
            },
            firstAnswerColumn: {
                label: 'モール名',
                isDisplay: true
            }
        }
    },
    disneyDark: {
        palette: {
            primary: {
                main: '#90caf9'
            },
            secondary: {
                main: '#FBBA72'
            },
            mode: 'dark' as const // Switching the dark mode on is a single property value change.
        },
        sidebar: {
            width: 200
        },
        components: {
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        borderLeft: '3px solid #000',
                        '&.RaMenuItemLink-active': {
                            borderLeft: '3px solid #90caf9'
                        }
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorSecondary: {
                        color: '#ffffffb3',
                        backgroundColor: '#616161e6'
                    }
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    // disable ripple for perf reasons
                    disableRipple: true
                },
                styleOverrides: {
                    root: {
                        '&:hover:active::after': {
                            // recreate a static ripple color
                            // use the currentColor to make it work both for outlined and contained buttons
                            // but to dim the background without dimming the text,
                            // put another element on top with a limited opacity
                            content: '""',
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'currentColor',
                            opacity: 0.3,
                            borderRadius: 'inherit'
                        }
                    }
                }
            }
        },
        postList: {
            mallNameColumn: {
                isDisplay: false
            },
            firstAnswerColumn: {
                label: 'モール名',
                isDisplay: true
            }
        }
    }
};

if (process.env.REACT_APP_ROUTER_BASENAME) {
    lightTheme = ThemeObject[process.env.REACT_APP_ROUTER_BASENAME + 'Light'] || ThemeObject.defaultLight;
    darkTheme = ThemeObject[process.env.REACT_APP_ROUTER_BASENAME + 'Dark'] || ThemeObject.defaultDark;
} else {
    lightTheme = ThemeObject.defaultLight;
    darkTheme = ThemeObject.defaultDark;
}

export { lightTheme, darkTheme };
export type { CustomTheme };
