import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { calculateFirstSelectionCapacity, formatDate } from '../../utils/util';
import axios from '../../axios/axios';

type MallSelectionsForExporter = {
    SCID: string;
    SC名: string;
    ログインID: string;
    一次審査通過数: string;
    応募数: string;
    審査状況: string;
};

// 一次審査状況確認エクスポート
export const mallSelectionsExporter = async (mallSelectionsData: any, contestData: any) => {
    for (let index = 0; index < mallSelectionsData.length; index++) {
        let item = mallSelectionsData[index];

        // 投稿数を取得
        const postsCount: number = await axios
            .get(`/posts?isOnlyCount=1&contestId=${contestData.id}&mallId=${item.mallId}`)
            .then((respose) => {
                return respose.data.postsCount || respose.data.postsCount === 0 ? respose.data.postsCount : 0;
            })
            .catch((error) => {
                console.log(error.response.data.message);
                return 0;
            });

        // １次審査通過数を取得
        const selectionCapacity = contestData.isSelectionByPostIndex
            ? calculateFirstSelectionCapacity(
                  contestData.firstSelectionCapacityBasis,
                  item.numberOfTenants,
                  postsCount,
                  contestData.firstSelectionCapacityBasisUpper,
                  contestData.postIndexIncreaseRatio,
                  contestData.increaseStartRatio,
                  item.fixedFirstSelectionCapacity
              )
            : item.fixedFirstSelectionCapacity
            ? item.fixedFirstSelectionCapacity
            : Math.ceil(item.numberOfTenants / contestData.firstSelectionCapacityBasis);

        // １次審査通過数を取得（API経由で取得）
        // const selectionCapacity: number = await axios
        //     .post('/excuteSelection', {
        //         contestId: contestData.id,
        //         mallId: item.mallId,
        //         isValidationOnly: true
        //     })
        //     .then((respose) => {
        //         console.log(respose.data.selectionCapacity);
        //         return respose.data.selectionCapacity;
        //     })
        //     .catch((error) => {
        //         console.log(error.response.data.message);
        //         return 0;
        //     });

        // カラム名を一括インポート用に変換（API側と逆）
        Object.keys(mallSelectionsData[index]).map((key) => {
            switch (key) {
                case 'mallId':
                    item['SCID'] = item[key];
                    break;
                case 'mallName':
                    item['SC名'] = item[key];
                    break;
                case 'mall':
                    Object.keys(item[key]).map((mallKey) => {
                        switch (mallKey) {
                            case 'loginId':
                                item['ログインID'] = item[key][mallKey];
                                break;
                            default:
                                delete item[key][mallKey];
                                break;
                        }
                    });
                    break;
                case 'isSelected':
                    item['審査状況'] = item[key] ? '○' : '';
                    break;
                default:
                    break;
            }
            delete item[key];
        });

        item[`一次審査通過数`] = selectionCapacity;
        item[`応募数`] = postsCount;

        // 順番を整理
        // カスタム順序を指定
        const customOrder: (keyof MallSelectionsForExporter)[] = [
            'SCID',
            'SC名',
            'ログインID',
            '一次審査通過数',
            '応募数',
            '審査状況'
        ];

        // カスタム順序に従ってキーを並び替える
        item = Object.assign({}, ...customOrder.map((key) => ({ [key]: item[key] })));

        mallSelectionsData[index] = item;
    }

    // BOM付きで出力
    const BOM = '\uFEFF';
    jsonExport(mallSelectionsData, (err: any, csv: any) => {
        downloadCSV(
            `${BOM}${csv}`,
            `一次審査状況_contestId_${contestData.id}_${formatDate(new Date(), 'yyyyMMddHHmmss')}`
        );
        if (err) {
            console.log('Error trying to export list');
        }
    });
};
