import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import {
    Box,
    Button,
    Typography,
    useMediaQuery,
    TextField as MuiTextField,
    Grid,
    Card,
    InputAdornment
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    AutocompleteInput,
    useRecordContext,
    Loading,
    useGetManyReference,
    useGetOne,
    useDataProvider,
    FileInput,
    CheckboxGroupInput,
    RadioButtonGroupInput,
    useGetMany,
    useGetList,
    NumberInput
} from 'react-admin';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, OpenInNewOffOutlined } from '@mui/icons-material';
import Error from '../Error';
import VideoViewer from './VideoViewer';

export const TabBasic = (props?: any) => {
    const record = props.record;
    const contest = props.contest;
    const isShow = props.isShow;
    const isNotInputPermitted = props.isNotInputPermitted;
    const [tagChoices, setTagChoices] = React.useState<any[]>([]);
    const contestTypeText = contest.contestType === Consts.CONTEST_TYPES.images ? '画像' : '動画';
    const [isMaxArrayInput, setIsMaxArrayInput] = React.useState<boolean>(false);

    const {
        data: tagsData,
        total,
        isLoading,
        error
    } = useGetList('tags', {
        pagination: { page: 1, perPage: 99999 },
        sort: { field: 'id', order: 'ASC' }
    });

    const validateMaxItems = (values: any[]) => {
        if (values?.length >= (contest.contestType === Consts.CONTEST_TYPES.images ? 5 : 1)) {
            setIsMaxArrayInput(true);
        } else {
            setIsMaxArrayInput(false);
        }
        return '';
    };

    React.useEffect(() => {
        const initialTagChoices = tagsData ? tagsData.map((tag) => ({ id: tag.id, name: tag.name })) : [];
        setTagChoices(initialTagChoices);
    }, [tagsData]);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    let contestsChoice: any = [];
    const mallsChoice: any = [];

    contestsChoice = [{ id: props.contest.id, name: props.contest.title }];
    props.malls.map((mall: any) => {
        mallsChoice.push({ id: mall.id, name: mall.mallName });
    });

    return (
        <>
            <Typography variant="h4" gutterBottom>
                投稿
            </Typography>
            <AutocompleteInput
                label="対象SC"
                source="mallId"
                choices={mallsChoice}
                fullWidth
                disabled={record == undefined ? isNotInputPermitted : true}
                defaultValue={
                    props.currentUser.mallId
                        ? mallsChoice.find((mallChoice: any) => mallChoice.id == props.currentUser.mallId)
                            ? mallsChoice.find((mallChoice: any) => mallChoice.id == props.currentUser.mallId).id
                            : null
                        : null
                }
            />
            <AutocompleteInput
                label="対象コンテスト"
                source="contestId"
                choices={contestsChoice}
                fullWidth
                disabled={true}
                defaultValue={contestsChoice[0].id}
            />
            <TextInput label="店名" source="tenantName" isRequired fullWidth disabled={isShow} />
            <ReferenceInput source="jobTypeId" reference="jobTypes">
                <SelectInput label="業種" optionText="name" isRequired disabled={isShow} />
            </ReferenceInput>
            <AutocompleteArrayInput
                label="ハッシュタグ"
                onCreate={async (newTagName) => {
                    const newTag = { id: newTagName!.replace(/#|＃/g, ''), name: newTagName!.replace(/#|＃/g, '') };
                    setTagChoices([newTag, ...tagChoices]);
                    return newTag;
                }}
                source="tagIds"
                choices={tagChoices}
                fullWidth
                sx={{
                    alignItems: 'flex-start',
                    '& input': {
                        minHeight: '30px',
                        width: '100% !important'
                    }
                }}
                disabled={isShow}
            />
            <TextInput
                label="編集・削除キー"
                source="editKey"
                helperText="4桁の数字"
                disabled={record ? true : undefined}
            />
            <Separator />
            <Box sx={{ width: '100%' }}>
                <ArrayInput label={false} source="files" disabled={isShow} validate={validateMaxItems}>
                    <SimpleFormIterator
                        getItemLabel={(index) => `${contestTypeText}${index + 1}. `}
                        {...(isShow && { disableAdd: true, disableRemove: true, disableReordering: true })}
                        {...(isMaxArrayInput && { disableAdd: true })}
                    >
                        <ImageInput
                            label={`${contestTypeText}ファイル`}
                            format={formatPreview}
                            source="url"
                            placeholder={`アップロードする${contestTypeText}ファイルをドロップするか、クリックして選択してください。`}
                            accept={
                                contest.contestType === Consts.CONTEST_TYPES.images
                                    ? Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION
                                    : Consts.ACCEPTABLE_VIDEO_FILE_EXTENSION
                            }
                            maxSize={
                                contest.contestType === Consts.CONTEST_TYPES.images
                                    ? Consts.ACCEPTABLE_IMAGE_FILE_SIZE
                                    : Consts.ACCEPTABLE_VIDEO_FILE_SIZE
                            }
                            isRequired
                            fullWidth
                            sx={
                                isShow && {
                                    '& .RaFileInputPreview-removeButton': {
                                        display: 'none'
                                    }
                                }
                            }
                        >
                            <CustomFilePreviewField contest={contest} />
                        </ImageInput>
                        <TextInput
                            label=""
                            source={'fileType'}
                            disabled={isShow}
                            defaultValue={props.contest.contestType == 0 ? 'images' : 'video'}
                            type="hidden"
                            sx={{
                                visibility: 'hidden',
                                overflow: 'hidden',
                                margin: 0,
                                padding: 0,
                                height: 0,
                                width: 0
                            }}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </Box>
        </>
    );
};

const Separator = () => <Box pt="1em" />;

type Question = {
    text: string;
    type: number | undefined;
    options: string[];
};

export const TabQuestions = (props: any) => {
    const isShow = props.isShow;

    return (
        <Box sx={{ width: '100%' }}>
            {props.contest.questions.map((item: any, index: number) => {
                return (
                    <Grid item xs={12} key={index}>
                        <Typography variant="h6">
                            {index + 1}) {item.text}
                        </Typography>
                        {((index: number) => {
                            switch (item.type) {
                                case Consts.QUESTION_TYPES.text:
                                    return (
                                        <TextInput
                                            label="回答"
                                            source={`answers[${index}][answer]`}
                                            fullWidth
                                            disabled={isShow}
                                        />
                                    );
                                case Consts.QUESTION_TYPES.textarea:
                                    return (
                                        <TextInput
                                            label="回答"
                                            source={`answers[${index}][answer]`}
                                            fullWidth
                                            multiline
                                            minRows={3}
                                            disabled={isShow}
                                        />
                                    );
                                case Consts.QUESTION_TYPES.select:
                                    return (
                                        <SelectInput
                                            label="回答"
                                            source={`answers[${index}][answer]`}
                                            fullWidth
                                            choices={item.options.map((option: any, j: number) => {
                                                return { id: j, name: option.optionText };
                                            })}
                                            disabled={isShow}
                                        />
                                    );
                                case Consts.QUESTION_TYPES.check:
                                    return (
                                        <CheckboxGroupInput
                                            label="回答"
                                            source={`answers[${index}][answer]`}
                                            choices={item.options.map((option: any, j: number) => {
                                                return { id: j, name: option.optionText };
                                            })}
                                            disabled={isShow}
                                        />
                                    );
                                case Consts.QUESTION_TYPES.radio:
                                    return (
                                        <RadioButtonGroupInput
                                            label="回答"
                                            source={`answers[${index}][answer]`}
                                            defaultValue={0}
                                            choices={item.options.map((option: any, j: number) => {
                                                return { id: j, name: option.optionText };
                                            })}
                                            disabled={isShow}
                                        />
                                    );
                                default:
                                    break;
                            }
                        })(index)}
                    </Grid>
                );
            })}
        </Box>
    );
};

export const TabScore = (props: { record: any; contest: any }) => {
    const { record, contest } = props;
    const scores = record.scores;
    const scoringItems = contest.scoringItems;

    if (!record || !contest) {
        return <Loading />;
    }

    if (scores.length <= 0) {
        return <p>未採点</p>;
    }

    return (
        <>
            {scores.map((score: any, index: number) => {
                return (
                    <Box
                        key={index}
                        sx={{
                            width: '100%',
                            paddingBottom: 3,
                            marginBottom: 3,
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'dashed',
                            borderBottomColor: 'gray'
                        }}
                    >
                        <Typography variant="h6">{index + 1}次審査</Typography>
                        {scoringItems.map((item: any, index: number) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Typography variant="h6">
                                        {index + 1}) {item.text}
                                    </Typography>
                                    <Typography variant="subtitle2">{item.description}</Typography>
                                    <NumberInput
                                        disabled={true}
                                        label=""
                                        source={`scoreId${score.id}`}
                                        type="hidden"
                                        sx={{
                                            visibility: 'hidden',
                                            overflow: 'hidden',
                                            margin: 0,
                                            padding: 0,
                                            height: 0,
                                            width: 0
                                        }}
                                        defaultValue={score ? score.id : undefined}
                                    />
                                    <TextInput
                                        disabled={true}
                                        label="スコア"
                                        source={`score${score.id}[${index}]`}
                                        helperText={false}
                                        defaultValue={score ? score.score[index] : undefined}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">{`/${item.allocation}`}</InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                        {score.phase - 1 !== Consts.SELECTION_STATUS.firstSelection ? (
                            <Grid item xs={12}>
                                <TextInput
                                    disabled={true}
                                    label="コメント"
                                    source={`comment${score.id}`}
                                    defaultValue={score ? score.comment : undefined}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                />
                            </Grid>
                        ) : null}
                        {score.phase === contest.maxSelectionNumber ? (
                            <Grid item xs={12}>
                                <RadioButtonGroupInput
                                    disabled={true}
                                    label="賞"
                                    source={`prize${score.id}`}
                                    choices={[
                                        { id: Consts.PRIZE.lost, name: '賞なし' },
                                        { id: Consts.PRIZE.bronze, name: '敢闘賞' },
                                        { id: Consts.PRIZE.silver, name: '優秀賞' },
                                        { id: Consts.PRIZE.gold, name: '大賞' }
                                    ]}
                                    defaultValue={score.prize ? score.prize : Consts.PRIZE.lost}
                                />
                            </Grid>
                        ) : null}
                    </Box>
                );
            })}
        </>
    );
};

const CustomFilePreviewField = (props: any) => {
    const parentRecord = useRecordContext();
    const record = useRecordContext(props);
    const imgStyle: any = {
        height: 'auto',
        width: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    };

    if (record.url) {
        return props.contest.contestType === Consts.CONTEST_TYPES.images ? (
            <img style={imgStyle} src={record.url} />
        ) : (
            <Card sx={{ width: 350 }}>
                <VideoViewer videoUrl={record.url.split('?')[0] + '__compressed.mp4?alt=media'} />
            </Card>
        );
    }

    if (!record.rawFile) return null;

    // urlを生成してプレビュー表示できるようにする
    const url = window.URL.createObjectURL(record.rawFile);
    console.log(url);
    return record.rawFile.type.match(/^image.*$/) ? (
        <img style={imgStyle} src={url} />
    ) : (
        <video src={url} controls width="100%" />
    );
};

function formatPreview(value: any) {
    if (!value || typeof value === 'string') {
        // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
        return { url: value };
    } else {
        // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}

//////////////////////////////////////////////////////////////////////
// Get file extension.
// @param url string: The target URL.
//////////////////////////////////////////////////////////////////////
function getExtension(url: any) {
    return url.split(/#|\?/)[0].split('.').pop().trim();
}
