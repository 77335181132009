import { Consts } from '../../consts/Const';
import { getAnswerString } from '../../utils/util';

export const printExporter = (data: any, contestData: any, jobTypesData: any, currentSelectionStatus: number) => {
    let content = '';
    const head = `<title>${contestData.title}</title>`;
    for (let index = 0; index < data.length; index++) {
        const item = data[index];
        let imagesElements = '';
        let answerRows = '';
        let scoresTable = '';
        let scoresRow = '';
        let commentRow = '';

        // 画像の繰り返し
        item.files.map((file: any) => {
            const imageUrl =
                contestData.contestType === Consts.CONTEST_TYPES.images
                    ? file.url
                    : file.url.split('?')[0] + '__thumbnail.jpg?alt=media';

            imagesElements += `<div class="post-image"><img src=${imageUrl} /></div>`;
        });

        // 設問と解答
        contestData.questions.map((question: any, index: number) => {
            let answerText: any = getAnswerString(question, index, item.answers);
            if (Array.isArray(answerText)) answerText = answerText.join('');
            answerRows += `<tr><th>${question.text}</th><td>${answerText}</td></tr>`;
        });

        // 最終審査段階画面での印刷エクスポートのみに表示する採点関係情報
        if (contestData.maxSelectionNumber <= currentSelectionStatus + 1) {
            // 各審査段階でのスコア（合計のみ）
            for (let i = 1; i <= contestData.maxSelectionNumber; i++) {
                const score = item[`averageScore${i}`];
                scoresRow += `<th>${i}次審査</th><td>${score ? score + ' 点' : ''}</td>`;
            }

            // 賞
            const prizeName = Consts.prize[item.prize] || '';
            scoresRow += `<th>賞</th><td>${prizeName}</td>`;

            // 審査員コメント
            const commentText = item.scores[0]?.comment || '';
            commentRow += `<th>審査員コメント</th><td colspan="${
                contestData.maxSelectionNumber * 2 + 1
            }">${commentText}</td>`;

            scoresTable = `
                <table class="scores-table" border="1">
                    <tr>${scoresRow}</tr>
                    <tr>${commentRow}</tr>
                </table>
            `;
        }

        content += `
            <div class="post">
                <table border="1">
                    <tr>
                        <th>コンテスト名</th>
                        <td>${contestData.title}</td>
                        <th>投稿ID</th>
                        <td>${item.id}</td>
                    </tr>
                    <tr>
                        <th>モール名</th>
                        <td>${item.mall.mallName}</td>
                        <th>店舗名</th>
                        <td>${item.tenantName}</td>
                    </tr>
                    <tr>
                        <th>業種</th>
                        <td>${
                            jobTypesData
                                .find((jobTypeData: any) => jobTypeData.id === item.jobTypeId)
                                .name.split('（')[0]
                        }</td>
                        <th>いいね数</th>
                        <td>${item.likes}</td>
                    </tr>
                </table>
                <div class="post-images">
                    ${imagesElements}
                </div>
                <table class="answers-table" border="1">
                    ${answerRows}
                </table>
                ${scoresTable}
            </div>
        `;
    }
    printArea(content, head);
};

function printArea(content: string, head: string) {
    const cmd = `<script>
        var count = 0;
        var img_elements = document.querySelectorAll("img");
        var loading_elements = document.getElementById("loading");
        var content_element = document.getElementById("content");
        console.log(img_elements.length + '枚');

        for(var i=0; i<img_elements.length; i++) {
            // 画像読み込み完了したときの処理
            img_elements[i].addEventListener('load', (e)=> {
                count += 1;
                if (count === img_elements.length) {
                    console.log(img_elements.length + '枚 全画像読み込み完了');
                    content_element.style.display = 'block';
                    loading_elements.parentNode.removeChild(loading_elements);
                    window.print();
                }
            });
        }
    </script>`;

    const sub = window.open();

    sub?.document.write(`
        <html>
            <style type="text/css" media="print">
                /* @page { size: A4 landscape; } */
                @page { size: A4 portrait; }
            </style>
            <style>
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                }
                body {
                    margin: 0;
                    padding: 0;
                }
                #content {
                    display: none;
                }
                .post {
                    page-break-before: always;
                }


                /* テーブル */
                table {
                    width: 100%;
                    table-layout: fixed;
                    border-collapse: collapse;
                    font-size: 12px;
                }
                th, td {
                    padding: 5px;
                }
                th {
                    text-align: left;
                    width: 150px;
                    background: #ddd;
                }
                td {
                    width: 100%;
                }

                .answers-table th {
                    width: 300px;
                }

                .scores-table {
                    margin-top: 5px
                }
                .scores-table th {
                    width: 100px;
                }


                /* 画像 */
                .post-images {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-bottom: 5px;
                }
                .post-image {
                    flex: 0 0 auto;
                    width: calc(33.33% - 4px);
                    height: 200px;
                    border: 1px solid black;
                    margin-top: 5px;
                }
                .post-image:not(:nth-child(3n + 1)) {
                    margin-left: 6px;
                }
                .post-images img {
                    background: white;
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }


                /* ローディング関係 */
                .loading-text {
                    margin: 50px auto;
                    text-align: center;
                    font-size: 30px;
                    font-weight: bold;
                }

                .spinner {
                    width: 62px;
                    height: 62px;
                    margin: 10px auto;
                    border: 10px #ddd solid;
                    border-top: 10px pink solid;
                    border-radius: 50%;
                    animation: sp-anime 1.0s infinite linear;
                }
                @keyframes sp-anime {
                    100% { 
                        transform: rotate(360deg); 
                    }
                }
            </style>
            <head>
                <meta name="viewport" content="width=device-width,initial-scale=1">
                ${head}
            </head>
            <body>
                <div id="loading">
                    <div class="loading-text">印刷の画像を読み込んでいます。少々お待ちください。</div>
                    <div class="spinner"></div>
                </div>
                <div id="content">
                    ${content}
                </div>
                ${cmd}
            </body>
        </html>
    `);
}
