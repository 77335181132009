import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import {
    Box,
    Button,
    Typography,
    useMediaQuery,
    TextField as MuiTextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    DateField,
    useRecordSelection,
    useGetMany,
    useRecordContext,
    ArrayField,
    RecordContextProvider,
    useGetOne,
    useGetManyReference,
    Loading,
    BooleanInput,
    UrlField,
    AutocompleteInput,
    required,
    Toolbar,
    SaveButton
} from 'react-admin';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';

export const TabBasic = ({ isShow, currentUser, isNotEditPermitted }: any) => {
    const record = useRecordContext();
    isShow = isNotEditPermitted;

    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 50px 0',
                    sm: '0'
                }
            }}
        >
            <ReferenceInput source="areaId" reference="areas">
                <SelectInput
                    label="開催地区"
                    optionText="areaName"
                    isRequired
                    disabled={isShow || currentUser.areaId || record ? true : false}
                    defaultValue={currentUser.areaId ? currentUser.areaId : undefined}
                />
            </ReferenceInput>
            <Typography variant="h6">おすすめハッシュタグ設定</Typography>
            {/* <ReferenceInput source='recommendTagIds' reference="tags">
            <AutocompleteArrayInput label="おすすめハッシュタグ" optionText="name" disabled={isShow} />
        </ReferenceInput> */}
            <ArrayInput label={false} source="recommendTagIds" disabled={isShow} isRequired>
                <SimpleFormIterator
                    getItemLabel={(index) => `ハッシュタグ${index + 1}. `}
                    {...(isShow && { disableAdd: true, disableRemove: true, disableReordering: true })}
                >
                    <ReferenceInput source="" reference="tags">
                        <AutocompleteInput label="ハッシュタグ" optionText="name" disabled={isShow} />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            <Typography variant="h6">おすすめ検索ワード設定</Typography>
            <ArrayInput label={false} source="recommendWords" disabled={isShow} isRequired>
                <SimpleFormIterator
                    getItemLabel={(index) => `検索ワード${index + 1}. `}
                    {...(isShow && { disableAdd: true, disableRemove: true, disableReordering: true })}
                >
                    <TextInput label="検索ワード" source={''} disabled={isShow} />
                </SimpleFormIterator>
            </ArrayInput>
            <Box>
                <DateTimeInput label="作成日時" source="createdAt" disabled isRequired />
            </Box>
            <Box>
                <DateTimeInput label="更新日時" source="updatedAt" disabled isRequired />
            </Box>
        </Box>
    );
};

export const TabTabSettings = ({ isShow, currentUser, isNotEditPermitted }: any) => {
    const record = useRecordContext();
    isShow = isNotEditPermitted;

    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 50px 0',
                    sm: '0'
                }
            }}
        >
            <Typography variant="h6">投稿一覧画面タブ設定</Typography>
            <ArrayInput label={false} source="tabSettings" disabled={isShow} isRequired>
                <SimpleFormIterator
                    getItemLabel={(index) => `タブ${index + 1}. `}
                    {...(isShow && { disableAdd: true, disableRemove: true, disableReordering: true })}
                >
                    <TextInput label={'タブの名称'} source={'tabName'} fullWidth disabled={isShow} isRequired />
                    <ReferenceInput source="contestId" reference="contests" page={1} perPage={99999}>
                        <AutocompleteInput label="コンテスト" optionText="title" fullWidth disabled={isShow} />
                    </ReferenceInput>
                    <ReferenceInput source="tagId" reference="tags">
                        <AutocompleteInput label="ハッシュタグ" optionText="name" disabled={isShow} />
                    </ReferenceInput>
                    <ReferenceInput source="jobTypeId" reference="jobTypes">
                        <SelectInput label="職種" optionText="name" disabled={isShow} />
                    </ReferenceInput>
                    <SelectInput
                        label="コンテストの種類"
                        source="contestType"
                        choices={[
                            { id: '0', name: '画像' },
                            { id: '1', name: '動画' }
                        ]}
                        disabled={isShow}
                    />
                    <TextInput label="検索ワード" source="q" />
                    <ReferenceInput source="areaId" reference="areas">
                        <SelectInput label="地区" optionText="areaName" disabled={isShow} />
                    </ReferenceInput>
                    <SelectInput
                        label={'賞あり'}
                        source={`hasPrize`}
                        choices={[
                            { id: 1, name: '賞あり' },
                            { id: 0, name: '賞なし' }
                        ]}
                        disabled={isShow}
                    />
                    {/* 現状実装していないプルダウンの選択肢があるため、以下コメントアウト */}
                    {/* <SelectInput
                    label={"表示順番の基準"}
                    source={`_sort`}
                    choices={[
                        { id: Consts.SORT.prize, name: '賞順' },
                        { id: Consts.SORT.views, name: '閲覧数' },
                        { id: Consts.SORT.likes, name: 'いいね数' },
                        { id: Consts.SORT.comments, name: 'コメント数' },
                    ]} 
                    disabled={isShow}
                />
                <SelectInput
                    label={"表示順番"}
                    source={`_order`}
                    choices={[
                        { id: "DESC", name: '降順' },
                        { id: "ASC", name: '昇順' },
                    ]} 
                    disabled={isShow}
                /> */}
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    );
};

export const CustomForm = ({ onSubmit, isShow, toolbar, currentUser, isNotEditPermitted }: any) => {
    const OnlySaveToolbar = () => (
        <Toolbar>
            <SaveButton label="保存" />
        </Toolbar>
    );

    return (
        <TabbedForm onSubmit={onSubmit} toolbar={<OnlySaveToolbar />}>
            <FormTab sx={{ maxWidth: 600 }} label="基本項目">
                <TabBasic isShow={isShow} currentUser={currentUser} isNotEditPermitted={isNotEditPermitted} />
            </FormTab>
            <FormTab sx={{}} label="投稿一覧画面タブ設定">
                <TabTabSettings isShow={isShow} currentUser={currentUser} isNotEditPermitted={isNotEditPermitted} />
            </FormTab>
        </TabbedForm>
    );
};
