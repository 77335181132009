import * as React from 'react';
import { useTranslate } from 'ra-core';
import { DefaultEditorOptions, useTiptapEditor } from 'ra-input-rich-text';
import { EditorContent, useEditor } from '@tiptap/react';
import { ToggleButton, ToggleButtonProps, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Youtube from '@tiptap/extension-youtube';
import StarterKit from '@tiptap/starter-kit';

export const MyYoutubeButton = (props: Omit<ToggleButtonProps, 'value'>) => {
    const editor = useTiptapEditor();

    const addYoutubeVideo = React.useCallback(() => {
        const url = prompt('YouTubeのURLを入力してください');

        if (url && editor) {
            editor.commands.setYoutubeVideo({
                src: url,
                width: 640,
                height: 480
            });
        }
    }, [editor]);

    return editor ? (
        <ToggleButton
            aria-label={'youtube'}
            title={'youtube'}
            {...props}
            disabled={!editor?.isEditable}
            value="youtube"
            onClick={addYoutubeVideo}
        >
            <YouTubeIcon fontSize="inherit" />
        </ToggleButton>
    ) : null;
};
