import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, Typography, useMediaQuery, TextField as MuiTextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    useRecordContext,
    useDataProvider,
    Loading,
    useGetIdentity,
    useCreate,
    useRedirect,
    useNotify,
    Toolbar,
    SaveButton,
    useRefresh
} from 'react-admin';
import { TabBasic, TabQuestions } from './PostFormComponents';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import CustomBreadcrumbs from '../layout/Breadcrumbs';
import { stringify } from 'query-string';
import Error from '../Error';
import { Consts } from '../consts/Const';
import { uploadImageToFirebaseStorage, uploadVideoToFirebaseStorage } from '../utils/util';

const PostCreateToolbar = (props: any) => {
    const { isSubmitting, onSubmit, ...rest } = props;
    const redirect = useRedirect();
    const notify = useNotify();
    const { getValues, setValue, setError, clearErrors } = useFormContext();

    return (
        <Toolbar>
            <SaveButton
                saving={isSubmitting}
                label="保存する"
                // onClick={async ()=>{
                //     const values = getValues();
                //     const errors = await onSubmit(values);
                //     if (errors) {
                //         Object.keys(errors).map((key)=>{
                //             setError(key, { message: errors[key] })
                //         })
                //     }
                // }}
                // alwaysEnable
            />
        </Toolbar>
    );
};

export const PostCreate = () => {
    const [contest, setContest] = useState<any>();
    const [searchParams] = useSearchParams();
    const currentSelectionStatus = searchParams.get('selectionStatus');
    const { identity: currentUser, isLoading } = useGetIdentity();
    const [create] = useCreate();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const [isSubmitting, setIsSubmitting] = useState(false);

    if (isLoading) return <Loading />;
    if (!currentUser) return <Error />;

    // 権限別調整
    let isNotPagePermitted;
    let isNotInputPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotInputPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = true;
            break;
        default:
            break;
    }
    if (contest && contest.applicationEnd < new Date()) isNotPagePermitted = true;

    if (isNotPagePermitted) return <Error type="permission" />;

    const onSubmit = async (data: any) => {
        setIsSubmitting(true);
        if (!contest) return;

        const tagIds: any[] = [];
        const newTags: any[] = [];

        // newTagsの抽出
        data.tagIds.map((tag: any) => {
            typeof tag == 'string' ? newTags.push(tag) : tagIds.push(tag);
        });
        data.tagIds = tagIds;
        data.newTags = newTags;

        // バリデーションのみ、filesは、バリデーションのため一時的に、rawFileのファイル名を入れておく。
        const validationFiles = data.files.map((file: any) => {
            return { url: file.url.rawFile.name, fileType: file.fileType };
        });
        try {
            await create(
                'posts',
                {
                    data: {
                        isValidationOnly: true,
                        ...data,
                        files: validationFiles.length > 0 ? validationFiles : undefined
                    }
                },
                { returnPromise: true }
            );
        } catch (error: any) {
            setIsSubmitting(false);
            if (error.body && error.body.errors) {
                // The shape of the returned validation errors must match the shape of the form
                console.log(error.body.errors);
                // return error.body.errors;
                return error.body.errors;
            }
        }

        // アップロード処理
        for (let index = 0; index < data.files.length; index++) {
            const file = data.files[index];
            console.log(file);
            try {
                if (file.fileType === 'video') {
                    data.files[index].url = await uploadVideoToFirebaseStorage(
                        file.url.rawFile,
                        contest.areaId,
                        'posts'
                    );
                } else {
                    data.files[index].url = await uploadImageToFirebaseStorage(
                        file.url.rawFile,
                        contest.areaId,
                        'posts'
                    );
                }
            } catch (error: any) {
                setIsSubmitting(false);
                return {
                    files: {
                        [index]: {
                            url: 'ファイルのアップロードに失敗しました。適切なファイルを選択し直してください。'
                        }
                    }
                };
            }
        }

        // formの送信
        try {
            await create('posts', { data: data }, { returnPromise: true });
            notify('resources.posts.notification.created_success', { type: 'success' });
            redirect(
                `/posts?` +
                    stringify({
                        filter: JSON.stringify({
                            contestId: contest.id,
                            selectionStatus_gte: parseInt(currentSelectionStatus ?? '0')
                        })
                    })
            );
            refresh();
        } catch (error: any) {
            setIsSubmitting(false);
            if (error.body.errors) {
                // The shape of the returned validation errors must match the shape of the form
                console.log(error.body.errors);
                // return error.body.errors;
                return error.body.errors;
            }
        }

        setIsSubmitting(false);
    };

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            {contest ? (
                currentSelectionStatus ? (
                    <CustomBreadcrumbs
                        breadcrumbs={[
                            { url: '/contests', text: 'コンテスト一覧' },
                            {
                                url:
                                    `/posts?` +
                                    stringify({
                                        filter: JSON.stringify({
                                            contestId: contest.id,
                                            selectionStatus_gte: parseInt(currentSelectionStatus)
                                        })
                                    }),
                                text: `${contest.title}：${parseInt(currentSelectionStatus) + 1}次審査投稿一覧`
                            },
                            { url: null, text: `新規投稿作成` }
                        ]}
                    />
                ) : (
                    <CustomBreadcrumbs
                        breadcrumbs={[
                            { url: '/contests', text: 'コンテスト一覧' },
                            {
                                url:
                                    `/posts?` +
                                    stringify({
                                        filter: JSON.stringify({
                                            contestId: contest.id,
                                            selectionStatus_gte: 0
                                        })
                                    }),
                                text: `${contest.title}：1次審査投稿一覧`
                            },
                            { url: null, text: `新規投稿作成` }
                        ]}
                    />
                )
            ) : null}
            <Create>
                <FormTabs
                    isNotInputPermitted={isNotInputPermitted}
                    onSubmit={onSubmit}
                    setContest={setContest}
                    contest={contest}
                    currentUser={currentUser}
                    toolbar={<PostCreateToolbar isSubmitting={isSubmitting} />}
                />
            </Create>
        </Box>
    );
};

const FormTabs = ({ isNotInputPermitted, onSubmit, contest, setContest, currentUser, toolbar }: any) => {
    const [searchParams] = useSearchParams();
    const areaId = searchParams.get('areaId');
    const contestId = searchParams.get('contestId');
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [malls, setMalls] = useState<any>();

    React.useEffect(() => {
        (async () => {
            await dataProvider
                .getOne('contests', { id: contestId })
                .then(({ data }: any) => {
                    setContest(data);
                })
                .catch((error: any) => {
                    setError(error);
                    setLoading(false);
                });
            await dataProvider
                .getManyReference('malls', {
                    target: 'areaId',
                    id: areaId || '',
                    // [TODO] API側で解決したい
                    pagination: { page: 1, perPage: 99999 },
                    sort: { field: 'id', order: 'DESC' },
                    filter: undefined
                })
                .then(({ data }: any) => {
                    setMalls(data);
                    setLoading(false);
                })
                .catch((error: any) => {
                    setError(error);
                    setLoading(false);
                });
        })();
    }, []);

    if (loading) return <Loading />;
    if (error) return <p>ERROR</p>;

    return (
        <TabbedForm onSubmit={onSubmit} toolbar={toolbar}>
            <FormTab sx={{ maxWidth: 600 }} label="基本項目">
                <TabBasic
                    areaId={areaId}
                    contestId={contestId}
                    contest={contest}
                    malls={malls}
                    currentUser={currentUser}
                    isNotInputPermitted={isNotInputPermitted}
                />
            </FormTab>
            <FormTab sx={{}} label="設問回答">
                <TabQuestions
                    areaId={areaId}
                    contestId={contestId}
                    contest={contest}
                    malls={malls}
                    currentUser={currentUser}
                />
            </FormTab>
        </TabbedForm>
    );
};
