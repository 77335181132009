import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    CreateBase,
    FileField,
    FileInput,
    Form,
    ImageField,
    ImageInput,
    Loading,
    SaveButton,
    useRefresh,
    useRedirect
} from 'react-admin';
import axios from '../axios/axios';
import { Box } from '@mui/system';
import { Card, CardContent, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { uploadImageToFirebaseStorage } from '../utils/util';
import Encoding from 'encoding-japanese';
import { stringify } from 'query-string';
import { useSearchParams } from 'react-router-dom';

export default function FormDialog({ contestData, currentUser }: any) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsloading] = React.useState(false);
    const [confirm, setConfirm] = React.useState<any>({});
    const [confirmError, setConfirmError] = React.useState(false);
    const [csvItems, setCsvItems] = React.useState<any>(null);
    const [isCompleted, setIsCompleted] = React.useState(false);
    const [completeError, setCompleteError] = React.useState<any[]>([]);
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [searchParams] = useSearchParams();
    let contestId = 1;
    let currentSelectionStatus = 0;
    const gotSeatchParamssearch = searchParams.get('filter');
    if (gotSeatchParamssearch !== null) {
        contestId = JSON.parse(gotSeatchParamssearch).contestId;
        currentSelectionStatus = JSON.parse(gotSeatchParamssearch).selectionStatus_gte;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == 'backdropClick' && isLoading) return;
        setOpen(false);
        setIsloading(false);
        setConfirm({});
        setIsCompleted(false);
        setCompleteError([]);
        setConfirmError(false);
        setCsvItems(null);
    };

    const handleCloseForComplete = (event?: any, reason?: any) => {
        if (reason && reason == 'backdropClick' && isLoading) return;
        setOpen(false);
        setIsloading(false);
        setConfirm({});
        setIsCompleted(false);
        setCompleteError([]);
        setConfirmError(false);
        setCsvItems(null);
        redirect(
            `/posts?` +
                stringify({
                    filter: JSON.stringify({
                        contestId: contestId,
                        selectionStatus_gte: currentSelectionStatus
                    })
                })
        );
        refresh();
    };

    const onSubmit = async (data: any) => {
        if (!csvItems) {
            setIsloading(true);

            const confirmObject: any = {};

            const fileReader: any = new FileReader();
            fileReader.readAsArrayBuffer(data.files.rawFile);
            fileReader.onload = async () => {
                // 8ビット符号なし整数値配列と見なす
                const array: any = new Uint8Array(fileReader.result);
                // 文字コードを取得
                console.log(Encoding.detect(array));
                // Unicodeの数値配列に変換
                const unicodeArray = Encoding.convert(array, 'UNICODE');
                // Unicodeの数値配列を文字列に変換
                const text = Encoding.codeToString(unicodeArray);

                // ファイル読み込み
                let fileResult = text.split(/\n|\r\n/);
                // 先頭行をヘッダとして格納
                const header: any = fileResult[0].split(',');
                console.log({ header });
                // 先頭行の削除
                fileResult.shift();
                // 最終行が不要ならば削除
                fileResult = fileResult.filter((row, index) => row.indexOf(',') !== -1);

                // CSVから情報を取得
                const items = fileResult.map((item: any) => {
                    const datas: any = item.split(',');
                    const result: any = {};
                    for (const index in datas) {
                        const key = header[index].replace(/\s|\ufeff/g, '');
                        result[key] = datas[index];
                    }
                    return result;
                });

                // バリデーション確認
                for (let index = 0; index < items.length; index++) {
                    const item = items[index];
                    items[index]['contestId'] = contestData.id;
                    items[index]['areaId'] = contestData.areaId;
                    // 一次審査の場合、他SCの投稿に対する採点ができないように
                    if (currentUser.mallId) {
                        items[index]['mallId'] = currentUser.mallId;
                    }
                    confirmObject[index] = {
                        postId: item['投稿ID'],
                        checks: []
                    };

                    try {
                        const results: any = await axios.post('/scores/bulkSetScoreCheck', item);
                        confirmObject[index]['checks'] = results.data.results.checks;
                        items[index] = results.data.item;
                        console.log(results.data.item);
                    } catch (error: any) {
                        confirmObject[index]['checks'].push({ status: 'error', message: 'エラーが発生しました' });
                    }
                }

                // 結果を格納
                setCsvItems(items);
                setConfirm(confirmObject);

                // errorが存在するかどうか
                Object.keys(confirmObject).map((id: any) => {
                    confirmObject[id].checks.map((d: any) => {
                        if (d.status === 'error') {
                            setConfirmError(true);
                        }
                    });
                });

                setIsloading(false);
            };
        }
        // 確認後の実行
        else {
            setIsloading(true);

            const errors = [];

            // 一括インポート実行
            for (let index = 0; index < csvItems.length; index++) {
                const csvItem = csvItems[index];
                csvItem.phase = contestData.selectionStatus + 1;
                csvItem.adminId = currentUser.id;

                console.log({ csvItem });

                try {
                    // 新規登録
                    if (!csvItem.docId) {
                        await axios.post('/scores', csvItem);
                    }
                    // 更新
                    else {
                        await axios.patch('/scores/' + csvItem.id, csvItem);
                    }
                } catch (error: any) {
                    errors.push({ rowNumber: index, postId: csvItem.postId });
                }
            }

            setConfirm({});
            setConfirmError(false);
            setIsCompleted(true);
            setCompleteError(errors);
            setCsvItems(false);
            setIsloading(false);
        }
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} color="secondary">
                一括採点
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>一括採点</DialogTitle>
                {!isCompleted ? (
                    <Form onSubmit={onSubmit}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <DialogContent>
                                {!csvItems ? (
                                    <FileInput
                                        source="files"
                                        label="csvファイル" // 仕様通り{false}にしたいが、errorが発生する。
                                        accept={['.csv']}
                                        multiple={false}
                                        format={formatPreview}
                                        placeholder={
                                            'csvファイルをアップロードするファイルをドロップするか、クリックして選択してください。'
                                        }
                                        sx={{
                                            '& .RaFileInput-dropZone': {
                                                background: 'transparent',
                                                border: '2px dashed gray',
                                                borderRadius: '6px',
                                                padding: '50px'
                                            }
                                        }}
                                    >
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                ) : (
                                    <Box>
                                        <Box>
                                            {confirmError
                                                ? '以下のエラー（赤字）があるため、一括インポートを実行できません。csvファイルを修正し、再度ファイル選択してください。'
                                                : '一括インポートを実行してよろしいですか。'}
                                        </Box>
                                        {Object.keys(confirm).map((id: any, index: number) =>
                                            confirm[id].checks.length > 0 ? (
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{ marginTop: '15px', padding: '10px' }}
                                                >
                                                    <Typography fontWeight={'bold'}>
                                                        {parseInt(id) + 2}行目、投稿ID: {confirm[id].postId}
                                                    </Typography>
                                                    <Box>
                                                        {confirm[id].checks.map((c: any, i: number) => {
                                                            return (
                                                                <Typography
                                                                    key={i}
                                                                    variant="body2"
                                                                    gutterBottom
                                                                    color={() => {
                                                                        switch (c.status) {
                                                                            case 'error':
                                                                                return 'red';
                                                                            case 'warning':
                                                                                return 'orange';
                                                                            case 'new':
                                                                                return 'green';
                                                                        }
                                                                    }}
                                                                >
                                                                    ・{c.message}
                                                                </Typography>
                                                            );
                                                        })}
                                                    </Box>
                                                </Card>
                                            ) : null
                                        )}
                                    </Box>
                                )}
                            </DialogContent>
                        )}
                        <DialogActions>
                            <Button onClick={handleClose} disabled={isLoading}>
                                キャンセル
                            </Button>
                            {csvItems ? (
                                <Button
                                    onClick={() => {
                                        setConfirm({});
                                        setConfirmError(false);
                                        setCsvItems(null);
                                    }}
                                    disabled={isLoading}
                                >
                                    ファイルを再選択
                                </Button>
                            ) : null}
                            <SaveButton
                                disabled={isLoading || confirmError}
                                label={csvItems ? '実行する' : '確認する'}
                            />
                        </DialogActions>
                    </Form>
                ) : (
                    <>
                        <DialogContent>
                            一括インポートが完了しました。
                            {completeError.length > 0
                                ? completeError.map((error: any, i: number) => {
                                      return (
                                          <Box key={i}>
                                              {!error.message ? (
                                                  <Typography variant="body2" gutterBottom color="red">
                                                      ・{error.rowNumber + 2}行目、投稿ID「{error.postId}
                                                      」に対する採点の登録に失敗しました。
                                                  </Typography>
                                              ) : (
                                                  <Typography variant="body2" gutterBottom color="red">
                                                      ・{error.rowNumber + 2}行目、行目、投稿ID「{error.postId}」
                                                      {error.message}
                                                  </Typography>
                                              )}
                                          </Box>
                                      );
                                  })
                                : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseForComplete}>閉じる</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
}

function formatPreview(value: any) {
    return value;
    // if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    //     return { url: value };
    // } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
    //     return value;
    // }
}
