import { TranslationMessages } from 'react-admin';
import japaneseMessages from 'ra-language-japanese';

japaneseMessages['ra']['navigation']['skip_nav'] = 'コンテンツへ移動する';
japaneseMessages['ra']['auth']['auth_check_error'] = 'ログインエラー';
japaneseMessages['ra']['auth']['sign_in'] = 'ログイン';
japaneseMessages['ra']['auth']['logout'] = 'ログアウト';
japaneseMessages['ra']['action']['confirm'] = '確認';
japaneseMessages['ra']['action']['unselect'] = '未選択';
japaneseMessages['ra']['action']['create_item'] = '入力中の値で新しいタグを作成する';
japaneseMessages['ra']['input']['password'] = {
    toggle_hidden: '非表示にする',
    toggle_visible: '表示する'
};
japaneseMessages['ra']['message']['unsaved_changes'] =
    '保存されていない変更は、変更が取り消されます。\n本当に移動してよろしいですか。';

const customEnglishMessages: TranslationMessages = {
    ...japaneseMessages,
    pos: {
        search: '検索',
        configuration: '設定',
        language: 'Language',
        theme: {
            name: 'テーマ',
            light: 'Light',
            dark: 'Dark'
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items'
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this demo'
            }
        },
        menu: {
            contests: 'コンテスト',
            contest_create: 'コンテスト登録',
            ranks: '投稿（ランキング）管理',
            s: 'ランキング管理',
            users: 'アカウント',
            doyutens: 'SC'
        }
    },
    resources: {
        contests: {
            name: 'コンテスト',
            create: {
                name: '新規登録'
            },
            fields: {
                contest_name: 'コンテスト名',
                area: '開催地区',
                publish_period: '掲載期間',
                application_period: '投稿期間',
                application_amount: '投稿数',
                selection_status: '審査状況',
                first_selection: '一次',
                second_selection: '二次',
                third_selection: '三次'
            },
            notification: {
                created_success: '登録完了しました',
                created_validation: '入力項目に不備があります',
                created_error: '登録に失敗しました',
                updated_success: '更新完了しました',
                updated_validation: '入力項目に不備があります',
                updated_error: '更新に失敗しました'
            }
        },
        posts: {
            name: '投稿',
            create: {
                name: '新規登録'
            },
            notification: {
                created_success: '登録完了しました',
                updated_success: '更新完了しました',
                created_error: 'エラーが発生しました',
                updated_error: 'エラーが発生しました'
            }
        },
        admins: {
            name: 'ユーザー'
        },
        recommends: {
            name: 'おすすめコンテンツ'
        },
        rankings: {
            name: 'ランキング'
        },
        searchSettings: {
            name: '検索設定'
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                stateAbbr: 'State'
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment'
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password'
            },
            page: {
                delete: 'Delete Customer'
            },
            errors: {
                password_mismatch: 'The password confirmation is not the same as the password.'
            }
        },
        commands: {
            name: 'Order |||| Orders',
            amount: '1 order |||| %{smart_count} orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    taxes: 'Tax',
                    total: 'Total',
                    unit_price: 'Unit Price'
                },
                address: 'Address',
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                nb_items: 'Nb Items',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned'
            },
            section: {
                order: 'Order',
                customer: 'Customer',
                shipping_address: 'Shipping Address',
                items: 'Items',
                total: 'Totals'
            }
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address'
            }
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                sales: 'Sales',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width'
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews'
            },
            filters: {
                categories: 'Categories',
                stock: 'Stock',
                no_stock: 'Out of stock',
                low_stock: '1 - 9 items',
                average_stock: '10 - 49 items',
                enough_stock: '50 items & more',
                sales: 'Sales',
                best_sellers: 'Best sellers',
                average_sellers: 'Average',
                low_sellers: 'Low',
                never_sold: 'Never sold'
            }
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products'
            }
        },
        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating'
            },
            action: {
                accept: 'Accept',
                reject: 'Reject'
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected'
            }
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                customers: 'Customers',
                name: 'Name'
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer'
            }
        }
    }
};

export default customEnglishMessages;
