import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, Typography, useMediaQuery, TextField as MuiTextField, Breadcrumbs } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    Show,
    Toolbar,
    SaveButton,
    useGetIdentity,
    Loading,
    useRecordContext,
    TopToolbar
} from 'react-admin';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';
import { AddAlarmSharp, OpenInNewOffOutlined } from '@mui/icons-material';
import { CustomFormTab, TabBasic } from './RecommendFormComponents';
import Error from '../Error';
import CustomBreadcrumbs from '../layout/Breadcrumbs';

const ContestTitle = ({ record }: any) => {
    return <span>Contest {record ? `"${record.title}"` : ''}</span>;
};

const ShowActions = ({ currentUser }: any) => {
    const record = useRecordContext();

    if (!record) return <Loading />;

    // 権限別調整
    let isNotPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            isNotPermitted = record.areaId !== currentUser.areaId;
            break;
        case Consts.AUTORITY.mall:
            isNotPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPermitted = true;
            break;
        default:
            break;
    }

    return (
        <>
            {!isNotPermitted ? (
                <TopToolbar>
                    <EditButton />
                    {/* Add your custom actions */}
                    {/* <Button color="primary" onClick={}>Custom Action</Button> */}
                </TopToolbar>
            ) : null}
        </>
    );
};

const RecommendShowContent = ({ currentUser }: any) => {
    const record = useRecordContext();

    if (!record) return <Loading />;

    // 権限別調整
    let isNotPagePermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPagePermitted = record.areaId !== currentUser.areaId;
            break;
        case Consts.AUTORITY.maker:
            isNotPagePermitted = record.areaId !== currentUser.areaId;
            break;
        default:
            break;
    }

    if (isNotPagePermitted) return <Error type="permission" />;

    return <CustomFormTab currentUser={currentUser} isShow={true} toolbar={<></>} />;
};

export const RecommendShow = () => {
    const { identity: currentUser, isLoading } = useGetIdentity();

    if (isLoading) return <Loading />;

    return (
        <Box>
            <CustomBreadcrumbs
                breadcrumbs={[
                    { url: '/recommends', text: 'おすすめコンテンツ一覧' },
                    { url: null, text: `おすすめコンテンツ詳細` }
                ]}
            />
            <Show
                title={'おすすめコンテンツ詳細'}
                actions={<ShowActions currentUser={currentUser} />}
                sx={{
                    padding: {
                        // xs: '20px 10px',
                        sm: '0px 0px 20px'
                    }
                }}
            >
                <RecommendShowContent currentUser={currentUser} />
            </Show>
        </Box>
    );
};
