import * as React from 'react';
import { Form, useGetIdentity, useGetOne, useTranslate } from 'ra-core';
import { useTiptapEditor } from 'ra-input-rich-text';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import {
    Box,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ToggleButton,
    ToggleButtonProps,
    Typography,
    Button
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { FileField, FileInput, ImageField, ImageInput, Loading, SaveButton } from 'react-admin';
import { uploadImageToFirebaseStorage } from '../utils/util';
import { Consts } from '../consts/Const';

export const MyImageButton = (props: Omit<ToggleButtonProps, 'value'>) => {
    const editor = useTiptapEditor();
    const translate = useTranslate();
    const { getValues, setValue, resetField } = useFormContext();
    const { identity: currentUser, isLoading: isIdentityLoading } = useGetIdentity();

    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsloading] = React.useState(false);
    const [confirm, setConfirm] = React.useState<any>(null);
    const [confirmError, setConfirmError] = React.useState(false);
    const [isCompleted, setIsCompleted] = React.useState(false);
    const [completeError, setCompleteError] = React.useState<any>(null);
    const selectedAreaId = getValues('areaId');
    const {
        data: area,
        isLoading: isGetAreaLoading,
        error
    } = useGetOne('areas', { id: selectedAreaId ? selectedAreaId : 1 });

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == 'backdropClick' && isLoading) return;
        setOpen(false);
        setIsloading(false);
        setConfirm(null);
        setIsCompleted(false);
        setCompleteError(null);
        setConfirmError(false);
    };

    const onSubmit = React.useCallback(
        async (data) => {
            // upload処理
            setIsloading(true);
            let url: string | undefined;

            if (!confirm) {
                if (!selectedAreaId || currentUser?.authority < Consts.AUTORITY.area) {
                    setIsloading(false);
                    setConfirm(true);
                    return;
                }
            }
            console.log({ data });
            // 画像のアップロード処理
            try {
                if (data.image) {
                    url = await uploadImageToFirebaseStorage(data.image.rawFile, selectedAreaId, 'recommends');
                }
            } catch (error: any) {
                setConfirm(false);
                setIsloading(false);
                setIsCompleted(true);
                setCompleteError('画像のアップロードに失敗しました。適切な画像を選択し直してください。');
            }

            if (url) {
                // editorのcontent内にレンダリング
                editor.chain().focus().setImage({ src: url }).run();
            }

            setIsCompleted(true);
            setIsloading(false);
        },
        [editor, confirm, selectedAreaId, setIsloading]
    );

    return editor ? (
        <>
            <ToggleButton
                aria-label={'画像'}
                title={'画像'}
                {...props}
                disabled={!editor?.isEditable}
                value="image"
                onClick={handleClick}
            >
                <ImageIcon fontSize="inherit" />
            </ToggleButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>記事用画像アップロード</DialogTitle>
                {!isCompleted ? (
                    <Form onSubmit={onSubmit}>
                        {isLoading || isGetAreaLoading || isIdentityLoading ? (
                            <Loading />
                        ) : (
                            <DialogContent>
                                {!confirm ? (
                                    <ImageInput
                                        label="画像ファイル"
                                        format={formatPreview}
                                        source="image"
                                        accept={Consts.ACCEPTABLE_IMAGE_FILE_EXTENSION}
                                        isRequired
                                        fullWidth
                                        placeholder={
                                            '画像ファイルをアップロードするファイルをドロップするか、クリックして選択してください。'
                                        }
                                        sx={{
                                            '& .RaFileInput-dropZone': {
                                                background: 'transparent',
                                                border: '2px dashed gray',
                                                borderRadius: '6px',
                                                padding: '50px'
                                            }
                                        }}
                                    >
                                        <ImageField
                                            source="url"
                                            title="title"
                                            sx={{
                                                width: '100%',
                                                height: '400px',
                                                '& img': {
                                                    height: 'auto',
                                                    width: 'auto',
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'contain'
                                                }
                                            }}
                                        />
                                    </ImageInput>
                                ) : (
                                    <Box>
                                        <Box>
                                            {selectedAreaId
                                                ? `${area.areaName}の画像として、アップロードします。よろしいですか。`
                                                : `全地域用の画像として、アップロードします。よろしいですか。`}
                                        </Box>
                                    </Box>
                                )}
                            </DialogContent>
                        )}
                        <DialogActions>
                            <Button onClick={handleClose} disabled={isLoading}>
                                キャンセル
                            </Button>
                            {confirm ? (
                                <Button
                                    onClick={() => {
                                        setConfirm(null);
                                    }}
                                    disabled={isLoading || isGetAreaLoading || isIdentityLoading}
                                >
                                    ファイルを再選択
                                </Button>
                            ) : null}
                            <SaveButton
                                disabled={isLoading || isGetAreaLoading || isIdentityLoading}
                                label={confirm ? '実行する' : '確認する'}
                            />
                        </DialogActions>
                    </Form>
                ) : (
                    <>
                        <DialogContent>
                            {completeError ? (
                                <Box sx={{ color: 'red' }}>{completeError}</Box>
                            ) : (
                                '画像をアップロードしました。'
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>閉じる</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    ) : null;
};

function formatPreview(value: any) {
    return value;
    // if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    //     return { url: value };
    // } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
    //     return value;
    // }
}
