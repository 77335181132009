import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Consts } from '../consts/Const';
import LabelIcon from '@mui/icons-material/Label';

import { useTranslate, DashboardMenuItem, MenuItemLink, MenuProps, useSidebarState, useGetIdentity } from 'react-admin';

import contests from '../contests';
import admins from '../admins';
import malls from '../malls';
import recommends from '../recommends';
import SubMenu from './SubMenu';
import rankings from '../rankings';
import searchSettings from '../searchSettings';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ dense = false }: MenuProps) => {
    const userAuthority: any = useGetIdentity();
    let checkAuthority = false;

    if (userAuthority.identity && userAuthority.identity.authority < Consts.AUTORITY.mall) {
        checkAuthority = true;
    }

    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: (theme) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    })
            }}
        >
            {/* { [TODO] ダッシュボード一旦コメントアウト } */}
            {/* <DashboardMenuItem /> */}
            {/* <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name="pos.menu.contests"
                icon={<contests.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={{
                        pathname: '/contests'
                    }}
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.contests.name`)}
                    leftIcon={<contests.icon />}
                    dense={dense}
                />
            </SubMenu> */}
            <MenuItemLink
                to={{ pathname: '/contests' }}
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.contests.name`)}
                leftIcon={<contests.icon />}
                dense={dense}
            />
            {/* 権限別調整 */}
            {checkAuthority ? (
                <MenuItemLink
                    to={{ pathname: '/malls' }}
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.doyutens`, { smart_count: 2 })}
                    leftIcon={<malls.icon />}
                    dense={dense}
                />
            ) : null}
            {checkAuthority ? (
                <MenuItemLink
                    to={{ pathname: '/admins' }}
                    state={{ _scrollToTop: true }}
                    // [TODO] :値渡せる
                    primaryText={translate(`resources.admins.name`, { smart_count: 2 })}
                    leftIcon={<admins.icon />}
                    dense={dense}
                />
            ) : null}
            {checkAuthority ? (
                <MenuItemLink
                    sx={{
                        letterSpacing: '-1px'
                    }}
                    to={{ pathname: '/recommends' }}
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.recommends.name`)}
                    leftIcon={<recommends.icon />}
                    dense={dense}
                />
            ) : null}
            {checkAuthority ? (
                <MenuItemLink
                    to={{
                        pathname: `/rankings/${userAuthority.identity.areaId ? userAuthority.identity.areaId : '1'}`
                    }}
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.rankings.name`)}
                    leftIcon={<rankings.icon />}
                    dense={dense}
                />
            ) : null}
            {checkAuthority ? (
                <MenuItemLink
                    to={{
                        pathname: `/searchSettings/${
                            userAuthority.identity.areaId ? userAuthority.identity.areaId : '1'
                        }`
                    }}
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.searchSettings.name`)}
                    leftIcon={<searchSettings.icon />}
                    dense={dense}
                />
            ) : null}
        </Box>
    );
};

export default Menu;
