import * as React from 'react';
import { useForm, useFormState, useFormContext, useWatch, useFieldArray, Controller } from 'react-hook-form';
import {
    Box,
    Button,
    Typography,
    useMediaQuery,
    TextField as MuiTextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    DateTimeInput,
    PasswordInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    DateField,
    useRecordSelection,
    useGetMany,
    useRecordContext,
    ArrayField,
    RecordContextProvider,
    useGetOne,
    useGetManyReference,
    Loading,
    BooleanInput,
    UrlField,
    AutocompleteInput,
    Toolbar,
    SaveButton
} from 'react-admin';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import { display } from '@mui/system';
import { useState } from 'react';

export const RankingForm = ({ isShow, currentUser, isNotEditPermitted }: any) => {
    const record = useRecordContext();
    isShow = isNotEditPermitted;

    return (
        <Box
            sx={{
                width: '100%',
                padding: {
                    xs: '0 0 50px 0',
                    sm: '0'
                }
            }}
        >
            <ReferenceInput source="areaId" reference="areas">
                <SelectInput
                    label="開催地区"
                    optionText="areaName"
                    isRequired
                    disabled={isShow || currentUser.areaId || record ? true : false}
                    defaultValue={currentUser.areaId ? currentUser.areaId : undefined}
                />
            </ReferenceInput>
            <ArrayInput label={false} source="settings" disabled={isShow} isRequired>
                <SimpleFormIterator
                    getItemLabel={(index) => `ランキング${index + 1}. `}
                    {...(isShow && { disableAdd: true, disableRemove: true, disableReordering: true })}
                >
                    <SelectInput
                        label={'対象'}
                        source={`dimention`}
                        choices={[
                            { id: Consts.DIMENTION.malls, name: 'SC' }
                            // { id: Consts.DIMENTION.posts, name: '投稿' },
                        ]}
                        disabled={isShow}
                        isRequired
                    />
                    <FormDataConsumer>
                        {({
                            formData, // The whole form data
                            scopedFormData, // The data for this item of the ArrayInput
                            getSource, // A function to get the valid source inside an ArrayInput
                            ...rest
                        }) => {
                            if (scopedFormData && scopedFormData.dimention === Consts.DIMENTION.malls) {
                                return (
                                    <>
                                        <ReferenceInput
                                            source={getSource!('filters.contestId')}
                                            reference="contests"
                                            perPage={9999}
                                        >
                                            <AutocompleteInput
                                                label="コンテスト"
                                                optionText="title"
                                                fullWidth
                                                disabled={isShow}
                                            />
                                        </ReferenceInput>
                                    </>
                                );
                            } else if (scopedFormData && scopedFormData.dimention === Consts.DIMENTION.posts) {
                                return (
                                    <>
                                        <ReferenceInput source={getSource!('filters.contestId')} reference="contests">
                                            <AutocompleteInput
                                                label="コンテスト"
                                                optionText="title"
                                                fullWidth
                                                disabled={isShow}
                                            />
                                        </ReferenceInput>
                                        <ReferenceInput source={getSource!('filters.tagId')} reference="tags">
                                            <AutocompleteInput
                                                label="ハッシュタグ"
                                                optionText="name"
                                                disabled={isShow}
                                            />
                                        </ReferenceInput>
                                        <ReferenceInput source={getSource!('filters.jobTypeId')} reference="jobTypes">
                                            <SelectInput label="職種" optionText="name" disabled={isShow} />
                                        </ReferenceInput>
                                        <SelectInput
                                            label="コンテストの種類"
                                            source={getSource!('filters.contestType')}
                                            choices={[
                                                { id: '0', name: '画像' },
                                                { id: '1', name: '動画' }
                                            ]}
                                            disabled={isShow}
                                        />
                                    </>
                                );
                            }
                        }}
                    </FormDataConsumer>
                    <SelectInput
                        label={'指標'}
                        source={`metrix`}
                        choices={[
                            // { id: Consts.METRIX.views, name: '閲覧数' },
                            // { id: Consts.METRIX.likes, name: 'いいね数' },
                            // { id: Consts.METRIX.comments, name: 'コメント数' },
                            { id: Consts.METRIX.posts, name: '投稿数' }
                        ]}
                        disabled={isShow}
                        isRequired
                    />
                    <DateTimeInput
                        label="集計開始日時"
                        source={`aggregationPeriodStart`}
                        isRequired
                        disabled={isShow}
                    />
                    <DateTimeInput label="集計終了日時" source={`aggregationPeriodEnd`} isRequired disabled={isShow} />
                </SimpleFormIterator>
            </ArrayInput>
            <Box>
                <DateTimeInput label="作成日時" source="createdAt" disabled isRequired />
            </Box>
            <Box>
                <DateTimeInput label="更新日時" source="updatedAt" disabled isRequired />
            </Box>
        </Box>
    );
};

export const CustomForm = ({ onSubmit, isShow, toolbar, currentUser, isNotEditPermitted }: any) => {
    const OnlySaveToolbar = () => (
        <Toolbar>
            <SaveButton label="保存" />
        </Toolbar>
    );

    return (
        <SimpleForm onSubmit={onSubmit} toolbar={<OnlySaveToolbar />}>
            <RankingForm isShow={isShow} currentUser={currentUser} isNotEditPermitted={isNotEditPermitted} />
        </SimpleForm>
    );
};
