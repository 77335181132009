import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

// 採点用エクスポート
export const scoresExporter = (data: any, contestData: any) => {
    for (let index = 0; index < data.length; index++) {
        const item = data[index];
        // カラム名を一括インポート用に変換（API側と逆）
        Object.keys(data[index]).map((key) => {
            switch (key) {
                case 'id':
                    item['投稿ID'] = item[key];
                    break;
                default:
                    break;
            }
            delete item[key];
        });

        contestData.scoringItems.map((question: any, index: number) => {
            item[`採点${index + 1}`] = '';
        });
        if (contestData.selectionStatus >= 1) {
            item[`コメント`] = '';
        }
        if (contestData.selectionStatus === contestData.maxSelectionStatus) {
            item[`賞`] = '';
        }
    }

    // BOM付きで出力
    const BOM = '\uFEFF';
    jsonExport(data, (err: any, csv: any) => {
        downloadCSV(`${BOM}${csv}`, 'scores');
        if (err) {
            console.log('Error trying to export list');
        }
    });
};
