import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    ShowButton,
    FunctionField,
    useTranslate,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    useGetIdentity,
    Loading,
    TopToolbar,
    FilterButton,
    ExportButton,
    CreateButton,
    Pagination,
    BooleanField
} from 'react-admin';
import { formatDate } from '../utils/util';
import { Consts } from '../consts/Const';
import CheckIcon from '@mui/icons-material/Check';

const contestFilters = (currentUser: any) => {
    // 権限別調整
    let isNotPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPermitted = true;
            break;
        default:
            break;
    }

    return !isNotPermitted
        ? [
              // [TODO] 未実装のため全文検索コメントアウト
              // <TextInput source="q" label="Search" alwaysOn />,
              <ReferenceInput source="areaId" label="地区" reference="areas" allowEmpty key="areaId">
                  <SelectInput optionText="areaName" />
              </ReferenceInput>
          ]
        : [
              // [TODO] 未実装のため全文検索コメントアウト
              // <TextInput source="q" label="Search" alwaysOn />,
          ];
};

const ContestListActions = ({ currentUser }: any) => {
    // 権限別調整
    let isNotPermitted;
    switch (currentUser.authority) {
        case Consts.AUTORITY.area:
            break;
        case Consts.AUTORITY.mall:
            isNotPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            isNotPermitted = true;
            break;
        default:
            break;
    }

    return (
        <>
            <TopToolbar>
                {!isNotPermitted ? <FilterButton /> : null}
                {/* <ExportButton/> */}
                {!isNotPermitted ? <CreateButton /> : null}
            </TopToolbar>
        </>
    );
};

const PostPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;

export const RecommendList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const { identity: currentUser, isLoading } = useGetIdentity();

    if (isLoading) return <Loading />;

    // 権限別調整
    let filter = {};
    let isNotFirstPermitted;
    let isNotPermitted;
    switch (currentUser?.authority) {
        case Consts.AUTORITY.area:
            filter = {};
            break;
        case Consts.AUTORITY.mall:
            filter = {
                areaId: currentUser?.areaId
            };
            isNotPermitted = true;
            break;
        case Consts.AUTORITY.maker:
            filter = {
                areaId: currentUser?.areaId
            };
            isNotFirstPermitted = true;
            break;
        default:
            break;
    }

    return (
        <List
            perPage={50}
            pagination={<PostPagination />}
            title={'おすすめコンテンツ一覧'}
            filters={contestFilters(currentUser)}
            actions={<ContestListActions currentUser={currentUser} />}
            sx={{
                padding: {
                    xs: '0px 10px',
                    sm: '0px 0px'
                }
            }}
            filter={filter}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="title" label="タイトル" />
                <ReferenceField label="エリア名" source="areaId" reference="areas" link={false}>
                    <TextField source="areaName" />
                </ReferenceField>
                <FunctionField
                    label="resources.contests.fields.publish_period"
                    sortBy="publishStart"
                    render={(record: any) =>
                        `${formatDate(new Date(record.publishStart), Consts.dateFormat)} ~ ${formatDate(
                            new Date(record.publishEnd),
                            Consts.dateFormat
                        )}`
                    }
                />
                <FunctionField
                    label="更新日"
                    sortBy="updatedAt"
                    render={(record: any) => `${formatDate(new Date(record.updatedAt), Consts.dateFormat)}`}
                />
                {currentUser?.authority <= Consts.AUTORITY.area ? <EditButton /> : <ShowButton />}
                <FunctionField
                    label="外部url"
                    sortBy={undefined}
                    render={(record: any) => (!record.url ? '' : <CheckIcon />)}
                />
                {/* <BooleanField label="お知らせ" source="popFlag" /> */}
            </Datagrid>
        </List>
    );
};
