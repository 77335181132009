import AdminsIcon from '@mui/icons-material/Group';

import { AdminList, AdminCreate, AdminEdit } from './admins';

export default {
    list: AdminList,
    edit: AdminEdit,
    create: AdminCreate,
    icon: AdminsIcon
};
