import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import {
    Button,
    Confirm,
    useListContext,
    useUpdateMany,
    useNotify,
    useRefresh,
    useUnselectAll,
    useCreate,
    useUpdate,
    useRedirect
} from 'react-admin';
import { Box, Card, Chip, Grid, InputAdornment, Typography, useMediaQuery, Button as MuiButton } from '@mui/material';
import Error from '../Error';
import { createDeflateRaw } from 'zlib';

const BulkSaveScoringButton = ({
    contestData,
    currentSelectionStatus,
    currentUser,
    setIsBulkAction
}: {
    contestData: any;
    currentSelectionStatus: number;
    currentUser: any;
    setIsBulkAction: any;
}) => {
    const form = useForm();
    const { getValues, setValue } = useFormContext();
    const { selectedIds, onUnselectItems } = useListContext();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<any[]>([]);
    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();
    const unselectAll = useUnselectAll('posts');
    const [create, { isLoading: isUseCreateLoading, error: useCreateError }] = useCreate();
    const [update, { isLoading: isUseUpdateLoading, error: useUpdateError }] = useUpdate();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    useEffect(() => {
        selectedIds.length > 0 ? setIsBulkAction(true) : setIsBulkAction(false);
    }, [selectedIds]);

    const handleConfirm = async () => {
        setValidationErrors([]);
        setIsLoading(true);
        const formData = getValues();
        const scoringItems = contestData.scoringItems;

        // バリデーション
        const errors: any[] = [];
        // 点数がallocationを超えているかどうか
        selectedIds.map((postId: number) => {
            const scoreData = formData[`score${postId}`].map((score: any) =>
                isNaN(parseInt(score)) ? 0 : parseInt(score)
            );
            scoringItems.map((item: any, index: number) => {
                if (item.allocation < scoreData[index]) {
                    errors.push({ postId: postId, index: index });
                }
            });
        });

        if (errors.length > 0) {
            setValidationErrors(errors);
            setIsLoading(false);
            return;
        }

        await Promise.all(
            selectedIds.map(async (postId) => {
                const data = {
                    score: formData[`score${postId}`].map((score: any) =>
                        isNaN(parseInt(score)) ? 0 : parseInt(score)
                    ),
                    comment: formData[`comment${postId}`],
                    phase: currentSelectionStatus + 1,
                    postId: postId,
                    adminId: currentUser.id
                };
                if (formData[`scoreId${postId}`]) {
                    update('scores', {
                        id: formData[`scoreId${postId}`],
                        data: data
                    });
                } else {
                    create(
                        'scores',
                        {
                            data: data
                        },
                        {
                            onSettled: (data, error) => {
                                setValue(`scoreId${postId}`, data.id, { shouldValidate: true });
                            },
                            onError: (error) => {
                                // error is an instance of Error.
                            }
                        }
                    );
                }
                if (formData[`prize${postId}`]) {
                    await update('posts', {
                        id: postId,
                        data: {
                            prize: formData[`prize${postId}`],
                            isSelection: true
                        }
                    });
                    setValue(`prize${postId}`, formData[`prize${postId}`], { shouldValidate: true });
                }
                data.score.map((score: any, index: number) => {
                    setValue(`score${postId}[${index}]`, score);
                });
            })
        );

        // [TODO] 処理完了までactiveにする処理
        await new Promise((resolve) => setTimeout(resolve, 2000));

        onUnselectItems();
        setIsLoading(false);
        setOpen(false);
        refresh();
    };

    if (useCreateError || useUpdateError) return <Error />;

    return (
        <>
            <MuiButton variant="contained" size="large" onClick={handleClick}>
                選択した採点を保存する
            </MuiButton>
            <Confirm
                isOpen={open}
                loading={isLoading || isUseCreateLoading || isUseUpdateLoading}
                title="採点の保存"
                content={
                    validationErrors.length > 0
                        ? validationErrors.map((validationError: any, index: number) => {
                              return (
                                  <Box key={index} color="red">
                                      投稿ID{validationError.postId}の採点が無効な値です。
                                  </Box>
                              );
                          })
                        : '採点を保存してよろしいですか'
                }
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default BulkSaveScoringButton;
